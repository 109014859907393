namespace Cryptomathic.Crypto.Digest {
  import Asn1Literal = Cryptomathic.ASN1.Asn1Literal;

  /**
   * Maps Digest enum value to Digest algorithm.
   */
  export function toAlgorithm(digest: Cryptomathic.Crypto.Digest): DigestAlgorithm {
    switch(digest) {
      case Cryptomathic.Crypto.Digest.SHA1:
        return Cryptomathic.Crypto.SHA1;
      case Cryptomathic.Crypto.Digest.SHA256:
        return Cryptomathic.Crypto.SHA256;
      case Cryptomathic.Crypto.Digest.SHA384:
        return Cryptomathic.Crypto.SHA384;
      case Cryptomathic.Crypto.Digest.SHA512:
        return Cryptomathic.Crypto.SHA512;
      default:
        throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError,
            "Digest must be one of Cryptomathic.Crypto.Digest");
    }
  }

  /**
   * Map Digest enume value to Digest algorithm.
   */
  export function toAsn1Oid(digest: Cryptomathic.Crypto.Digest): Asn1Literal {
    switch(digest) {
      case Cryptomathic.Crypto.Digest.SHA1:
        return Cryptomathic.ASN1.Constants.sha1Oid;
      case Cryptomathic.Crypto.Digest.SHA256:
        return Cryptomathic.ASN1.Constants.sha256Oid;
      case Cryptomathic.Crypto.Digest.SHA384:
        return Cryptomathic.ASN1.Constants.sha384Oid;
      case Cryptomathic.Crypto.Digest.SHA512:
        return Cryptomathic.ASN1.Constants.sha512Oid;
      default:
        throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError,
            "Digest must be one of Cryptomathic.Crypto.Digest");
    }
  }
}
