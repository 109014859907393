namespace Cryptomathic.ASN1 {
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class Set implements Asn1Object {

    private content: Asn1Object[];

    public constructor(object: Asn1Object) {

      this.content = [];

      if (object) {
        this.content.push(object);
      }
    }

    public push(object: Asn1Object): Set {
      this.content.push(object);
      return this;
    }
    
    public encode(): number[] {
      if (this.content.length===0) {
        throw new Cryptomathic.Error.CrmError(ErrorTypes.IllegalState, "Error: cannot encode empty sequence.");
      }

      const encodedContent = this.content.reduce((result, element) => result.concat(element.encode()), []);

      return ASN1Util.encodeConstructedTag(ASN1Util.TagClasses.UNIVERSAL, 0x11)
        .concat(ASN1Util.encodeLength(encodedContent.length)
        .concat(encodedContent));
    }
  }
}
