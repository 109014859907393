namespace Cryptomathic.Messages {
  /**
   * The type of a token
   *
   * @namespace Cryptomathic.Messages
   * @class AuthenticationToken
   *
   */
  export type TokenType = "OATH-HOTP"|"OATH-TOTP"|"SMS"|"OATH-OCRA";

  /**
   * AuthenticationToken object returned by the {{#crossLink "Cryptomathic.SignerUserSDK.SDK/createSession:method"}}createSession{{/crossLink}} method of the SDK object.
   *
   * Client side representation of an authentication token: an OATH-OCRA hardware token, an OATH-HOTP hardware token, an OATH-TOTP hardware token or an
   * SMS token. See the Signer Administrators' Manual for more information about token types.
   *
   * @namespace Cryptomathic.Messages
   * @class AuthenticationToken
   */
  export class AuthenticationToken {
    private readonly tokenType: TokenType;
    private readonly tokenSerial: string;
    private readonly tokenTag: string;


    public constructor(tokenType: TokenType, tokenSerial: string, tokenTag: string) {
      this.tokenType = tokenType;
      this.tokenSerial = tokenSerial;
      this.tokenTag = tokenTag;
    }

    /**
     * @method getTokenType
     * @return TokenType
     */
    public getTokenType(): TokenType {
      return this.tokenType;
    }

    /**
     * @method getTokenSerial
     * @return string
     */
    public getTokenSerial(): string {
      return this.tokenSerial;
    }

    /**
     * @method getTokenTag
     * @return string
     */
    public getTokenTag(): string {
      return this.tokenTag;
    }
  }


}
