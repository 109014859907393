namespace Cryptomathic.SignerUserSDK {
  
  export class SDK implements SDKInterface {
    private readonly impl: Cryptomathic.SignerUserSDK.Impl.SignerUserSDKObject;
    
    constructor(host: string, timeout: number) {
      this.impl = new Cryptomathic.SignerUserSDK.Impl.SignerUserSDKObject(host, timeout);
    }
    
    public initialize(): void {
      this.impl.initialize();
    }

    public isInitialized(): boolean {
      return this.impl.isInitialized();
    }

    public free(): void {
      this.impl.free();
    }

    public createSession(resolve: (tokenList: Messages.AuthenticationToken[], policyList: Messages.PolicyEntry[]) => void, reject: RejectCallback, saml?: number[]): void {
      this.impl.createSession(resolve, reject, saml);
    }
    
    public getChallenge(authenticationToken: Messages.AuthenticationToken, resolve: (challenge: Messages.AuthenticationChallenge) => void, reject: RejectCallback): void {
      this.impl.getChallenge(authenticationToken, resolve, reject);
    }
      
    public getUsableTokens(policyEntry: Messages.PolicyEntry, tokenList: Messages.AuthenticationToken[]): Messages.AuthenticationToken[] {
      return Cryptomathic.SignerUserSDK.Impl.getUsableTokens(policyEntry, tokenList);
    }

    public escalate(authenticationToken: Messages.AuthenticationToken, tokenCredentials: string, resolve: NotificationCallback, reject: RejectCallback): void {
      this.impl.escalate(authenticationToken, tokenCredentials, resolve, reject);
    }

    public escalateAndSign(authenticationToken: Messages.AuthenticationToken, tokenCredentials: string, keyEntry: Messages.KeyEntry, signatureRequest: Cryptomathic.Crypto.SignatureRequest, resolve: SignatureCallback, reject: RejectCallback): void {
      this.impl.escalateAndSign(authenticationToken, tokenCredentials, keyEntry, signatureRequest, resolve, reject);
    }

    public sign(keyEntry: Messages.KeyEntry, signatureRequest: Cryptomathic.Crypto.SignatureRequest, resolve: SignatureCallback, reject: RejectCallback): void {
      this.impl.sign(keyEntry, signatureRequest, resolve, reject);
    }

    public logoff(resolve: NotificationCallback, reject: RejectCallback): void {
      this.impl.logoff(resolve, reject);
    }

    public noop(resolve: NotificationCallback, reject: RejectCallback): void {
      this.impl.noop(resolve, reject);
    }

  }

}
