namespace Cryptomathic.Messages {
  /**
   * The key level of the keys issued under the policy. Returned by the {{#crossLink "Cryptomathic.SignerUserSDK.SDK/createSession:method"}}createSession{{/crossLink}}
   * method of the SDK object as part of the {{#crossLink "Cryptomathic.Messages.PolicyEntry"}}{{/crossLink}} object
   *
   * @namespace Cryptomathic.Messages
   * @class PolicyKeyLevel
   */

  export enum PolicyKeyLevel {
    /**
     * EXPORTABLE
     *
     * **Deprecated** - not in use
     *
     * @type PolicyKeyLevel
     * @property EXPORTABLE
     * @deprecated not in use
     */
    EXPORTABLE = 0,

    /**
     * RECOVERABLE
     *
     * **Deprecated** - not in use
     *
     * @type PolicyKeyLevel
     * @property RECOVERABLE
     * @deprecated - not in use
     */
    RECOVERABLE = 10,

    /**
     * STANDARD
     *
     * @type PolicyKeyLevel
     * @property STANDARD
     */
    STANDARD = 20,

    /**
     * PASSWORD_ENCRYPTED
     *
     * **Deprecated** - not in use
     *
     * @type PolicyKeyLevel
     * @property PASSWORD_ENCRYPTED
     * @deprecated - not in use
     */
    PASSWORD_ENCRYPTED = 30

}

}

