namespace Cryptomathic.ASN1.PKCS7 {

  import Digest = Cryptomathic.Crypto.Digest;

    function createSignerInfo(certificate: number[], signature: number[], params: SignatureParams): SignerInfo {
      const signerInfo = new Cryptomathic.ASN1.SignerInfo();
      signerInfo.setVersion(new Cryptomathic.ASN1.Asn1Integer(1));

      signerInfo.setSignerIdentifier(certificate);

      const digestAlgorithmIdentifier = createDigestAlgorithmIdentifier(params.signatureId);
      signerInfo.setDigestAlgorithm(digestAlgorithmIdentifier);

      signerInfo.setSignatureAlgorithm(params.signatureAlgorithmIdentifier);

      const signatureValue = new Cryptomathic.ASN1.OctetString(signature);
      signerInfo.setSignatureValue(signatureValue);

      return signerInfo;
    }

    function createSignedData(certificate: number[], signerInfo: SignerInfo, digestAlgorithms: Set, encapsulatedContentInfo: EncapsulatedContentInfo): SignedData {
      const signedData = new Cryptomathic.ASN1.SignedData();

      signedData.setVersion(new Cryptomathic.ASN1.Asn1Integer(1));
      signedData.setEncodedCertificate(certificate);

      signedData.setSignerInfo(signerInfo);
      signedData.setDigestAlgorithms(digestAlgorithms);
      signedData.setEncapsulatedContentInfo(encapsulatedContentInfo);
      return signedData;
    }

    function createEncapsulatedContent(message: number[]): EncapsulatedContentInfo {
      if(message) {
        return new EncapsulatedContentInfo(Constants.pkcs7Data, new Cryptomathic.ASN1.OctetString(message));
      } else {
        return new EncapsulatedContentInfo(Constants.pkcs7Data);
      }
    }

    function createDigestList(params: SignatureParams) {
      const signatureAlgorithmIdentifier = createDigestAlgorithmIdentifier(params.signatureId);

      return new Set(signatureAlgorithmIdentifier);
    }

    function genericCreatePkcs7SignedMessage(certificate: number[], signature: number[], message: number[], params: SignatureParams): number[] {
      const signerInfo = createSignerInfo(certificate, signature, params);
      const digestAlgorithms = createDigestList(params);
      const encapsulatedContent = createEncapsulatedContent(message);

      const signedData = createSignedData(certificate, signerInfo, digestAlgorithms, encapsulatedContent);

      return new Cryptomathic.ASN1.ContentInfo(Cryptomathic.ASN1.Constants.pkcs7SignedData, signedData).encode();
    }

    export function createPkcs7SignedMessage(certificate: number[], signature: number[], message: number[], digest: Digest): number[] {
      const digestType = Cryptomathic.Crypto.Digest.toAlgorithm(digest);
      const handler = pkcs1SignatureParams(digestType.SIGNATURE_ID);
      return genericCreatePkcs7SignedMessage(certificate, signature, message, handler);
    }

    export function createPssPkcs7SignedMessage(certificate: number[], signature: number[], message: number[], digest: Digest, useSalt: boolean): number[] {
      const digestType = Cryptomathic.Crypto.Digest.toAlgorithm(digest);
      const saltLength = useSalt ? digestType.DIGEST_SIZE : 0;
      const signatureParams = pssSignatureParams(digestType.SIGNATURE_ID, saltLength);
      return genericCreatePkcs7SignedMessage(certificate, signature, message, signatureParams);
    }

  interface SignatureParams {
    signatureId: string;
    signatureAlgorithmIdentifier: AlgorithmIdentifier;
  }

  function pkcs1SignatureParams(signatureId: string): SignatureParams {
      return {
        signatureId,
        signatureAlgorithmIdentifier: createSignatureAlgorithmIdentifier(signatureId)
      };
  }

  function pssSignatureParams(signatureId: string, saltLength: number): SignatureParams {
      return {
        signatureId,
        signatureAlgorithmIdentifier: encodePssAlgorithmId(signatureId, saltLength)
      };
  }
  
}
