///<reference path="../error/CrmError.ts"/>
///<reference path="Tags/ServerCommand.ts"/>
///<reference path="Tags/HsmCommand.ts"/>
///<reference path="Sign.ts"/>
namespace Cryptomathic.Messages.Sign.Request {

  import CrmError = Cryptomathic.Error.CrmError;
  import HsmCommand = Cryptomathic.Messages.Tags.HsmCommand;
  import ServerCommand = Cryptomathic.Messages.Tags.ServerCommand;

  export function signCommandParts(keyEntry: KeyEntry, toSign: Cryptomathic.Crypto.SignatureRequest | number[]) {
    const serverContent = ServerCommand.userKeyId.encode(keyEntry.KEY_ID);

    const hsmContent = HsmCommand.keyHash.encode(keyEntry.KEY_DIGEST).concat(
      HsmCommand.signaturedata.encode(implementation.getBytesToSign(toSign, keyEntry))
    );
    return {serverContent, hsmContent};
  }

  export namespace implementation {
    /**
     * Check if an legacy style SignatureRequest is conformant with a given policy (a RAW policy will work for any SignatureRequest)
     */
    export function compareSignatureTypes(signatureRequest: Cryptomathic.Crypto.SignatureRequest, keyEntry: KeyEntry): boolean {
      const policy = keyEntry.OWNING_POLICY;

      if (!policy) {
        throw new CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "Key must have an owning policy");
      }

      if (policy.SIGNATURE_TYPE === Cryptomathic.Messages.SignatureType.RAW) {
        return true; //RAW has never been an option on JS
      } else {
        if (!Array.isArray(policy.SIGNATURE_TYPE_PARAMETER) || policy.SIGNATURE_TYPE_PARAMETER.length===0) {
          throw new CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "SIGNATURE_TYPE_PARAMETER cannot be empty if SIGNATURE_TYPE is " + keyEntry.OWNING_POLICY.SIGNATURE_TYPE);
        }
        const policyParamsAsHex = Cryptomathic.Utils.StringUtils.bytesToHex(policy.SIGNATURE_TYPE_PARAMETER);
        switch (policy.SIGNATURE_TYPE) {
          case Cryptomathic.Messages.SignatureType.SIGN_PKCS1:
            const requestParamsAsHex = Cryptomathic.Utils.StringUtils.bytesToHex(signatureRequest.getPaddedRequest(keyEntry));

            return signatureRequest.paddingType === Cryptomathic.Crypto.SignatureRequestType.PKCS1 && requestParamsAsHex.indexOf(policyParamsAsHex) !== -1;
          case Cryptomathic.Messages.SignatureType.SIGN_PSS:
            const digest = Cryptomathic.Crypto.Digest.toAlgorithm(signatureRequest.digest);
            const saltLength = signatureRequest.useSalt ? digest.DIGEST_SIZE : 0;
            const signatureParams = Cryptomathic.ASN1.encodePssParameters(digest.SIGNATURE_ID, saltLength).encode();

            return signatureRequest.paddingType === Cryptomathic.Crypto.SignatureRequestType.PSS && policyParamsAsHex===Cryptomathic.Utils.StringUtils.bytesToHex(signatureParams);
          default:
            throw new CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "Unrecognized User Key Operation "+policy.SIGNATURE_TYPE);
        }
      }
    }

    /**
     * Get the bytes to sign:
     * If legacy style SignatureRequest, then format for RAW policies and extract hash from the request for other policies.
     * If not legacy style SignatureRequest, then return the input directly, as it is the hash.
     */
    export function getBytesToSign(toSign: Cryptomathic.Crypto.SignatureRequest | number[], keyEntry: KeyEntry) {
      let bytesToSign;
      if (isSignatureRequest(toSign)) {
        if (!compareSignatureTypes(toSign, keyEntry)) {
          throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "User operation on user privilege did not match signature request");
        }
        if (keyEntry.OWNING_POLICY.SIGNATURE_TYPE === Cryptomathic.Messages.SignatureType.RAW) {
          bytesToSign = toSign.getPaddedRequest(keyEntry);
        } else {
          bytesToSign = toSign.hash;
        }

      } else {
        bytesToSign = toSign;
      }
      return bytesToSign;
    }

  }
}
