///<reference path="../../utils/ByteUtils.ts"/>
///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags {
  import convertToNumberArray = Cryptomathic.Utils.ByteArrayUtils.convertToNumberArray;

  export const stringTag = makeTag(encodeString, asString);

  const utf8Decoder = new TextDecoder();
  const utf8Encoder = new TextEncoder();

  export function asString(data: number[]): string {
    return data?utf8Decoder.decode(new Uint8Array(data)):undefined;
  }

  export function encodeString(value: string): number[] {
    return convertToNumberArray(utf8Encoder.encode(value));
  }

}
