namespace Cryptomathic.ASN1 {

  export class Asn1Integer implements Asn1Object {
    private encoding: number[];

    public constructor(byte: number) {
      if (byte > 127 || byte < 0) {
        throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.IllegalState,
          "Error while encoding Asn1Integer object: '" + byte + "' is not a supported value.");
      }
      this.encoding = [byte];
    }

    public encode(): number[] {
      return ASN1Util.encodePrimitiveTag(ASN1Util.TagClasses.UNIVERSAL, 0x02)
                 .concat(ASN1Util.encodeLength(this.encoding.length).concat(this.encoding));
    }
  }
}
