///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags.Userkeys {

  export const userKeyTag = makeTag(undefined, asUserKey);

  const UserKey = {
    certificate: bytesTag( 40000),
    displayText: bytesTag(40001),
    keyId: longTag(40002),
    publicKey: bytesTag(40003),
    deprecationDate: longTag(40004),
    keyDigest: bytesTag(40005)
  };

  export function asUserKey(content: number[]): KeyEntry {
    const tags = parseTags(content);

    return KeyEntry(
      tags.get(UserKey.keyId),
      tags.get(UserKey.publicKey),
      tags.get(UserKey.deprecationDate),
      tags.getOptional(UserKey.certificate),
      tags.getOptional(UserKey.displayText),
      tags.get(UserKey.keyDigest),
      undefined //placeholder
    );
  }

}
