namespace Cryptomathic.Messages {

  /**
  * Defines the error codes that may be received from the server.
  **/
  export enum SignerErrorCodes {
    OK = 0,
    COMMAND_FAILED_ON_SERVER = 17,
    UNEXPECTED_COMMAND_PARAMETER = 33,
    MALFORMED_COMMAND_PARAMETER = 34,
    COMMAND_DISABLED = 35,
    INSUFFICIENT_PRIVILEGES = 36,
    USER_DISABLED = 37,
    KEY_DISABLED = 38,
    NO_TOKENS_AVAILABLE = 39,
    AUTHENTICATION_FAILED_TRY_AGAIN = 40,
    AUTHENTICATION_FAILED_FORCED_LOGOFF = 41,
    AUTHENTICATION_NO_CHALLENGE = 42,
    TOO_MANY_FAILED_ATTEMPTS_TOKEN_IS_SUSPENDED = 43,
    AUTHENTICATION_BAD_TOKEN_DATA = 44,
    AUTHENTICATION_TOKEN_NOT_USABLE = 45,
    USER_SUSPENDED = 48,
    USER_IS_ENABLED = 51,
    PRIVILEGE_NOT_FOUND = 52,
    KEY_ALREADY_HAS_CERTIFICATE = 53,
    CERTIFICATE_DOES_NOT_MATCH_KEY = 54,
    KEY_DEPRECATED = 55,
    GENERAL_ERROR = 1000,
    FORCED_LOGOFF = 1001,
    COMMUNICATION_ERROR = 1002,
    NOT_LOGGED_IN = 1003,
    DESERIALISATION_ERROR = 1004,
    INTERMITTENT_SERVER_ERROR_TRY_AGAIN = 1005,
    AUTHENTICATOR_DISABLED = 1006,
    INVALID_SAML_ASSERTION = 1007
  }
}

namespace Cryptomathic.Messages.SignerErrorCodes {

  /**
   * Get the name of the error code from the numeric value
   */
  export function getError(code: number): string {
    const errorCode = SignerErrorCodes[code];

    return errorCode?errorCode:code.toString();
  }

}
