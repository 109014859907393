///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags {
  export const bytesTag = makeTag(encodeBytes, asBytes);

  export function asBytes(data: number[]): number[] {
    return data;
  }

  export function encodeBytes(value: number[]): number[] {
    return value;
  }
}
