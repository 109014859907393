///<reference path="TLV.ts"/>
///<reference path="../../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Tags {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;
  import TlvInfo = Cryptomathic.Messages.Tags.TLV.TlvInfo;
  import parse = Cryptomathic.Messages.Tags.TLV.parse;

  export function listTag<T>(tag: number, elementTag: LogicalTag<T>): LogicalTag<T[]> {
    return {
      tag,
      get: (tagMap: TagMap): T[] => get(tag, tagMap, asList(elementTag)),
      getOptional: (tagMap: TagMap): T[] => getOptional(tag, tagMap, asList(elementTag)),
      encode(value: any): number[] {
        throw new CrmError(ErrorTypes.UnknownError, "Encoder not implemented for tag");
      }
    };
  }

  function asList<R>(elementTag: LogicalTag<R>): (value: number[])=>R[] {
    return (input: number[]) => parseList(input, elementTag);
  }

  export function parseList<R>(input: number[], elementTag: LogicalTag<R>, startPos?: number, endPos?: number): R[] {

    const addToList = (lst: R[], tlv: TlvInfo)=> {
      // the callback gets each tag in the list one at a time and need to parse them individually.
      const tagMap = parseTags(tlv.raw);

      lst.push(tagMap.get(elementTag));
      return lst;
    };

    return parse(input, [], addToList, startPos, endPos);
  }


}
