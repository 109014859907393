namespace Cryptomathic.Crypto {
  export class SHA512 {
    public static SIGNATURE_ID = "SHA512withRSA";
    public static DIGEST_SIZE = 64;

    private static H0 = [
      Int64.fromInts(0x6A09E667, 0xF3BCC908),
      Int64.fromInts(0xBB67AE85, 0x84CAA73B),
      Int64.fromInts(0x3C6EF372, 0xFE94F82B),
      Int64.fromInts(0xA54FF53A, 0x5F1D36F1),
      Int64.fromInts(0x510E527F, 0xADE682D1),
      Int64.fromInts(0x9B05688C, 0x2B3E6C1F),
      Int64.fromInts(0x1F83D9AB, 0xFB41BD6B),
      Int64.fromInts(0x5BE0CD19, 0x137E2179)
    ];

    public static hash(messageBytes: number[]|Uint8Array): number[] {
      return Cryptomathic.Crypto.Sha2w64Support.hash(messageBytes, SHA512.H0);
    }
  }
}
