namespace Cryptomathic.Messages {

  /**
  * Defines the available commands and their IDs.
  **/
  export enum CommandIDs {
    Get_Challenge =      12,
    Escalate =           13,
    Escalate_and_Sign =  14,
    Do_Operation =       22,
    Noop =               99,
    Logoff =             100
  }

}
