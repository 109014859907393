///<reference path="Tags/Int.ts"/>
///<reference path="Tags/TagMap.ts"/>
///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Response {

  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;
  import asInt = Cryptomathic.Messages.Tags.asInt;
  import parseTags = Cryptomathic.Messages.Tags.parseTags;
  import TagMap = Cryptomathic.Messages.Tags.TagMap;

  export function parseSignerResponse(response: number[], expectedMagic: string, commandNameForErrorMessages?: string): TagMap {
    // the message preamble do NOT follow the usual TLV format

    // 4 bytes magic follows by 4 bytes length, payload
    // (note: the response format does not carry a version, the version of the response must by convention follow the version of the request)

    if (!Array.isArray(response) || response.length < 8) {
      throw new CrmError(ErrorTypes.SerializationError,"Length of response is too short");
    }

    const responseType = Cryptomathic.Utils.StringUtils.bytes2string(response.slice(0, 4));
    if (responseType !== expectedMagic) {
      throw new CrmError(ErrorTypes.SerializationError,"Unexpected response type: " + responseType+ " (expected "+expectedMagic+")");
    }

    const length = asInt(response.slice(4,8));
    if (length+8!==response.length) {
      throw new CrmError(ErrorTypes.SerializationError, "Inconsistent response length");
    }

    // content

    const tags = parseTags(response, 8);

    const errorCode = tags.get(Tags.Response.errorCode);
    if (errorCode !== SignerErrorCodes.OK && errorCode !== SignerErrorCodes.FORCED_LOGOFF) { //FORCED_LOGOFF is a kind of success
      const prefix = commandNameForErrorMessages ? commandNameForErrorMessages+" response contained" : "Signer returned";
      throw new CrmError(ErrorTypes.SignerError,prefix+" error code: " + Cryptomathic.Messages.SignerErrorCodes.getError(errorCode), errorCode);
    }

    return tags;
  }
}
