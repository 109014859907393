///<reference path="Byte.ts"/>
///<reference path="Bytes.ts"/>
///<reference path="String.ts"/>
///<reference path="Int.ts"/>
namespace Cryptomathic.Messages.Tags {
  export const HsmResponse = {
    sequenceNumber: intTag(0x0001),
    returnCode: intTag(0x0002),
    sessionAuthLevel: byteTag(0x0003),
    signature: bytesTag( 0x0010),
    authenticationChallengeId: stringTag(0x0032),
    authenticationChallengeData: stringTag(0x0033),
    nonce: bytesTag(0x0040),
    serverSessionKey: bytesTag(0x0041)
  };

}
