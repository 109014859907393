namespace Cryptomathic.SignerUserSDK {

  /**
   * This is the error type returned in reject callbacks.
   * @namespace Cryptomathic.SignerUserSDK
   * @class ErrorTypes
   */
  export enum ErrorTypes {
    /**
     * The internal state of the SDK object is invalid.
     * @property IllegalState
     * @type ErrorTypes
     * @static
     **/
    IllegalState = 1,

    /**
     * A connection error occurred.
     * @property ConnectionError
     * @type ErrorTypes
     * @static
     **/
    ConnectionError = 2,

    /**
     * The SDK received an error from Signer.
     * @property SignerError
     * @type ErrorTypes
     * @static
     **/
    SignerError = 3,

    /**
     * SDK is not initialized and is not ready for use.
     * @property NotInitializedError
     * @type ErrorTypes
     * @static
     **/
    NotInitializedError = 4,

    /**
     * An internal cryptographic operation in the SDK failed.
     * @property CryptoError
     * @type ErrorTypes
     * @static
     **/
    CryptoError = 5,

    /**
     * An unknown error occurred.
     * @property UnknownError
     * @type ErrorTypes
     * @static
     **/
    UnknownError = 6,

    /**
     * An invalid response (e.g. an incorrect sequence number) was received from Signer.
     * @property InvalidResponseError
     * @type ErrorTypes
     * @static
     **/
    InvalidResponseError = 7,

    /**
     * An error occurred during serialization of requests to or responses from Signer.
     * @property SerializationError
     * @type ErrorTypes
     * @static
     **/
    SerializationError = 8,

    /**
     * A function was given an invalid argument.
     * @property InvalidArgumentError
     * @type ErrorTypes
     * @static
     **/
    InvalidArgumentError = 9,

    /**
     * A token was found to be invalid.
     * @property AuthTokenError
     * @type ErrorTypes
     * @static
     **/
    AuthTokenError = 10,

    /**
     * An internal operation failed during a big number calculation.
     * @property BigNumError
     * @type ErrorTypes
     * @static
     **/
    BigNumError = 11,

    /**
     * No SCK has been loaded.
     * @property NoSckError
     * @type ErrorTypes
     * @static
     **/
    NoSckError = 12,

    /**
     * The SDK cannot be initialized. The SDK cannot run in insecure environments (i.e. window.location.protocol !== 'https:').
     * @property SecureEnvError
     * @type ErrorTypes
     * @static
     **/
    SecureEnvError = 13,

    /**
     * No CTK has been loaded and a function was called that requires it.
     * @property NoCtkError
     * @type ErrorTypes
     * @static
     **/
    NoCtkError = 14
  }

}
