///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto.Sha2w32Support {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export const DIGEST_SIZE = 32;
  export const HMAC_BLOCKSIZE = 64;

  function ROR(x: number, n: number) {
    return (x >>> n) | (x << (32 - n));
  }

  function R(x: number, n: number) {
    return (x >>> n);
  }

  function sigma0_256(x: number) {
    return (ROR(x, 7) ^ ROR(x, 18) ^ R(x, 3));
  }

  function sigma1_256(x: number) {
    return (ROR(x, 17) ^ ROR(x, 19) ^ R(x, 10));
  }

  function Sigma0_256(x: number) {
    return (ROR(x, 2) ^ ROR(x, 13) ^ ROR(x, 22));
  }

  function Sigma1_256(x: number) {
    return (ROR(x, 6) ^ ROR(x, 11) ^ ROR(x, 25));
  }

  function Ch(x: number, y: number, z: number) {
    return ((x & y) ^ ((~x) & z));
  }

  function Maj(x: number, y: number, z: number) {
    return ((x & y) ^ (x & z) ^ (y & z));
  }

  function updateState(s: number[] | Uint8Array, index: number, state: number[]): number[] {
    let j;

    let A;
    let B;
    let C;
    let D;
    let E;
    let F;
    let G;
    let H;
    let T1;
    let T2;

    const W = new Array(64);
    const K = [0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5, 0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174, 0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da, 0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967, 0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85, 0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070, 0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3, 0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2];

    A = state[0];
    B = state[1];
    C = state[2];
    D = state[3];
    E = state[4];
    F = state[5];
    G = state[6];
    H = state[7];

    for (j = 0; j < 16; j++) {
      W[j] = (s[4 * j + index] << 24) + (s[4 * j + index + 1] << 16) + (s[4 * j + index + 2] << 8) + s[4 * j + index + 3];
    }

    for (j = 16; j < 64; j++) {
      W[j] = sigma1_256(W[j - 2]) + W[j - 7] + sigma0_256(W[j - 15]) + W[j - 16] & 0xffffffff;
    }


    /* do the rounds */
    for (j = 0; j < 64; j++) {
      T1 = H + Sigma1_256(E) + Ch(E, F, G) + K[j] + W[j] & 0xffffffff;
      T2 = Sigma0_256(A) + Maj(A, B, C) & 0xffffffff;

      H = G;
      G = F;
      F = E;
      E = D + T1 & 0xffffffff;
      D = C;
      C = B;
      B = A;
      A = T1 + T2 & 0xffffffff;
    }

    state[0] = A + state[0] & 0xffffffff;
    state[1] = B + state[1] & 0xffffffff;
    state[2] = C + state[2] & 0xffffffff;
    state[3] = D + state[3] & 0xffffffff;
    state[4] = E + state[4] & 0xffffffff;
    state[5] = F + state[5] & 0xffffffff;
    state[6] = G + state[6] & 0xffffffff;
    state[7] = H + state[7] & 0xffffffff;
    return state;
  }


  export function hash(s: number[] | Uint8Array, initHash: ReadonlyArray<number>): number[] {
    /* note that "s" can be quite large, and we really do not care if it is either number[] or Uint8Array, but we do NOT
       want to convert one to the other, because there might not be room for that */
    let state = initHash.slice(0);

    const l = s.length;
    if (l>4294967295) throw new CrmError(ErrorTypes.InvalidArgumentError,"Message too long.");

    let j;
    const fullBlocks = Math.floor(l / 64);

    /* Initial part */
    for (j = 0; j<fullBlocks;j++) {
      state = updateState(s, j * 64, state);
    }

    const tailLength = l - fullBlocks * 64;
    let lastBlockLength;
    /* We need one byte for 0x80 padding and 8 bytes for original length */
    if (tailLength <= 64 - 1 - 8) {
      lastBlockLength = 64;
    } else {
      lastBlockLength = 128;
    }

    const m = new Uint8Array(lastBlockLength);
    for (j = 0; j < tailLength; j++) {
      m[j] = s[j+fullBlocks*64];
    }

    /* Padding */
    m[j] = 0x80;
    j++;
    for(;j<lastBlockLength - 8; j++) {
      m[j] = 0;
    }

    const bitLength = l * 8;
    m[j++] = 0;
    m[j++] = 0;
    m[j++] = 0;
    m[j++] = 0;
    m[j++] = (bitLength >>> 24) & 0xff;
    m[j++] = (bitLength >>> 16) & 0xff;
    m[j++] = (bitLength >>>  8) & 0xff;
    m[j++] = (bitLength) & 0xff;

    state = updateState(m, 0, state);
    if (lastBlockLength===128) {
      state = updateState(m, 64, state);
    }

    const result = new Array(32);
    for (j=0; j<8;j++) {
      result[j*4] = (state[j] >>> 24) & 0xff;
      result[j*4+1] = (state[j] >>> 16) & 0xff;
      result[j*4+2] = (state[j] >>> 8)& 0xff;
      result[j*4+3] = state[j] & 0xff;
    }
    return result;
  }
}
