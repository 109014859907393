///<reference path="./TLV.ts"/>
///<reference path="TLVUtils.ts"/>
///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags {
  import assertLength = Cryptomathic.Messages.Tags.Utils.assertLength;

  export const intTag = makeTag(encodeInt, asInt);

  export function asInt(data: number[]): number {
    assertLength(data, 4, "Integer");

    return data[3]+256*(data[2]+256*(data[1]+256*data[0]));
  }

  export function encodeInt(value: number): number[] {
    return [
      (value >> 24) & 0xff,
      (value >> 16) & 0xff,
      (value >> 8) & 0xff,
      value & 0xff
    ];
  }

}
