///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Comm {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class SignerConnection {
    private readonly url: string;
    private readonly timeout: number;

    /**
     * Used to send messages to Signer (the Signer Forwarder, to be exact).
     * Each message is sent using a one-shot connection, so this object
     * does not need to be opened or closed in any way; it just remembers
     * where the messages need to go.
     *
     * This class requires a browser that supports CORS, and this should be
     * correctly configured on the page serving this script as well as the
     * Signer Forwarder.
     *
     * @param url A string containing the Signer Forwarder URL (must be HTTP).
     * @param timeout An unsigned long containing the network timeout in milliseconds.
     */
    public constructor(url: string, timeout: number) {
      if (!url || typeof url !== "string") {
        throw new CrmError(ErrorTypes.InvalidArgumentError,"Invalid url, must be non-empty string");
      }
      if (!timeout || typeof timeout !== "number" || Math.floor(timeout) !== timeout || timeout < 0) {
        throw new CrmError(ErrorTypes.InvalidArgumentError,"Invalid timeout, must be a positive integer");
      }
      if (SignerConnection.isAbsoluteAndNonSecure(url)) {
        throw new CrmError(ErrorTypes.SecureEnvError,"Invalid url, must be relative or secure absolute (https://)");
      }

      this.url = url;
      this.timeout = timeout;
    }

    public getUrl(): string {
      return this.url;
    }

    public getTimeout(): number {
      return this.timeout;
    }

    /**
     * Sends a message to Signer (Forwarder).
     * This method is asynchronous and returns a response or error via the given
     * response and error callbacks. If the 'resolveCallback' callback is called, the 'rejectCallback'
     * callback will not be used, and vice versa.
     *
     * @param bytes an ArrayBuffer containing the bytes to send
     * @param resolveCallback a callback that is used when a response is received
     * @param rejectCallback a callback for handling errors
     */
    public send(bytes: number[], resolveCallback: (response: number[])=>void, rejectCallback: (errorType: ErrorTypes, message: string)=>void) {

      function doReject(msg: string): ()=>void {
        return () => {
          rejectCallback(ErrorTypes.ConnectionError, "Failed to send request to Signer: " + msg);
        };
      }

      const req = new XMLHttpRequest();
      req.open("POST", this.url);
      req.withCredentials = true;
      req.timeout = this.timeout;
      req.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");

      // older IE don't seem to call onload properly, so we have to use the old event
      if (SignerConnection.isOldIE()) {
        req.onreadystatechange = () => {
          if (req.readyState === 4) {
            SignerConnection.handleResponse(req, resolveCallback, doReject("Communication failed with status " + req.status + " " + req.statusText));
          }
        };
      } else { // otherwise we use the new events instead to get more precise output
        req.onload = () => { SignerConnection.handleResponse(req, resolveCallback, doReject("Communication failed with status " + req.status + " " + req.statusText)); };
        req.onerror = doReject("Communication failed with status " + req.status + " " + req.statusText);
        req.ontimeout = doReject("Request timed out");
      }

      const b64 = Cryptomathic.Utils.Base64.encode(bytes);
      req.send(b64);
    }

    private static handleResponse(req: XMLHttpRequest, resolveCallback: (response: number[])=>void, rejectCallback: ()=>void) {
      if (req.status === 200) {
        resolveCallback(Cryptomathic.Utils.Base64.decode(req.responseText));
      } else {
        rejectCallback();
      }
    }

    private static isOldIE(): boolean {
      // documentMode is an IE-only property in IE8+
      // that returns the version of IE that IE used to displayed the page
      // (IE may choose to display as a version older than itself)
      // thus the value is <= 8 if we are in IE8, but it may actually be <= 8
      // even in newer IEs
      return (document as any).documentMode <= 8;
    }

    private static isAbsoluteAndNonSecure(url: string): boolean {
      const urlDecomposition = new Cryptomathic.Utils.UrlDecomposition(url);
      return urlDecomposition.isAbsolute() && !urlDecomposition.isScheme("https");
    }

 }
}
