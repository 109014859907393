namespace Cryptomathic.Messages {
  /**
   * The operations allowed by policy for keys issued under the policy. Returned by the {{#crossLink "Cryptomathic.SignerUserSDK.SDK/createSession:method"}}createSession{{/crossLink}}
   * method of the SDK object as part of the {{#crossLink "Cryptomathic.Messages.PolicyEntry"}}{{/crossLink}} object
   *
   * @namespace Cryptomathic.Messages
   * @class SignatureType
   */

  export enum SignatureType {
    /**
     * RAW
     *
     * When using a RAW policy, signatures are encoded client side, in practice by the SDK.
     *
     * RAW policies are not compatible with bound hashes in SAML assertions; bound hashes is a prerequisite for CC
     * compliance.
     *
     * @type SignatureType
     * @property RAW
     */
    RAW = 0x3,

    /**
     * PSS
     *
     * Instructs the server to make a PSS signature based on the provided hash.
     *
     * Policies of this type are compatible with bound hashes in SAML assertions; bound hashes is a prerequisite for CC
     * compliance.
     *
     * @type SignatureType
     * @property SIGN_PSS
     */
    SIGN_PSS = 0x1,

    /**
     * PKCS1
     *
     * Instructs the server to make a PKCS#1 signature based on the provided hash.
     *
     * Policies of this type are compatible with bound hashes in SAML assertions; bound hashes is a prerequisite for CC
     * compliance.
     *
     * @type SignatureType
     * @property SIGN_PKCS1
     */
    SIGN_PKCS1 = 0x2
  }
}


