///<reference path="Boolean.ts"/>
///<reference path="Long.ts"/>
///<reference path="Int.ts"/>
namespace Cryptomathic.Messages.Tags  {

  export const Response = {
    errorCode: intTag( 10000),
    sessionId: longTag(10010),
    authenticatorEnabled: booleanTag( 10011),
    hsmEncryption: bytesTag( 10001),
    serverEncryption: bytesTag( 10002)
  };

}
