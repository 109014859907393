namespace Cryptomathic.Messages {
  export function KeyEntry(keyId: Cryptomathic.Types.KeyId, keyPublicKey: number[], keyExpiredDate: Cryptomathic.Types.LongDate, keyCertBytes: number[], keyCertDisplayText: number[], keyDigest: number[], policyEntry: PolicyEntry): KeyEntry {
    return {
      KEY_ID: keyId,
      KEY_PUBLIC_KEY: keyPublicKey,
      KEY_EXPIRED_DATE: keyExpiredDate,
      KEY_CERT_BYTES: keyCertBytes,
      KEY_CERT_DISPLAY_TEXT: keyCertDisplayText,
      OWNING_POLICY: policyEntry,
      KEY_DIGEST: keyDigest,
    };
  }

  export function getKeySize(keyEntry: KeyEntry): number {
    const spki = Cryptomathic.ASN1.SubjectPublicKeyInfo.createFromEncoded(keyEntry.KEY_PUBLIC_KEY);
    return spki.getModulus().length;
  }

}
