///<reference path="TagMap.ts"/>
///<reference path="../../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Tags.Tokens {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  const Token = {
    type: byteTag(0x0200),
    serial: stringTag(0x0201),
    tag: stringTag(0x0203)
  };

  export const tokenTag = makeTag(encodeToken, asToken);

  export function fromId(id: number): TokenType {
    switch(id) {
      case 6: return "SMS";
      case 8: return "OATH-HOTP";
      case 9: return "OATH-TOTP";
      case 10: return "OATH-OCRA";
      default:
        throw new CrmError(ErrorTypes.SerializationError, "Unknonwn value for token type: "+id);
    }
  }

  export function fromName(tokenType: TokenType): number {
    switch(tokenType) {
      case "SMS": return 6;
      case "OATH-HOTP": return 8;
      case "OATH-TOTP": return 9;
      case "OATH-OCRA": return 10;
      default:
        throw new CrmError(ErrorTypes.SerializationError, "Unknonwn name for a token type: "+tokenType);
    }
  }

  export function encodeToken(authenticationToken: AuthenticationToken): number[] {
    const tokenTypeId = fromName(authenticationToken.getTokenType());

    return Token.type.encode(tokenTypeId).concat(
      Token.serial.encode(authenticationToken.getTokenSerial()),
      Token.tag.encode(authenticationToken.getTokenTag())
    );
  }

  export function asToken(content: number[]): AuthenticationToken {
    const tags = parseTags(content);

    return new AuthenticationToken(
      fromId(tags.get(Token.type)),
      tags.get(Token.serial),
      tags.get(Token.tag)
    );
  }
}
