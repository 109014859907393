///<reference path="Policies.ts"/>
///<reference path="Tokens.ts"/>
///<reference path="List.ts"/>

namespace Cryptomathic.Messages.Tags {

  import tokenTag = Cryptomathic.Messages.Tags.Tokens.tokenTag;
  import policyTag = Cryptomathic.Messages.Tags.Policies.policyTag;

  export const CreateSessionResponse = {
    tokenList: listTag(20002, tokenTag(20003)),
    policyList: listTag(20004, policyTag(20005))
  };

}
