///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.ASN1.X509 {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

    interface Iterator {
      bytes: number[];
      offset: number;
    }

    interface Lengths {
      length: number;
      encodingLength: number;
      totalLength: number;
    }

    function readBase256(bytes: number[], offset: number, len: number) {
      let val = 0;
      for (let i = 0; i < len; ++i) {
        if (i > 0) val *= 256;
        val += bytes[i + offset];
      }
      return val;
    }

    function parseLength(iter: Iterator): Lengths {
      const {bytes, offset} = iter;

      const byte0 = bytes[offset];
      if (byte0 <= 127) {
        return {length: byte0, encodingLength: 1, totalLength: 1 + byte0};
      }
      const lenBytes = byte0 & 0x7F;
      if (lenBytes >= bytes.length + offset) {
        throw new CrmError(ErrorTypes.CryptoError, "Incorrect length encoding in X.509");
      }
      const len = readBase256(bytes, offset + 1, lenBytes);
      return {length: len, encodingLength: 1 + lenBytes, totalLength: 1 + lenBytes + len};
    }

    // can only handle 1-byte tags
    function skipTag(iter: Iterator, tag: number): void {
      const {bytes, offset} = iter;
      if (offset + 1 >= bytes.length) {
        throw new CrmError(ErrorTypes.CryptoError, "Tag " + tag + " expected past end of encoding in X.509");
      }
      if (bytes[offset] !== tag) {
        throw new CrmError(ErrorTypes.CryptoError, "Expected tag " + tag + " but found " + bytes[0]);
      }
      iter.offset++;
    }

  function skipLength(iter: Iterator): number {
      const len = parseLength(iter);
      iter.offset += len.encodingLength;
      return len.length;
    }

    function skipTagAndLength(iter: Iterator, tag: number): void {
      skipTag(iter, tag);
      skipLength(iter);
    }

    function skipObjectWithTag(iter: Iterator, tag: number): void {
      skipTag(iter, tag);
      const len = skipLength(iter);
      if (iter.offset + len >= iter.bytes.length) {
        throw new CrmError(ErrorTypes.CryptoError, "Attempted to read past end of encoding in X.509");
      }
      iter.offset += len;
    }

    function readEntireObject(iter: Iterator): number[] {
      let bytes = [iter.bytes[iter.offset++]];
      const len = parseLength(iter);
      if (iter.offset + len.totalLength  > iter.bytes.length) {
        throw new CrmError(ErrorTypes.CryptoError, "Attempted to read past end of encoding in X.509");
      }
      bytes = bytes.concat(iter.bytes.slice(iter.offset, iter.offset + len.totalLength));
      iter.offset += len.totalLength;
      return bytes;
    }

    // returns {serial: <serial as byte array>, issuer: <issuer as byte array>}
    export function readIssuerAndSerial(cert: number[]): {serial: number[], issuer: number[]} {
      const iter = {bytes: cert, offset: 0};
      skipTagAndLength(iter, 0x30); // sequence: Certificate
      skipTagAndLength(iter, 0x30); // sequence: TBSCertificate
      if (iter.bytes[iter.offset]===0xa0) {
        skipObjectWithTag(iter, 0xa0); // explicit [0]: Version
      }
      // next object is serialNumber (CertificateSerialNumber)
      const serial = readEntireObject(iter);
      skipObjectWithTag(iter, 0x30); //  sequence: AlgorithmIdentifier
      // next object is issuer (Name)
      const issuer = readEntireObject(iter);
      return {serial, issuer};
    }

}
