namespace Cryptomathic.ASN1 {
  export const Constants = Object.freeze({

    rsaEncryptionOid: new Asn1Literal([0x06, 0x09, 0x2a, 0x86, 0x48, 0x86, 0xf7, 0x0d, 0x01, 0x01, 0x01]),
    nullAlgorithmIdentifierParameters: new Asn1Literal([0x05, 0x00]),

    pkcs7SignedData: new Asn1Literal([0x06, 0x09, 0x2A, 0x86, 0x48, 0x86, 0xF7, 0x0D, 0x01, 0x07, 0x02]),
    pkcs7Data: new Asn1Literal([0x06, 0x09, 0x2A, 0x86, 0x48, 0x86, 0xF7, 0x0D, 0x01, 0x07, 0x01]),

    sha256Oid: new Asn1Literal([0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x01]),
    sha384Oid: new Asn1Literal([0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x02]),
    sha512Oid: new Asn1Literal([0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x03]),

    rsaSsaPssOid: new Asn1Literal([0x06, 0x09, 0x2A, 0x86, 0x48, 0x86, 0xF7, 0x0D, 0x01, 0x01, 0x0A]),
    mgf1Oid: new Asn1Literal([0x06, 0x09, 0x2A, 0x86, 0x48, 0x86, 0xF7, 0x0D, 0x01, 0x01, 0x08]),

    /**
     * @deprecated
    */
    sha1Oid: new Asn1Literal([0x06, 0x05, 0x2B, 0x0E, 0x03, 0x02, 0x1A])
  });
}

