namespace Cryptomathic.Crypto.SessionKeyUtils {
  import encodeInt = Cryptomathic.Messages.Tags.encodeInt;

  function lvEncode(data: number[]): number[] {
    return encodeInt(data.length).concat(data);
  }

  export function createSessionKey(nonce1: number[], nonce2: number[], helloKey: number[], createRequest: number[], response: number[]): number[] {
    const data = [].concat(
      lvEncode(nonce1),
      lvEncode(nonce2),
      lvEncode(helloKey),
      lvEncode(createRequest),
      lvEncode(response)
    );

    return Cryptomathic.Crypto.SHA256.hash(data);
  }

  export function deriveNewKey(otpBytes: number[], salt: number[], challenge: string, oldSecret: number[]): number[] {
    const data = [].concat(
      lvEncode(otpBytes),
      lvEncode(salt),
      challenge===null ? [] : lvEncode(Cryptomathic.Utils.StringUtils.string2bytes(challenge))
    );

    const keyMaterial = Cryptomathic.Crypto.SHA256.hash(data);

    return Cryptomathic.Utils.ByteArrayUtils.xor(oldSecret, keyMaterial);
  }

}
