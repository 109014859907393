namespace Cryptomathic.Messages {
  export class AuthenticationChallenge {
    private challengeId: string;
    private challengeData: string;

    /**
     * A Signer server authentication challenge retrieved by the {{#crossLink "Cryptomathic.SignerUserSDK.SDK/getChallenge:method"}}getChallenge{{/crossLink}} method of the SDK object.
     *
     * @class AuthenticationChallenge
     * @namespace Cryptomathic.Messages
     * @param challengeId {String}
     * @param challengeData {String}
     */
    public constructor(challengeId: string, challengeData: string) {
      this.challengeId = challengeId;
      this.challengeData = challengeData;
    }

    /**
     * Returns the id of the authentication challenge.
     * @method getChallengeId
     * @return {String}
     */
    public getChallengeId() {
      return this.challengeId;
    }

    /**
     * Returns the data of the authentication challenge or the empty string for SMS token challenges.
     * @method getChallengeData
     * @return {String}
     */
    public getChallengeData() {
      return this.challengeData;
    }

  }
}
