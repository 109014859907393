namespace Cryptomathic.Error {
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class CrmError {

    // qunit uses .message for comparison
    public message: string;

    private type: ErrorTypes;
    private msg: string;
    private signerErrorCode: number;

    public constructor(type: ErrorTypes, msg: string, signerErrorCode?: number) {
      // make sure values appear when printing object
      this.type = type;
      this.message = type + ": " + msg;
      this.msg = msg;
      this.signerErrorCode = signerErrorCode?signerErrorCode:null;
    }

    public getType(): ErrorTypes {
      return this.type;
    }
    public getMessage(): string {
      // This is on purpose - this is what the customer sees and should see.
      // The other message field is for qunit only.
      return this.msg;
    }
    public getSignerErrorCode() {
      return this.signerErrorCode;
    }
    public setSignerErrorCode(signerErrorCode: number) {
      this.signerErrorCode = signerErrorCode;
    }

  }
}
