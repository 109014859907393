///<reference path="Tags/Command.ts"/>
namespace Cryptomathic.Messages.Request {

  import Command = Cryptomathic.Messages.Tags.Command;

  /**
  * Constructs a create session request and return it as a byte array.
  **/
  export function createSessionRequest(sckKeyID: Cryptomathic.Types.KeyId, encryptedContent: number[], activeKeysOnly: boolean, saml?: Cryptomathic.Types.ByteArray) {
    const cmd = createSessionUserCommand(sckKeyID, encryptedContent, activeKeysOnly, saml);

    return createRequest("SSCP", cmd);
  }

  function createSessionUserCommand(sckKeyID: Cryptomathic.Types.KeyId, encryptedContent: number[], activeKeysOnly: boolean, saml?: Cryptomathic.Types.ByteArray): number[] {
    //the order and number of tags are defined in com.cryptomathic.signer.api.user.message.v2.CreateSessionCommand
    //the actual tags and their type is defined in com.cryptomathic.signer.api.user.tlv.CommandTag

    const logonProtocolID = 1; //SAML logon type (cf. com.cryptomathic.signer.api.user.message.CreateSessionLogonProtocolType

    return Command.createSessionProtocolId.encode(logonProtocolID).concat(
      Command.sckId.encode(sckKeyID),
      Command.sckEncryption.encode(encryptedContent),
      Command.activeKeysOnly.encode(activeKeysOnly),
      Command.samlAssertion.encode(saml)
    );

  }
}
