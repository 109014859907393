///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto {
  const GCM_IV_LENGTH = 12; // in bytes

  export function gcmDecrypt(encryptedContent: number[], key: number[]): number[] {
    const iv = encryptedContent.slice(0, GCM_IV_LENGTH);
    const cipherText = encryptedContent.slice(GCM_IV_LENGTH, encryptedContent.length);

    return Cryptomathic.Crypto.Gcm.decrypt(key, cipherText, iv);
  }

  export function gcmEncrypt(content: number[], key: number[]): number[] {
    const random = Cryptomathic.Crypto.Random.SecureRandom.getInstance();
    const iv = random.generate(GCM_IV_LENGTH);

    return iv.concat(Cryptomathic.Crypto.Gcm.encrypt(key, content, iv));
  }
}


namespace Cryptomathic.Crypto.CryptoUtils {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;
  import SubjectPublicKeyInfo = Cryptomathic.ASN1.SubjectPublicKeyInfo;

  /**
   * Constructs the content for a create session request and returns it encrypted by the Session Creation Key.
   */
  export function createEncryptedContentForCreateSessionRequest(spki: SubjectPublicKeyInfo, nonce1: Uint8Array, helloKey: Uint8Array, resolveCallback: (result: number[])=>void, rejectCallback: (msg: string)=>void): void {
    this.encryptRsaWithOaep(
      spki,
      Cryptomathic.Utils.ByteArrayUtils.concat(nonce1, helloKey),
      resolveCallback,
      rejectCallback
    );
  }

  export function encryptRsaWithOaep(spki: SubjectPublicKeyInfo, message: Uint8Array, resolveCallback: (result: number[])=>void, rejectCallback: (msg: string)=>void): void {
    const modLen = spki.getModulus().length;
    if (modLen<256) {
      throw new CrmError(ErrorTypes.CryptoError, "Only keys with length 2k or above is supported");
    }

    const oaep = Cryptomathic.Crypto.OAEP.oaepEncode(modLen, message);
    Cryptomathic.Crypto.Rsa.EncryptRsa(oaep, spki, resolveCallback, rejectCallback);
  }

  /**
   * Calculates a byte sequence suitable to be prepended a digest to wrap it in asn1
   */
  export function asn1DigestInfo(digest: Digest): number[] {
    const oid = Cryptomathic.Crypto.Digest.toAsn1Oid(digest);
    const algId = oid.encode().concat(Cryptomathic.ASN1.Constants.nullAlgorithmIdentifierParameters.encode());
    const algSeq = [0x30, algId.length].concat(algId);

    const digestAlgorith = Cryptomathic.Crypto.Digest.toAlgorithm(digest);

    return [0x30, (algSeq.length+2+digestAlgorith.DIGEST_SIZE)].concat(algSeq).concat([0x04, digestAlgorith.DIGEST_SIZE]);
  }
}

