namespace Cryptomathic.Crypto.SignHelper {
  /**
   * Hashes the message and returns a PKCS#1 encoded {{#crossLink "Cryptomathic.Crypto.SignatureRequest"}}{{/crossLink}} with the data to be signed.
   */
  export function pkcs1Padding(digest: Digest, message: number[]): SignatureRequestPkcs1 {
    return new Cryptomathic.Crypto.SignatureRequestPkcs1(digest, message, null);
  }

  /**
   * Takes the hash and returns a PKCS#1 encoded {{#crossLink "Cryptomathic.Crypto.SignatureRequest"}}{{/crossLink}} with the data to be signed.
   */
  export function pkcs1PaddingWithHash(digest: Digest, hash: number[]): SignatureRequestPkcs1 {
    return new Cryptomathic.Crypto.SignatureRequestPkcs1(digest, null, hash);
  }

  /**
   * Hashes the message and returns a request for a PSS encoded {{#crossLink "Cryptomathic.Crypto.SignatureRequest"}}{{/crossLink}} with the data to be signed.
   */
  export function pssPadding(digest: Digest, message: number[], useSalt: boolean): SignatureRequestPss {
    return new Cryptomathic.Crypto.SignatureRequestPss(digest, message, null, useSalt);
  }

    /**
     * Takes the hash of a message and returns a PSS encoded {{#crossLink "Cryptomathic.Crypto.SignatureRequest"}}{{/crossLink}} with the hash to be signed.
     */
  export function pssPaddingWithHash(digest: Digest, hash: number[], useSalt: boolean): SignatureRequestPss {
    return new Cryptomathic.Crypto.SignatureRequestPss(digest, null, hash, useSalt);
  }


  /**
   * Sets PKCS#7 encoding of the signed response from Signer.
   */
  export function encodePkcs7(signatureRequest: SignatureRequest, includeMessage: boolean): SignatureRequestPkcs7Wrapper {
    return new Cryptomathic.Crypto.SignatureRequestPkcs7Wrapper(signatureRequest, includeMessage);
  }
}
