///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class SignatureRequestPss implements SignatureRequest {

    public readonly paddingType = Cryptomathic.Crypto.SignatureRequestType.PSS;

    public readonly digest: Digest;
    public readonly hash: number[];
    public readonly message: number[];
    public readonly useSalt: boolean;

    private readonly digestAlgorithm: DigestAlgorithm;
    private readonly saltLength: number;

    public constructor(digest: Digest, message: number[], hash: number[], useSalt: boolean) {
      this.digest = digest;
      this.message = message;
      this.hash = hash;
      this.useSalt = useSalt;

      this.digestAlgorithm = Cryptomathic.Crypto.Digest.toAlgorithm(digest);
      this.saltLength = useSalt ? this.digestAlgorithm.DIGEST_SIZE : 0;

      if(!this.hash && !this.message) {
        throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.CryptoError, "Missing hash or message in PSS SignatureRequest.");
      }

      if(!this.hash) {
        this.hash = this.digestAlgorithm.hash(this.message);
      }
    }

    public getPaddedRequest(keyEntry: Cryptomathic.Messages.KeyEntry): number[] {
      return this.pssPadHash(this.digestAlgorithm, this.saltLength, keyEntry, this.hash);
    }

    private pssPadHash(digest: DigestAlgorithm, saltLength: number, key: Cryptomathic.Messages.KeyEntry, hash: number[]) {
      // See PCKS#1 v.2.1, Section 9.1.1, EMSA-PSS-Encode
      // Step 3
      const modulusSize = Cryptomathic.Messages.getKeySize(key);
      if (hash.length + saltLength + 2 > modulusSize) {
        throw new CrmError(ErrorTypes.InvalidArgumentError, "key to small to encode the hash");
      }

      // Step 4
      const random = Cryptomathic.Crypto.Random.SecureRandom.getInstance();
      const salt = saltLength === 0 ? [] : random.generate(saltLength);

      // Step 5
      const mPrime = Cryptomathic.Utils.ByteArrayUtils.emptyArray(8).concat(hash, salt);

      // Step 6
      const h = digest.hash(mPrime);

      // Step 7
      const psLength = modulusSize - saltLength - hash.length - 2;
      const ps = Cryptomathic.Utils.ByteArrayUtils.emptyArray(psLength);

      // Step 8
      const db = ps.concat( [0x01], salt);

      // Step 9
      const dbMask = Cryptomathic.Crypto.OAEP.maskGenerator(digest, h, db.length);

      // Step 10
      const maskedDb = db.map((v,i)=>v^dbMask[i]);

      // Step 11 (clear top bit)
      maskedDb[0] = (maskedDb[0] & 0x7F);

      // step 12 + 13
      return maskedDb.concat(h, [0xbc]);
    }


    public encodeSignerResponse(rawSignature: number[], keyEntry: Cryptomathic.Messages.KeyEntry): number[] {
      return rawSignature;
    }
    
  }
}
