///<reference path="UserKeys.ts"/>
///<reference path="List.ts"/>
///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags.Policies {
  import userKeyTag = Cryptomathic.Messages.Tags.Userkeys.userKeyTag;

  export const policyTag = makeTag(undefined, asPolicy);

  const PolicyTags = {
    authenticationLevel: byteTag(30000),
    signatureType: byteTag(30001),
    signatureTypeParam: bytesTag(30002),
    enabled: booleanTag(30003),
    policyId: longTag(30004),
    userKeyList: listTag(30005, userKeyTag(30006)),
    name: stringTag(30007),
    tokenList: bytesTag(30008),
    guid: bytesTag(30010)
  };

  function asPolicy(content: number[]): PolicyEntry {
    const tags = parseTags(content);

    const canDecrypt = tags.get(PolicyTags.signatureType) === Cryptomathic.Messages.SignatureType.RAW;
    const acceptedTokenTypes = tags.get(PolicyTags.tokenList).map(Tags.Tokens.fromId).join(":");

    const policyEntry = PolicyEntry(
      tags.get(PolicyTags.policyId),
      tags.get(PolicyTags.name),
      tags.get(PolicyTags.authenticationLevel),
      PolicyKeyLevel.STANDARD,
      true,
      canDecrypt,
      tags.get(PolicyTags.enabled),
      acceptedTokenTypes,
      null, //placeholder for keylist,
      tags.get(PolicyTags.signatureType),
      tags.get(PolicyTags.signatureTypeParam)
    );
    policyEntry.KEY_LIST = tags.get(PolicyTags.userKeyList);

    //all keys refer back to their "owning" policy (yep, circular reference)
    policyEntry.KEY_LIST.forEach( (key) => key.OWNING_POLICY=policyEntry);

    return policyEntry;
  }

}
