///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.ASN1 {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  import Asn1Object = Cryptomathic.ASN1.Asn1Object;

  export class SignerInfo implements Asn1Object {

    private version: Asn1Integer = null;
    private signerIdentifier: IssuerAndSerialNumber = null;
    private digestAlgorithm: AlgorithmIdentifier = null;
    private signatureAlgorithm: AlgorithmIdentifier = null;
    private signatureValue: OctetString = null;

    public setVersion(version: Asn1Integer): SignerInfo {
      this.version = version;
      return this;
    }

    public setSignerIdentifier(certificate: number[]): SignerInfo {
      const parsedCert = Cryptomathic.ASN1.X509.readIssuerAndSerial(certificate);
      this.signerIdentifier = new Cryptomathic.ASN1.IssuerAndSerialNumber(parsedCert.issuer, parsedCert.serial);
      return this;
    }

    public setDigestAlgorithm(algorithmIdentifier: AlgorithmIdentifier): SignerInfo {
      this.digestAlgorithm = algorithmIdentifier;
      return this;
    }

    public setSignatureAlgorithm(signatureAlgorithm: AlgorithmIdentifier): SignerInfo {
      this.signatureAlgorithm = signatureAlgorithm;
      return this;
    }

    /**
     * @param signatureValue Byte array containing the signature wrapped in an ASN1 OctetString.
     */
    public setSignatureValue(signatureValue: OctetString): SignerInfo {
      this.signatureValue = signatureValue;
      return this;
    }

    public encode(): number[] {
      if (this.version === null ) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding SignerInfo object, version is not set.");
      } else if(this.signerIdentifier === null) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding SignerInfo object, issuerAndSerialNumber is not set.");
      } else if(this.digestAlgorithm === null) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding SignerInfo object, digestAlgorithm is not set.");
      } else if (this.signatureAlgorithm === null) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding SignerInfo object, signatureAlgorithm is not set.");
      } else if (this.signatureValue === null) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding SignerInfo object, signatureValue is not set.");
      }

      return new Sequence()
        .push(this.version)
        .push(this.signerIdentifier)
        .push(this.digestAlgorithm)
        .push(this.signatureAlgorithm)
        .push(this.signatureValue)
        .encode();
    }
  }
}
