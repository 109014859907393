namespace Cryptomathic.Utils.StateHandler {
  import AuthenticationToken = Cryptomathic.Messages.AuthenticationToken;
  import AuthLevel = Cryptomathic.Messages.AuthLevel;

  export class StateObject {
    private softwareKey: number[] = null;
    private sessionKey: number[] = null;
    private sessionID: number[] = null;
    private lastChallengeId: string = null;
    private lastChallengeData: string = null;
    private lastChallengeToken: AuthenticationToken = null;
    private sequenceNumber = 0;
    private sessionAuthLevel = Cryptomathic.Messages.AuthLevel.NONE;

    /**
    * Increments the sequence number with 2.
    **/
    public incrementSequenceNumber(): void {
      this.sequenceNumber += 2;
    }

    public getSequenceNumber(): number {
      return this.sequenceNumber;
    }

    public hasSoftwareKey(): boolean {
      return this.softwareKey !== null;
    }

    public getSoftwareKey(): number[] {
      return this.softwareKey;
    }

    public setSoftwareKey(newSoftwareKey: number[]): void {
      this.softwareKey = newSoftwareKey;
    }


    /**
    * @returns true if session key has been set
    **/
    public hasSessionKey(): boolean {
      return this.sessionKey !== null;
    }

    public getSessionKey(): number[] {
      return this.sessionKey;
    }

    public setSessionKey(newSessionKey: number[]): void {
      this.sessionKey = newSessionKey;
    }

    public getSessionID(): number[] {
      return this.sessionID;
    }

    public setSessionID(newSessionID: number[]): void {
      this.sessionID = newSessionID;
    }

    public hasChallenge(): boolean {
      return this.lastChallengeId != null;
    }

    public setLastChallenge(token: AuthenticationToken, challengeId: string, challengeData: string): void {
      if (token.getTokenType() === "OATH-OCRA" || token.getTokenType() === "SMS") {
        this.lastChallengeToken = token;
        this.lastChallengeId = challengeId;
        if (token.getTokenType() === "OATH-OCRA") {
          this.lastChallengeData = challengeData;
        }
      } else {
        this.lastChallengeToken = null;
        this.lastChallengeData = null;
      }
    }

    public prepareEscalateSession(token: AuthenticationToken): boolean {
      switch(token.getTokenType()) {
        case "OATH-OCRA":
        case "SMS":
          if(this.lastChallengeToken !== null) {
            if(token.getTokenSerial() !== this.lastChallengeToken.getTokenSerial()) {
              return false;
            }
          }
          return true;
        case "OATH-HOTP":
        case "OATH-TOTP":
          this.lastChallengeToken = null;
          this.lastChallengeData = null;
          this.lastChallengeId = null;
          return true;
        default:
          return false;
      }
    }

    public getLastChallengeData(): string {
      return this.lastChallengeData;
    }

    public getLastChallengeId(): string {
      return this.lastChallengeId;
    }

    public setSessionAuthLevel(authLevel: AuthLevel): void {
      this.sessionAuthLevel = authLevel;
    }

    public getSessionAuthLevel(): AuthLevel {
      return this.sessionAuthLevel;
    }

    public clearLastChallenge(): void {
      this.lastChallengeData = null;
      this.lastChallengeToken = null;
      this.lastChallengeId = null;
    }
  }
}
