namespace Cryptomathic.Crypto.SHA256 {
  export const SIGNATURE_ID = "SHA256withRSA";
  export const DIGEST_SIZE = 32;

  const initialState = Object.freeze([0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a, 0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19]);

  export function hash(s: number[]|Uint8Array): number[] {
    return Cryptomathic.Crypto.Sha2w32Support.hash(s, initialState);
  }
}
