namespace Cryptomathic.Crypto.Random.EntropySources {

  enum EventSource {
      BROWSER  = 0,
      MOUSE    = 1,
      KEYBOARD = 2,
      TIME     = 3
  }

  const windowCrypto = window.crypto || window.msCrypto;
  const hasCryptRandom = windowCrypto && (typeof windowCrypto.getRandomValues === "function");

  type EventHandler<E extends Event> = (event?: E) => void;

  export interface SourcePool {
    start(): void;
    collectManually(): void;
  }

  /**
   * Creates an handler-function for at given type of events, that can update the random-generator, using the eventAdapter to convert event to a form usable by the generator
   */
  function createEventHandler<E extends Event>(eventSource: EventSource, generator: Cryptomathic.Crypto.Random.Fortuna, eventAdapter: (event?: E)=>number[]): EventHandler<E> {
    let poolNumber = 0;

    return (event?: E) => {
      const rawEvent = eventAdapter(event);

      if (rawEvent) {
        generator.addRandomEvent(eventSource, poolNumber, rawEvent);
        poolNumber = (poolNumber + 1) % 32;
      }
    };
  }

  const mouseEventAdapter = (mouseEvent: MouseEvent) => {
    const data = mouseEvent.clientX ^
      mouseEvent.clientY ^
      mouseEvent.movementX ^
      mouseEvent.movementY ^
      mouseEvent.timeStamp;
    return [data & 0xff];
  };

  const keyboardEventAdapter = (kbEvent: KeyboardEvent) => {
    const data = kbEvent.keyCode ^
      kbEvent.location ^
      (kbEvent.repeat ? 0x01 : 0x00) ^
      (kbEvent.shiftKey ? 0x02 : 0x00) ^
      (kbEvent.metaKey ? 0x04 : 0x00) ^
      (kbEvent.altKey ? 0x08 : 0x00) ^
      kbEvent.timeStamp;

    return[data & 0xff];
  };

  const timeEventAdapter = () => {
    const now = (new Date()).getTime();
    return [now & 0xff];
  };

  const browserEventAdapter = () => {
    if (hasCryptRandom) {
      const tEvt = new Uint8Array(32);
      windowCrypto.getRandomValues(tEvt);
      const evt = new Array(32);
      for (let i = 0; i < 32; ++i) {
        evt[i] = tEvt[i];
      }

      return evt;
    } else {
      return undefined;
    }
  };


  export function createSourcePool(generator: Fortuna): SourcePool {

    const updateOnMouseEvent = createEventHandler(EventSource.MOUSE, generator, mouseEventAdapter);
    const updateOnKeyboardEvent = createEventHandler(EventSource.KEYBOARD, generator, keyboardEventAdapter);
    const updateWithCurrentTime = createEventHandler(EventSource.TIME, generator, timeEventAdapter);
    const updateUsingBrowser = createEventHandler(EventSource.BROWSER, generator, browserEventAdapter);

    setInterval(this.updateBrowserAndTimeSources, 500); // update random generator regularly

    let lastAutoUpdate = new Date(0);

    function updateBrowserAndTimeSources() {
      const now = new Date();
      if (now.getTime() - lastAutoUpdate.getTime() > 50) {
        lastAutoUpdate = now;

        updateUsingBrowser();
        updateWithCurrentTime();
      }
    }

    function kbHandler(kbEvent: KeyboardEvent) {
      updateOnKeyboardEvent(kbEvent);
      updateBrowserAndTimeSources();
    }

    function mouseHandler(mouseEvent: MouseEvent) {
      updateOnMouseEvent(mouseEvent);
      updateBrowserAndTimeSources();
    }

    let started: boolean;

    return {
      start: () => {
        if (started) return;

        if (!window.addEventListener) {
          window.attachEvent("mousemove", mouseHandler);
          window.attachEvent("onkeydown", kbHandler);
        } else {
          window.addEventListener("mousemove", mouseHandler, false);
          window.addEventListener("onkeydown", kbHandler, false);
        }

        started = true;
      },
      collectManually: () => updateUsingBrowser()
    };

  }

}
