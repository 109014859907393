///<reference path="../crypto/SignatureRequest.ts"/>
namespace Cryptomathic.Messages.Sign {

  import SignatureRequest = Cryptomathic.Crypto.SignatureRequest;

  /**
   * Is this an legacy style SignatureRequest or not? Can be used as a type-guard.
   */
  export function isSignatureRequest(toSign: SignatureRequest|number[]): toSign is SignatureRequest {
    return !Array.isArray(toSign);
  }

}
