///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.ASN1 {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class AlgorithmIdentifier implements Asn1Object {

    private readonly algorithm: Asn1Object;
    private readonly parameters: Asn1Object;

    constructor (algorithm: Asn1Object, parameters: Asn1Object = Constants.nullAlgorithmIdentifierParameters) {
      this.algorithm = algorithm;
      this.parameters = parameters;
    }

    /**
     * @returns a byte array containing the encoding.
     */
    public encode(): number[] {
      if (!this.algorithm) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding AlgorithmIdentifier object, algorithm is not set");
      }
      if (!this.parameters) {
        throw new CrmError(ErrorTypes.IllegalState, "Error while encoding AlgorithmIdentifier object, parameters is not set");
      }

      return new Cryptomathic.ASN1.Sequence().push(this.algorithm)
        .push(this.parameters)
        .encode();
    }
  }

  /**
   * @param alg A SIGNATURE_ID from one of the hash functions, e.g.
   * Cryptomathic.Crypto.SHA512.SIGNATURE_ID or Cryptomathic.Crypto.SHA256.SIGNATURE_ID
   */
   export function createDigestAlgorithmIdentifier(alg: string): AlgorithmIdentifier {
    if ("SHA1/RSA" === alg || "SHA1withRSA" === alg) {
      return new AlgorithmIdentifier(Constants.sha1Oid);
    } else if ("SHA256/RSA" === alg || "SHA256withRSA" === alg) {
      return new AlgorithmIdentifier(Constants.sha256Oid);
    } else if ("SHA384/RSA" === alg || "SHA384withRSA" === alg) {
      return new AlgorithmIdentifier(Constants.sha384Oid);
    } else if ("SHA512/RSA" === alg || "SHA512withRSA" === alg) {
      return new AlgorithmIdentifier(Constants.sha512Oid);
    } else {
      throw new CrmError(ErrorTypes.IllegalState, "Unknown algorithm " + alg);
    }
  }

  /**
   * @param alg A SIGNATURE_ID from one of the hash functions, e.g.
   * Cryptomathic.Crypto.SHA512.SIGNATURE_ID or Cryptomathic.Crypto.SHA256.SIGNATURE_ID
   */
  export function createSignatureAlgorithmIdentifier(alg: string): AlgorithmIdentifier {
    if ("SHA1/RSA" === alg || "SHA1withRSA" === alg || "SHA256/RSA" === alg || "SHA256withRSA" === alg ||
      "SHA384/RSA" === alg || "SHA384withRSA" === alg || "SHA512/RSA" === alg || "SHA512withRSA" === alg ||
      "MD5/RSA" === alg || "MD5withRSA" === alg) {
      return new AlgorithmIdentifier(Constants.rsaEncryptionOid);
    } else {
      throw new CrmError(ErrorTypes.IllegalState, "Unknown algorithm " + alg);
    }
  }


}
