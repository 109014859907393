namespace Cryptomathic.Utils {
  export class UrlDecomposition {

    private url: string;

    public constructor(input: string) {
      this.url = input;
    }

    public isAbsolute(): boolean {
      // check whether url starts with 'scheme://'
      return this.url.search(/^[a-zA-Z][a-zA-Z0-9.+-]*:[/]{2}/) > -1;
    }

    public isScheme(scheme: string): boolean {
      const n = this.url.indexOf("://");
      return n < 0 ? false : scheme.toLowerCase() === this.url.substring(0, n).toLowerCase();
    }
  }
}
