namespace Cryptomathic.ASN1 {

  export interface Asn1Object {
    encode(): number[];
  }

  export class Asn1Literal implements Asn1Object {

    private readonly encoding: number[];

    public constructor(encoding: number[]) {
      this.encoding = encoding;
    }

    /**
     * @returns the encoded byte array.
     */
    public encode(): number[] {
      return this.encoding;
    }
  }
}
