///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.ASN1 {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class SubjectPublicKeyInfo {

    private readonly encoded: number[];
    private readonly modulus: number[];
    private readonly publicExponent: number[];

    public constructor(encoded: number[], modulus: number[], publicExponent: number[]) {
      this.encoded = encoded;
      this.modulus = toUnsignedBytes(modulus);
      this.publicExponent = publicExponent;
    }

    public getModulus() {
      return this.modulus;
    }

    public getPublicExponent() {
      return this.publicExponent;
    }

    public getEncoded() {
      return this.encoded;
    }

    // factory method, creates a SubjectPublicKeyInfo object containing
    // modulus and pub. exp. from SPKI bytes
    public static createFromEncoded(bytes: number[]) {
      const encoded = bytes.slice(0);
      
      const spki = readTag(encoded, 0, 0x30, "Sequence tag is incorrect");
      const algorithmIdentifier = readTag(spki.getBytes(), 0, 0x30, "The AlgorithmIdentifier tag is incorrect");

      algorithmIdentifier.checkValue("06092a864886f70d0101010500", "AlgorithmIdentifier value is incorrect");

      const bitString = readTag(spki.getBytes(), algorithmIdentifier.getRead(), 0x03, "The BitString tag is incorrect");
      const publicKey = readTag(bitString.getBytes(), 1, 0x30, "The PublicKey tag is incorrect");
      const modulus = readTag(publicKey.getBytes(), 0, 0x02, "Modulus tag is incorrect");
      const exponent = readTag(publicKey.getBytes(), modulus.getRead(),0x02, "Exponent tag is incorrect");

      return new SubjectPublicKeyInfo(encoded, modulus.getBytes(), exponent.getBytes());
    }

  }
  
  function readTag(bytes: number[], index: number, exceptedTag: number, msg: string): ASN1SubObject {
    const subObject = ASN1SubObject.decode(bytes, index);
    if (subObject.getTag() !== exceptedTag) {
      throw new CrmError(ErrorTypes.SerializationError,"Public key is not in right format. "+msg);
    }
    return subObject;
  }

  function toUnsignedBytes(modulus: number[]): number[] {
    if (modulus[0] === 0x00) {
      modulus = modulus.slice(1, modulus.length);
    }
    return modulus;
  }
}
