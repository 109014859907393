namespace Cryptomathic.Crypto.SHA224 {
  export const SIGNATURE_ID = "SHA224withRSA";
  export const DIGEST_SIZE = 28;

  const initialState = Object.freeze([0xc1059ed8, 0x367cd507, 0x3070dd17, 0xf70e5939, 0xffc00b31, 0x68581511, 0x64f98fa7, 0xbefa4fa4]);

  export function hash(s: number[]): number[] {
    return Cryptomathic.Crypto.Sha2w32Support.hash(s, initialState).slice(0, DIGEST_SIZE);
  }
}

