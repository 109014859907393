///<reference path="TLVUtils.ts"/>
///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags {
  import assertLength = Cryptomathic.Messages.Tags.Utils.assertLength;

  export const booleanTag = makeTag(encodeBoolean, asBoolean);

  export function asBoolean(data: number[]): boolean {
    assertLength(data, 1, "Boolean");
    return data[0]===1;
  }

  export function encodeBoolean(value: boolean): number[] {
    return [value?1:0];
  }

}
