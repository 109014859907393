namespace Cryptomathic.Crypto {
  export class SignatureRequestPkcs7Wrapper implements SignatureRequest {

    public get digest(): Digest {
      return this.wrappedRequest.digest;
    }

    public get hash(): number[] {
      return this.wrappedRequest.hash;
    }

    public get message(): number[] {
      return this.wrappedRequest.message;
    }

    public get paddingType() {
      return this.wrappedRequest.paddingType;
    }

    public get useSalt(): boolean {
      return this.wrappedRequest.useSalt;
    }

    private wrappedRequest: SignatureRequest;
    private includeMessageInSignature: boolean;

    private pkcs7: typeof ASN1.PKCS7; //for mocking

    public constructor(signatureRequest: SignatureRequest, signatureIncludeMessage: boolean) {
      this.wrappedRequest = signatureRequest;
      this.includeMessageInSignature = signatureIncludeMessage;

      this.pkcs7 = Cryptomathic.ASN1.PKCS7;
    }

    public getPaddedRequest(keyEntry: Cryptomathic.Messages.KeyEntry) {
      if (!keyEntry.KEY_CERT_BYTES) {
        // we do not really need a certificate for the request, but we will when we try to format the response, so we might as well fail now.
        throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "The supplied key does not have a certificate, which is required for PKCS#7 signatures");
      }
      return this.wrappedRequest.getPaddedRequest(keyEntry);
    }

    public encodeSignerResponse(rawSignature: number[], keyEntry: Cryptomathic.Messages.KeyEntry): number[] {
      const includedMessage = this.includeMessageInSignature ? this.message : null;
      switch (this.paddingType) {
        case Cryptomathic.Crypto.SignatureRequestType.PKCS1:
          return this.pkcs7.createPkcs7SignedMessage(keyEntry.KEY_CERT_BYTES,
              this.wrappedRequest.encodeSignerResponse(rawSignature, keyEntry),
                                                includedMessage,
                                                this.digest);
        case Cryptomathic.Crypto.SignatureRequestType.PSS:
          return this.pkcs7.createPssPkcs7SignedMessage(keyEntry.KEY_CERT_BYTES,
              this.wrappedRequest.encodeSignerResponse(rawSignature, keyEntry),
                                                                     includedMessage,
                                                                     this.digest,
                                                                     this.useSalt);
        default:
          throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.CryptoError, "Unsupported encoding.");
      }
    }

  }
}
