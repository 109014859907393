namespace Cryptomathic.Utils.Errors {
  /**
   * Duck-typed typeguard for standard ES5 errors.
   */
  export function isEs5Error(error: any): error is Error {
    const es5Error = error as Error;
    return es5Error.name !== undefined && es5Error.message !== undefined;
  }

}
