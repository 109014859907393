namespace Cryptomathic.Crypto.OAEP {

  export interface RandomGenerator {
    generateRandom(length: number): Uint8Array;
  }

  export class DefaultRandomGenerator implements RandomGenerator {
    public generateRandom(length: number): Uint8Array {
      const randomBytes = Cryptomathic.Crypto.Random.SecureRandom.getInstance().generate(length);
      return Cryptomathic.Utils.ByteArrayUtils.convertToByteArray(randomBytes);
    }
  }

  export let defaultRandomGenerator = new DefaultRandomGenerator();

  export function oaepEncode(modulusLen: number, message: Uint8Array, label?: Uint8Array, rand?: RandomGenerator): Uint8Array {
    if (!label) {
      label = new Uint8Array(0); //default label is the empty string
    }
    if (!rand) {
      rand = new DefaultRandomGenerator();
    }

    return _oaepEncode(modulusLen, message, label, rand);
  }

  /**
   *
   * @param L Label to be associated with the message
   * @param M Message to be encrypted
   * @param rand The random generator. A default random generator is provided in this class.
   */
  export function OAEP_ENCODE(L: Uint8Array, M: Uint8Array, rand: RandomGenerator): Uint8Array {
    const k: number = 128; // Length of the return value
    return _oaepEncode(k, M, L, rand);
  }

  function _oaepEncode(k: number, M: Uint8Array, L: Uint8Array, rand: RandomGenerator) {
    let i;
    const hLen: number = 32; // Length of SHA256 output

    // a
    if (L.length > 4294967295) {
      throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "Label too long");
    }
    const lHash = Cryptomathic.Crypto.SHA256.hash(L); // hash of L

    // b
    const mLen = M.length;
    const PSLength = k - mLen - 2 * lHash.length - 2;
    if (PSLength < 0) {
      throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError, "Message too long");
    }
    const PS = new Uint8Array(PSLength);
    for (i = 0; i < PS.length; i++) {
      PS[i] = 0;
    }

    // c
    const DB = new Uint8Array(lHash.length + PS.length + 1 + mLen);
    let j = 0;
    for (i = 0; i < lHash.length; i++) {
      DB[j++] = lHash[i];
    }
    for (i = 0; i < PS.length; i++) {
      DB[j++] = PS[i];
    }
    DB[j++] = 0x01;
    for (i = 0; i < mLen; i++) {
      DB[j++] = M[i];
    }

    // d
    const seed = rand.generateRandom(hLen);
    // e
    const dbMask = MGF1(seed, k - hLen - 1);
    // f
    const maskedDB = new Uint8Array(dbMask.length);
    for (i = 0; i < dbMask.length; i++) {
      maskedDB[i] = DB[i] ^ dbMask[i];
    }
    // g
    const seedMask = MGF1(maskedDB, hLen);

    // h
    const maskedSeed = new Uint8Array(hLen);
    for (i = 0; i < maskedSeed.length; i++) {
      maskedSeed[i] = seed[i] ^ seedMask[i];
    }

    // i
    const EM = new Uint8Array(1 + maskedSeed.length + maskedDB.length);
    j = 0;
    EM[j++] = 0x00;
    for (i = 0; i < maskedSeed.length; i++) {
      EM[j++] = maskedSeed[i];
    }

    for (i = 0; i < maskedDB.length; i++) {
      EM[j++] = maskedDB[i];
    }

    return EM;
  }

  export function MGF1(seed: Uint8Array, maskLength: number): Uint8Array {
    return maskGenerator(Cryptomathic.Crypto.SHA256, seed, maskLength);
  }

  export function maskGenerator(digest: DigestAlgorithm, seed: number[]|Uint8Array, maskLength: number): Uint8Array {
    const tbh = new Uint8Array(seed.length + 4);
    const mask = new Uint8Array(maskLength);

    for (let i = 0; i < seed.length; i++) {
      tbh[i] = seed[i];
    }

    let j = 0;
    for (let i = 0; j < maskLength; i++) {
      tbh[seed.length] = (i >> 24) & 0xff;
      tbh[seed.length + 1] = (i >> 16) & 0xff;
      tbh[seed.length + 2] = (i >> 8) & 0xff;
      tbh[seed.length + 3] = i & 0xff;

      const hash = digest.hash(tbh);

      for (let k = 0; k < digest.DIGEST_SIZE && j < maskLength; k++, j++) {
        mask[j] = hash[k];
      }

    }
    return mask;
  }
}
