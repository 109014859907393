///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto.Random {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  /* tslint:disable:max-classes-per-file ... feel free to fix :-) */

  // min pool size of P_0 before reseeding
  const MIN_POOL_SIZE = 64;

  // max pool size of any pool before hashing it down
  // this limit is not part of Fortuna but used here to limit memory usage
  const MAX_POOL_SIZE = 64*1024; // 64 KiB * 32 pools = 2 MiB

  export class Fortuna {

    private readonly P: number[][];
    private reseedCnt: number;
    private reseedTime: Date;
    private G: Generator;

    private initialized: boolean;

    public constructor() {

      this.P = [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []];
      this.reseedCnt = 0;
      this.reseedTime = new Date(0);
      this.G = new Generator();

      this.initialized = false;
    }

    private timeSinceReseedInMs(): number {
      const curTime = new Date();
      return curTime.getTime() - this.reseedTime.getTime();
    }

    private reseed(): void {
      ++this.reseedCnt;
      let s: number[] = [];
      for (let i = 0; i < 32; ++i) {
        if (this.reseedCnt % Math.pow(2, i) === 0) {
          s = s.concat(Cryptomathic.Crypto.SHA256.hash(this.P[i]));
          this.P[i] = [];
        }
      }
      this.reseedTime = new Date();
      this.G.reseed(s);
    }

    public initialize(seed: number[]): void {
      if (this.initialized) {
        throw new CrmError(ErrorTypes.CryptoError,"Already initialized");
      }
      this.G.reseed(seed);
      ++this.reseedCnt;
      this.initialized = true;
    }

    public randomData(n: number): number[] {
      if (this.P[0].length >= MIN_POOL_SIZE && this.timeSinceReseedInMs() > 100) {
        this.reseed();
      }
      if (this.reseedCnt === 0) {
        throw new CrmError(ErrorTypes.CryptoError,"PRNG not seeded yet");
      }
      return this.G.pseudoRandomData(n);
    }

      // s - source number in range 0,...,255
      // i - pool number
      // e - event data, 1 to 32 bytes
      // sources must add events to pools in a round-robin fashion
    public addRandomEvent(s: number, i: number, e: number[]): void {
      if (e.length < 1 || e.length > 32) {
        throw new CrmError(ErrorTypes.InvalidArgumentError,"Event data not in range (1 to 32 bytes)");
      }
      if (s < 0 || s > 255) {
        throw new CrmError(ErrorTypes.InvalidArgumentError,"Source number not in range (0 to 255)");
      }
      if (i < 0 || i > 31) {
        throw new CrmError(ErrorTypes.InvalidArgumentError,"Pool number not in range (0 to 31)");
      }
      this.P[i] = this.P[i].concat(s, e.length, e);
      if (this.P[i].length > MAX_POOL_SIZE) {
        this.P[i] = Cryptomathic.Crypto.SHA256.hash(this.P[i]);
      }
    }
  }

  export class SecureRandom {

    private static instance: SecureRandom = null;
    private readonly prng: Fortuna;
    private sourcePool: Cryptomathic.Crypto.Random.EntropySources.SourcePool;

    private constructor() {
      if (SecureRandom.instance != null) {
        // FUTURE: remove this check if all tests and other potential callers are converted to typescript.
        throw new CrmError(ErrorTypes.IllegalState, "Direct constructor call made on private constructor - please use getInstance() instead as this is a singleton");
      }
      SecureRandom.instance = this;

      this.prng = new Fortuna();
      this.sourcePool = Cryptomathic.Crypto.Random.EntropySources.createSourcePool(this.prng);

      this.prng.initialize(Cryptomathic.Crypto.Random.generateSeed());
      this.sourcePool.start();
    }

    public generate(numberOfBytes: number): number[] {
      this.sourcePool.collectManually();
      return this.prng.randomData(numberOfBytes);
    }

    public static getInstance(): SecureRandom {
      if (SecureRandom.instance == null) {
        SecureRandom.instance = new SecureRandom();
      }

      return SecureRandom.instance;
    }
  }

  // implementation of Fortuna
  export class Generator {

    private K = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    private readonly C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    private seeded = false;
    
    private incrementC() {
      for (let i = 0; i < 16; ++i) {
        this.C[i] = (this.C[i] + 1) % 256;
        if (this.C[i] > 0) {
          break;
        }
      }
    }

    public reseed(seed: number[]): void {
      this.seeded = true;
      this.K = Cryptomathic.Crypto.SHA256.hash(this.K.concat(seed));
      this.incrementC();
    }

    private generateBlocks(k: number): number[] {
      if (!this.seeded) {
        throw new CrmError(ErrorTypes.NotInitializedError,
            "Random number generator has not been correctly seeded");
      }
      let r: number[] = [];
      for (let i = 0; i < k; ++i) {
        r = r.concat(Cryptomathic.Crypto.AES.EncryptBlock_256(this.C, this.K));
        this.incrementC();
      }
      return r;
    }

    public pseudoRandomData(n: number): number[] {
      if (n < 0 || n > 1048576) {
        throw new CrmError(ErrorTypes.InvalidArgumentError,"Number of bytes to generate must be from 0 to 1048576");
      }
      const blocks = Math.ceil(n / 16);
      const r = this.generateBlocks(blocks).slice(0, n);
      this.K = this.generateBlocks(2);
      return r;
    }
  }
}
