///<reference path="../utils/Errors.ts"/>
///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto.Rsa {
  import CrmError = Cryptomathic.Error.CrmError;
  import SubjectPublicKeyInfo = Cryptomathic.ASN1.SubjectPublicKeyInfo;
  import BigNum = Cryptomathic.Utils.orcan.BigNum;
  import isEs5Error = Cryptomathic.Utils.Errors.isEs5Error;

  export function EncryptRsa(encodedData: Uint8Array, spki: SubjectPublicKeyInfo, resolveCallback: (result: number[])=>void, rejectCallback: (msg: string)=>void) {
    const exponent = spki.getPublicExponent();
    const modulus = spki.getModulus();

    const mod = new Cryptomathic.Utils.orcan.Modulus(Cryptomathic.Utils.StringUtils.bytesToHex(modulus));
    const exp = new Cryptomathic.Utils.orcan.BigNum(Cryptomathic.Utils.StringUtils.bytesToHex(exponent));
    const modulusLength = modulus.length;

    const message = new Cryptomathic.Utils.orcan.BigNum(Cryptomathic.Utils.StringUtils.bytesToHex(encodedData));

    const resolvePowMod = (r: BigNum) => {
      let rBytes = Cryptomathic.Utils.StringUtils.hexToBytes(r.toString().replace(/^0x/, ""));
      if (rBytes.length < modulusLength) {
        rBytes = [0x00].concat(rBytes);
      }
      resolveCallback(rBytes);
    };

    const rejectPowMod = (err: any) => {
      let msg;
      if (isEs5Error(err)) {
        msg = err.message;
      } else if (err instanceof CrmError) {
        msg = err.getMessage();
      } else {
        msg = ""+err;
      }
      rejectCallback("Error in RSA encryption: " + msg);
    };

    message.powModNAsync(exp, mod, 3, resolvePowMod, rejectPowMod);
  }
}
