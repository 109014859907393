///<reference path="Byte.ts"/>
///<reference path="Boolean.ts"/>
///<reference path="Long.ts"/>
///<reference path="Bytes.ts"/>
namespace Cryptomathic.Messages.Tags {
  export const Command = {
    sessionId: longTag( 10000),
    hsmEncryption: bytesTag(10001),
    serverEncryption: bytesTag(10002),

    createSessionProtocolId: byteTag( 10010),
    sckId: longTag( 10011),
    sckEncryption: bytesTag(10012),
    samlAssertion: bytesTag( 10013),
    activeKeysOnly: booleanTag(10014)
  };

}
