namespace Cryptomathic.Crypto.SHA384 {
    export const SIGNATURE_ID = "SHA384withRSA";
    export const DIGEST_SIZE = 48;

    export function hash(messageBytes: number[]): number[] {
      return Cryptomathic.Crypto.Sha2w64Support.hash(messageBytes, H0).slice(0, DIGEST_SIZE);
    }

    const H0 = Object.freeze([
      Int64.fromInts(0xcbbb9d5d, 0xc1059ed8),
      Int64.fromInts(0x629a292a, 0x367cd507),
      Int64.fromInts(0x9159015a, 0x3070dd17),
      Int64.fromInts(0x152fecd8, 0xf70e5939),
      Int64.fromInts(0x67332667, 0xffc00b31),
      Int64.fromInts(0x8eb44a87, 0x68581511),
      Int64.fromInts(0xdb0c2e0d, 0x64f98fa7),
      Int64.fromInts(0x47b5481d, 0xbefa4fa4)
    ]);
}
