///<reference path="Tags/Byte.ts"/>
///<reference path="Tags/Int.ts"/>
namespace Cryptomathic.Messages {

  import encodeByte = Cryptomathic.Messages.Tags.encodeByte;
  import encodeInt = Cryptomathic.Messages.Tags.encodeInt;

  import Command = Cryptomathic.Messages.Tags.Command;

  export function createRequest(magic: string, command: number[]): number[] {
    const magicBytes = Cryptomathic.Utils.StringUtils.string2bytes(magic);
    const protocolVersion = 2;

    // the message preamble do NOT follow the usual TLV format

    // 4 bytes magic, 1 byte version, 4 bytes length, payload (cf. com.cryptomathic.signer.api.user.protocol.UserProtocol)
    return magicBytes.concat(
      encodeByte(protocolVersion),
      encodeInt(command.length),
      command
    );

  }

  function createUserCommand(sessionId: number[], hsmEncryptedContent: number[], softwareEncryptedContent: number[]) {
    return Command.sessionId.encode(sessionId).concat(
      Command.hsmEncryption.encode(hsmEncryptedContent),
      Command.serverEncryption.encode(softwareEncryptedContent)
    );
  }

  export function createUserRequest(sessionId: number[], hsmEncryptedContent: number[], softwareEncryptedContent: number[]) {
    return createRequest("SAUT", createUserCommand(sessionId, hsmEncryptedContent, softwareEncryptedContent));
  }
}
