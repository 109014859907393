///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.ASN1.ASN1Util {

  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export enum TagClasses {
    UNIVERSAL = 0x00,
    APPLICATION = 0x40,
    CONTEXT_SPECIFIC = 0x80,
    PRIVATE = 0xC0
  }

  export function encodeLength(length: number): number[] {
      if (length < 0) {
        throw new CrmError(ErrorTypes.InvalidArgumentError, "Cannot encode negative length");
      }
      if (length <= 127) {
        return [length];
      }

      const encoding = [];

      while (length > 0) {
        encoding.unshift(length % 256);
        length = (length / 256) | 0;
      }

      encoding.unshift(0x80 | encoding.length);

      return encoding;
    }
    
    export function encodeTag(classType: TagClasses, tagValue: number, constructed: boolean): number[] {
      if (classType !== TagClasses.UNIVERSAL &&
          classType !== TagClasses.APPLICATION &&
          classType !== TagClasses.CONTEXT_SPECIFIC &&
          classType !== TagClasses.PRIVATE) {
        throw new CrmError(ErrorTypes.InvalidArgumentError, "Cannot encode unknown class type: " + classType);
      }

      if (tagValue < 0) {
        throw new CrmError(ErrorTypes.InvalidArgumentError, "Cannot encode negative tag: " + tagValue);
      }

      const constructedMask = constructed ? 0x20 : 0;

      if (tagValue <= 30) {
        return [classType | constructedMask | tagValue];
      }

      const encoding = [classType | constructedMask | 31];

      while (tagValue > 0) {
        encoding.unshift((tagValue % 128) | 0x80);
        tagValue = (tagValue / 128) | 0;
      }
      encoding[encoding.length - 1] ^= 0x80;

      encoding.unshift(classType | constructedMask | 0x1F);

      return encoding;
    }

    export function encodePrimitiveTag(classType: TagClasses, tagValue: number): number[] {
      return ASN1Util.encodeTag(classType, tagValue, false);
    }

    export function encodeConstructedTag(classType: TagClasses, tagValue: number): number[] {
      return ASN1Util.encodeTag(classType, tagValue, true);
    }

    export function prependExplicitTag(object: Asn1Object, classType: TagClasses, tagValue: number): Asn1Object {
      const encoding = object.encode();
      const explicitTag = ASN1Util.encodeConstructedTag(classType, tagValue)
        .concat(ASN1Util.encodeLength(encoding.length));

      return new Asn1Literal(explicitTag.concat(encoding));
    }

    export function makeTagImplicit(object: Asn1Object, classType: TagClasses, tagValue: number): Asn1Object {
      const encoding = object.encode();
      if (encoding.length === 0) {
        throw new CrmError(ErrorTypes.InvalidArgumentError, "Encoding too short for tag");
      }
      const first = encoding[0];
      let withoutTag;
      if ((first & 0x1F) === 0x1F) {
        let i = 1;
        //noinspection StatementWithEmptyBodyJS
        while ((encoding[i++] & 0x80) === 1);
        ++i;
        withoutTag = encoding.slice(i);
      } else {
        withoutTag = encoding.slice(1);
      }

      return new Asn1Literal(ASN1Util.encodeConstructedTag(classType, tagValue).concat(withoutTag));
    }
}
