///<reference path="TLV.ts"/>
///<reference path="../../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Tags {

  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;
  import TlvInfo = Cryptomathic.Messages.Tags.TLV.TlvInfo;
  import parse = Cryptomathic.Messages.Tags.TLV.parse;

  /**
   * Defines what we expect of every tag
   */
  export interface LogicalTag<T> {
    tag: number;
    get(tagMap: TagMap): T;
    getOptional(tagMap: TagMap): T;

    /**
     * Encodes the value AND prepends tag and length.
     */
    encode(value: T): number[];
  }

  type Encoder<T> = (value: T)=>number[];
  type Decoder<T> =  (bytes: number[])=>T;

  type TagFactory<T> = (tag: number) => LogicalTag<T>;

  /**
   * Creates a tag factory function from an encoder and decoder
   */
  export function makeTag<T>(encoder: Encoder<T>, decoder: Decoder<T>): TagFactory<T> {
    return (tag: number) => {
      return {
        tag,
        get: (tagMap: TagMap): T => get(tag, tagMap, decoder),
        getOptional: (tagMap: TagMap): T => getOptional(tag, tagMap, decoder),
        encode: (value: T): number[] => encodeTag(tag, encoder(value))
      };
    };
  }

  /**
   * Convenience factory for empty tag maps. The map can be filled afterwards.
   */
  export function emptyTagMap() {
    return {
      // beware that "this" below must refer back to object - the syntax is carefully chosen
      get<T>(tag: LogicalTag<T>): T { return tag.get(this); },
      getOptional<T>(tag: LogicalTag<T>): T {return tag.getOptional(this); },
    };
  }


  export interface TagMap {
    [tag: number]: number[];

    get<R>(tag: LogicalTag<R>): R;
    getOptional<R>(tag: LogicalTag<R>): R;
  }

  /**
   * Parses an input array of byte-values assuming a sequence of the following structures:
   * two-byte tag followed four-byte content length followed by content
   *
   * @param input the bytes to be parsed
   * @param startPos Optional start position, default 0
   * @param endPos Optional end position, default input.length
   * @return An object mapping tag value to tag content
   */
  export function parseTags(input: number[], startPos?: number, endPos?: number): TagMap {
    const addToTagMap = (map: TagMap, tlv: TlvInfo)=> {
      map[tlv.tag]=tlv.content;
      return map;
    };

    const tagMap: TagMap = emptyTagMap();
    return parse(input, tagMap, addToTagMap, startPos, endPos);
  }

  /**
   * Gets content for a tag from the tagmap asserting that is it present. Content may be the empty array.
   */
  export function get<T>(tag: number, tagmap: TagMap, map: (value: number[])=>T): T {
    const result = getOptional(tag, tagmap, map);
    if (result===undefined) {
      throw new CrmError(ErrorTypes.SerializationError, "Expected tag "+tag+" in response, but found none");
    }
    return result;
  }

  export function getOptional<T>(tag: number, tagmap: TagMap, map: (value: number[])=>T): T {
    const value = tagmap[tag];
    return value?map(value):undefined;
  }


  export function encodeTag(tag: number, encodedContent: number[]): number[] {
    return encodedContent?encodeShort(tag).concat(Cryptomathic.Messages.Tags.encodeInt(encodedContent.length), encodedContent):[];
  }

  export function encodeShort(value: number): number[] {
    return [(value >>> 8) & 0xFF, value & 0xFF];
  }


}
