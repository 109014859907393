///<reference path="TLVUtils.ts"/>
///<reference path="TagMap.ts"/>
namespace Cryptomathic.Messages.Tags {

  import assertLength = Cryptomathic.Messages.Tags.Utils.assertLength;

  export const byteTag = makeTag(encodeByte, asByte);

  export function encodeByte(value: number): number[] {
    return [value];
  }

  export function asByte(data: number[]): number {
    assertLength(data, 1, "Byte");

    return data[0];
  }

}
