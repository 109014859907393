///<reference path="Byte.ts"/>
///<reference path="Bytes.ts"/>
///<reference path="String.ts"/>
///<reference path="Int.ts"/>
namespace Cryptomathic.Messages.Tags {
  export const HsmCommand = {
    sequenceNumber: intTag(0x0001),
    commandId: byteTag(0x0002),
    keyHash: bytesTag( 0x0012),
    policyGuid: bytesTag(0x0013),
    signature: bytesTag(0x0020),
    signaturedata: bytesTag(0x0021),
    certificate: bytesTag(0x0030),
    certificateDisplayText: bytesTag( 0x0031),
    tokenType: byteTag(0x0200),
    tokenSerial: stringTag(0x0201),
    tokenCredentials: bytesTag(0x0203),
    policyName: bytesTag(0x0204)
  };
}
