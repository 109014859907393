///<reference path="Tags/CreateSessionResponse.ts"/>
namespace Cryptomathic.Messages.Response.CreateSessionServer {
  import CreateSessionResponse = Cryptomathic.Messages.Tags.CreateSessionResponse;
  import parseTags = Cryptomathic.Messages.Tags.parseTags;

  export interface CreateSessionServerResponse {
    tokens: AuthenticationToken[];
    policies: PolicyEntry[];
  }

  export function parse(response: number[]): CreateSessionServerResponse {
    const tags = parseTags(response);

    const tokens = tags.get(CreateSessionResponse.tokenList);
    const policies = tags.get(CreateSessionResponse.policyList);

    return {tokens, policies};
  }

}
