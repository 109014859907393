namespace Cryptomathic.ASN1 {

  import Asn1Object = Cryptomathic.ASN1.Asn1Object;

  export class OctetString implements Asn1Object {

    private readonly bytes: number[];

    public constructor(bytes: number[]) {
      this.bytes = bytes;
    }

    public encode(): number[] {
      return ASN1Util.encodePrimitiveTag(ASN1Util.TagClasses.UNIVERSAL, 0x04)
                 .concat(ASN1Util.encodeLength(this.bytes.length).concat(this.bytes));
    }
  }

}
