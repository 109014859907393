///<reference path="../utils/ByteUtils.ts"/>
///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto.Pkcs1EmsaPadding {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  import emptyArray = Cryptomathic.Utils.ByteArrayUtils.emptyArray;

  export function EMSA_PKCS1_encode(hash: number[], encodedLength: number, digest: Digest): number[] {
    const T = Cryptomathic.Crypto.CryptoUtils.asn1DigestInfo(digest).concat(hash);
    if (encodedLength < T.length + 11) {
      throw new CrmError(ErrorTypes.CryptoError, "Intended encoded message length too short.");
    }

    const PS = emptyArray(encodedLength - T.length - 3, 0xff);
    return [0x00, 0x01].concat(PS).concat(0x00).concat(T);
  }

}
