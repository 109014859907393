///<reference path="./TLV.ts"/>
///<reference path="TLVUtils.ts"/>
///<reference path="TagMap.ts"/>
///<reference path="../../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Tags {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;
  import assertLength = Cryptomathic.Messages.Tags.Utils.assertLength;

  export const longTag = makeTag(encodeLong, asLong);

  export function asLong(data: number[]): number[] {
    assertLength(data, 8, "Long");

    return data;
  }

  export function encodeLong(value: number[]) {
    if (value.length !== 8) {
      throw new CrmError(ErrorTypes.SerializationError, "expected 8 bytes of value for a long, but got "+value.length);
    }
    return value;
  }

}
