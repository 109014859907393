///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Utils.Base64 {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

    export function encode(bytes: number[]|Uint8Array): string {
      const str = Cryptomathic.Utils.StringUtils.bytes2string(bytes);

      const strLen = str.length;
      const endBlockLen = strLen % 3;
      const normalBlocksLen = strLen - endBlockLen;

      let res = "";

      let i = 0;
      for (; i < normalBlocksLen; i += 3) {
        const block = (str.charCodeAt(i) << 16) + (str.charCodeAt(i + 1) << 8) + str.charCodeAt(i + 2);
        res += encodeBlock(block);
      }

      if (endBlockLen === 1) {
        const enc1 = encodeBlock(str.charCodeAt(i) << 16);
        res += enc1.slice(0, 2) + "==";
      }
      if (endBlockLen === 2) {
        const enc2 = encodeBlock((str.charCodeAt(i) << 16) + (str.charCodeAt(i + 1) << 8));
        res += enc2.slice(0, 3) + "=";
      }

      return res;
    }

    export function decode(str: string): number[] {
      if (str.length % 4 !== 0) {
        throw new CrmError(ErrorTypes.InvalidArgumentError,
          "Invalid base64 encoding, length not divisible by 4");
      }

      const bytes = [];
      for (let i = 0; i < str.length; i += 4) {
        const index0 = digits.indexOf(str[i]);
        const index1 = digits.indexOf(str[i + 1]);
        const index2 = digits.indexOf(str[i + 2]);
        const index3 = digits.indexOf(str[i + 3]);

        bytes.push((index0 << 2) | (index1 >> 4));
        if (index2 < 64) {
          bytes.push(((index1 & 15) << 4) | (index2 >> 2));
        }
        if (index3 < 64) {
          if (index1 === 64) {
            throw new CrmError(ErrorTypes.InvalidArgumentError, "Invalid base64 padding");
          }
          bytes.push(((index2 & 3) << 6) | index3);
        }
      }

      return bytes;
    }

    const digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    function encodeBlock(block: number): string {
      const d1 = block >> 18;
      const d2 = (block >> 12) & 63;
      const d3 = (block >> 6) & 63;
      const d4 = block & 63;
      return digits.charAt(d1) + digits.charAt(d2) + digits.charAt(d3) + digits.charAt(d4);
    }

}
