namespace Cryptomathic.ASN1 {
  class RsaSsaPssAlgorithmParameters implements Asn1Object {

    private readonly asn1HashAlgorithm: Cryptomathic.ASN1.AlgorithmIdentifier;
    private readonly asn1Mgf: Cryptomathic.ASN1.AlgorithmIdentifier;
    private readonly asn1SaltLength: Cryptomathic.ASN1.Asn1Integer;

    private static readonly DEFAULT_HASH_ALGORITHM: AlgorithmIdentifier = new Cryptomathic.ASN1.AlgorithmIdentifier(Constants.sha1Oid);
    private static readonly DEFAULT_ASN1_MGF1: Cryptomathic.ASN1.AlgorithmIdentifier = new Cryptomathic.ASN1.AlgorithmIdentifier(Constants.mgf1Oid, RsaSsaPssAlgorithmParameters.DEFAULT_HASH_ALGORITHM);
    private static readonly DEFAULT_SALT_LENGTH: Cryptomathic.ASN1.Asn1Integer = new Cryptomathic.ASN1.Asn1Integer(20);

    public constructor(asn1HashAlgorithm: Cryptomathic.ASN1.AlgorithmIdentifier, asn1Mgf: Cryptomathic.ASN1.AlgorithmIdentifier, asn1SaltLength: Cryptomathic.ASN1.Asn1Integer) {
      this.asn1HashAlgorithm = asn1HashAlgorithm;
      this.asn1Mgf = asn1Mgf;
      this.asn1SaltLength = asn1SaltLength;
    }

    public encode(): number[] {
      let sequence = new Cryptomathic.ASN1.IndexedSequence();

      if (!RsaSsaPssAlgorithmParameters.equals(this.asn1HashAlgorithm, RsaSsaPssAlgorithmParameters.DEFAULT_HASH_ALGORITHM)) {
        sequence = sequence.push(this.asn1HashAlgorithm, 0);
      }

      if (!RsaSsaPssAlgorithmParameters.equals(this.asn1Mgf, RsaSsaPssAlgorithmParameters.DEFAULT_ASN1_MGF1)) {
        sequence = sequence.push(this.asn1Mgf, 1);
      }

      if (!RsaSsaPssAlgorithmParameters.equals(this.asn1SaltLength, RsaSsaPssAlgorithmParameters.DEFAULT_SALT_LENGTH)) {
        sequence = sequence.push(this.asn1SaltLength, 2);
      }

      return sequence.wrapContentInExplicitTags().encode();
    }

    private static equals<T extends Asn1Object>(asn1Object: T, otherAsn1Object: T) {
      return Cryptomathic.Utils.StringUtils.bytesToHex(asn1Object.encode()) === Cryptomathic.Utils.StringUtils.bytesToHex(otherAsn1Object.encode());
    }
  }

  /**
   * Encodes a PSS parameter section from a algorithmId and a salt length.
   */
  export function encodePssAlgorithmId(signatureId: string, saltLength: number): AlgorithmIdentifier {
    return new AlgorithmIdentifier(
        Constants.rsaSsaPssOid,
        encodePssParameters(signatureId, saltLength)
    );
  }

  /**
   * Encodes a PSS parameter section from a algorithmId and a salt length.
   */
  export function encodePssParameters(signatureId: string, saltLength: number): Asn1Object {
    const asn1HashAlgorithm = createDigestAlgorithmIdentifier(signatureId);
    const asn1Mgf = new AlgorithmIdentifier(Constants.mgf1Oid, asn1HashAlgorithm);
    const asn1SaltLength = new Cryptomathic.ASN1.Asn1Integer(saltLength);

    return new RsaSsaPssAlgorithmParameters(asn1HashAlgorithm, asn1Mgf, asn1SaltLength);
  }
}
