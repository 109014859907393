namespace Cryptomathic.ASN1 {
  import Asn1Object = Cryptomathic.ASN1.Asn1Object;

  export class Sequence implements Asn1Object {

    private content: Asn1Object[];

    public constructor(content: Asn1Object[] = []) {
      this.content = content;
    }

    public push(object: Asn1Object): Sequence {
      this.content.push(object);
      return this;
    }

    public encode() {

      const encodedContent = this.content.reduce((result, elem) => result.concat(elem.encode()), []);

      return Cryptomathic.ASN1.ASN1Util.encodeConstructedTag(ASN1Util.TagClasses.UNIVERSAL, 0x10)
        .concat(Cryptomathic.ASN1.ASN1Util.encodeLength(encodedContent.length)
        .concat(encodedContent));
    }
  }
}
