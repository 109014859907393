///<reference path="Tags/Response.ts"/>
namespace Cryptomathic.Messages.Response.CreateSession {

  import Response = Cryptomathic.Messages.Tags.Response;

  export interface CreateSessionResponse {
    softwareEncryptedContent: number[];
    encryptedContent: number[];
    sessionID: number[];
    authenticatorEnabled: boolean;
  }

  /**
   * Parses the given response bytes.
   **/
  export function parse(response: number[]): CreateSessionResponse {
    const tags = parseSignerResponse(response, "RSCP", "Create session");

    return {
      authenticatorEnabled: tags.get(Response.authenticatorEnabled),
      encryptedContent: tags.get(Response.hsmEncryption),
      sessionID: tags.get(Response.sessionId),
      softwareEncryptedContent: tags.get(Response.serverEncryption)
    };

  }
}
