namespace Cryptomathic.Crypto {
  export class SignatureRequestPkcs1 implements SignatureRequest {

    public readonly paddingType = Cryptomathic.Crypto.SignatureRequestType.PKCS1;
    public readonly useSalt = false;

    public readonly digest: Digest;
    public readonly hash: number[];
    public readonly message: number[];

    public constructor(digest: Digest, message: number[], hash: number[]) {
      this.digest = digest;
      this.hash = hash;
      this.message = message;

      const digestAlgorithm = Cryptomathic.Crypto.Digest.toAlgorithm(digest);

      if(!this.hash && !this.message) {
        throw new Cryptomathic.Error.CrmError(Cryptomathic.SignerUserSDK.ErrorTypes.CryptoError, "Missing hash or message in PKCS1 SignatureRequest.");
      }

      if(!this.hash) {
        this.hash = digestAlgorithm.hash(this.message);
      }
    }

    public getPaddedRequest(keyEntry: Cryptomathic.Messages.KeyEntry): number[] {
      const spki = Cryptomathic.ASN1.SubjectPublicKeyInfo.createFromEncoded(keyEntry.KEY_PUBLIC_KEY);
      const keySize = spki.getModulus().length;
      return Cryptomathic.Crypto.Pkcs1EmsaPadding.EMSA_PKCS1_encode(this.hash, keySize, this.digest);
    }

    public encodeSignerResponse(rawSignature: number[], keyEntry: Cryptomathic.Messages.KeyEntry): number[] {
      return rawSignature;
    }

  }
}
