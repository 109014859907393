///<reference path="../../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Tags.Utils {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export function assertLength(data: number[], len: number, type: string): void {
    if (data.length !== len) {
      throw new CrmError(ErrorTypes.InvalidArgumentError, type+" data should be "+len+" bytes long, but was "+data.length+ " bytes long");
    }
  }
}
