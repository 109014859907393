namespace Cryptomathic.Crypto {
  //internally in JavaScript Number is represented as a double-precision 64-bit binary format IEEE 754 value and
  //bitwise operations by truncation to int32. This is sufficient for doing 32-bit bitwise logical operations, but is
  //insufficient for 64-bit bitwise operations.
  export type int64 = [number, number];
}

namespace Cryptomathic.Crypto.Int64 {
  // note on the ..._high() and ..._low() functions:
  // when doing a chain of operations it is a performance loss to construct int64 (aka. [number,number]) object for the
  // intermediate results. It is much more efficient to have high-32bit lane and a low-32bit lane that is combined into
  // an int64 at the latest possible moment.

  function normalize(value: number): number {
    return value >>> 0; //fast uint32
  }

  function int32ToHex(intValue: number): string {
    const res = Number(normalize(intValue)).toString(16);
    return "00000000".substr(0, 8-res.length)+res;
  }

  function int32ToBytes(intValue: number): number[] {
    const value = normalize(intValue);
    return [(value >>> 24) & 0xff, (value >>> 16) & 0xff, (value >>> 8)& 0xff, value & 0xff];
  }

  function int32fromBytes(bytes: number[]|Uint8Array): number {
    return normalize((bytes[0] << 24) + (bytes[1] << 16) + (bytes[2] << 8) + bytes[3]);
  }

  function reduceShiftOperand(intValue: number): number {
    // Reduce to 6 bits (0-63) as defined by shift standards
    return intValue & 0x3F;
  }

  export function fromBytes(bytes: number[]|Uint8Array): int64 {
    return [int32fromBytes(bytes.slice(0,4)), int32fromBytes(bytes.slice(4,8))];
  }

  export function toBytes(x: int64): number[] {
    return int32ToBytes(x[0]).concat(int32ToBytes(x[1]));
  }

  export function fromInts(most: number, least: number): int64 {
    return [most, least];
  }

  export function fromNumber(value: number): int64 {
    return [Math.floor(value / 0x100000000), value % 0x100000000];
  }

  export function xor(x: int64, y: int64): int64 {
    return [x[0]^y[0], x[1]^y[1]];
  }

  export function xors(...values: int64[]): int64 {
    let least = values[0][1];
    let most = values[0][0];

    for (let i=1; i<values.length; i++) {
      least ^= values[i][1];
      most ^= values[i][0];
    }
    return [most, least];
  }

  export function and(x: int64, y: int64): int64 {
    return [x[0]&y[0], x[1]&y[1]];
  }

  export function or(x: int64, y: int64): int64 {
    return [x[0]|y[0], x[1]|y[1]];
  }

  export function not(x: int64): int64 {
    return [~x[0], ~x[1]];
  }

  export function add(x: int64, y: int64): int64 {
    const least = normalize(x[1]) + normalize(y[1]);
    let most = normalize(x[0]) + normalize(y[0]);
    most += Math.floor(least/0x100000000); //carry
    return [most,least];
  }

  export function sum(...values: int64[]): int64 {
    let least = normalize(values[0][1]);
    let most = normalize(values[0][0]);

    for (let i=1; i<values.length; i++) {
      most += normalize((values[i][0]));
      least += normalize(values[i][1]);
    }
    most += Math.floor(least/0x100000000); //carry
    return [most, least];
  }

  export function shr(x: int64, n: number): int64 {
    n = reduceShiftOperand(n);
    if (n === 0) {
      return x;
    } else if (n<32) {
      const carry = x[0] << (32 - n);
      return [x[0] >>> n, carry | (x[1] >>> n)];
    } else { // 32 <= n < 64
      return [0, x[0] >>> (n-32)];
    }
  }

  export function shr_low(x: int64, n: number): number {
    n = reduceShiftOperand(n);
    if (n === 0) {
      return x[1];
    } else if (n<32) {
      return x[0] << (32 - n) | (x[1] >>> n);
    } else { // 32 <= n < 64
      return x[0] >>> (n-32);
    }
  }

  export function shr_high(x: int64, n: number): number {
    n = reduceShiftOperand(n);
    if (n === 0) {
      return x[0];
    } else if (n<32) {
      return x[0] >>> n;
    } else { // 32 <= n < 64
      return 0;
    }
  }

  export function shl(x: int64, n: number): int64 {
    n = reduceShiftOperand(n);
    if (n === 0) {
      return x;
    } else if (n<32) {
      const carry = x[1] >>> (32 - n);
      return [(x[0] << n) | carry, x[1] << n];
    } else { // 32 <= n < 64
      return [x[1] << (n-32), 0];
    }
  }

  export function rotr(x: int64, n: number): int64 {
    n = reduceShiftOperand(n);
    if (n === 0) {
      return x;
    } else {
      return Int64.or(Int64.shr(x, n), Int64.shl(x, 64-n));
    }
  }

  export function rotr_low(x: int64, n: number): number {
    n = reduceShiftOperand(n);
    if (n===0) {
      return x[1];
    } else if (n < 32) {
      return (x[1] >>> n) ^ (x[0] << (32-n));
    } else { // 32 <= n < 64
      return Int64.rotr_high(x, n-32);
    }
  }

  export function rotr_high(x: int64, n: number): number {
    n = reduceShiftOperand(n);
    if (n===0) {
      return x[0];
    } else if(n < 32) {
      return (x[0] >>> n) ^ (x[1] << (32-n));
    } else { // 32 <= n < 64
      return Int64.rotr_low(x, n-32);
    }
  }

  export function rotl(x: int64, n: number): int64 {
    n = reduceShiftOperand(n);
    if (n === 0) {
      return x;
    } else {
      return Int64.or(Int64.shl(x, n), Int64.shr(x, 64-n));
    }
  }

  export function toHex(x: int64): string {
    return int32ToHex(x[0]) + int32ToHex(x[1]);
  }

  export function convertInt64ArrayToByteArray(int64Array: int64[]): number[] {
    return int64Array.reduce((result, i) => result.concat(toBytes(i)), []);
  }
}
