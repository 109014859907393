///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Crypto {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class MessageBlockStream {

    private readonly blocksizeInBytes=128;
    private readonly int64SizeInBytes=8;
    private readonly blockLengthInInt64s = this.blocksizeInBytes / this.int64SizeInBytes;
    private readonly lengthFieldSizeBytes=16;

    private readonly messageBytes: number[]|Uint8Array; //message can potentially be quite large, so do not convert en block (it is OK to nibble chunks off, though)

    private readonly fullBlocks: number;
    private readonly tail: number[];
    private readonly tailBlocks: number;

    private currentBlock: number;

    public constructor(messageBytes: number[]|Uint8Array) {
      //while the algorithm is defined on a stream of bits, this assumes that we work in bytes.

      this.messageBytes = messageBytes; //somehow it is possible to call this with an Uint8Array
      this.blockLengthInInt64s = this.blocksizeInBytes / this.int64SizeInBytes;
      this.currentBlock = 0;

      this.fullBlocks = Math.floor(messageBytes.length / this.blocksizeInBytes);
      this.tail = this.makeTail();
      this.tailBlocks = this.tail.length / this.blocksizeInBytes;
    }

    public makeTail(): number[] {
      const tail = this.messageBytes.slice(this.fullBlocks * this.blocksizeInBytes);
      let paddingLength = this.blocksizeInBytes - tail.length - this.lengthFieldSizeBytes - 1;
      if (paddingLength < 0) {
        paddingLength += this.blocksizeInBytes;
      }

      //message length in bits allows for 128 bits of precision, but in JavaScript 64 bits of precision is more than enough
      //array length can be specification (JS5) not exceed a 32-bit number, which means 40 bits of precision is fine if elements are bytes.
      const messageLengthInBits = Int64.shl(Int64.fromNumber(this.messageBytes.length), 3);
      const byteEncodedMessageBitLength = Cryptomathic.Utils.ByteArrayUtils.emptyArray(8).concat(Int64.toBytes(messageLengthInBits));
      return Cryptomathic.Utils.ByteArrayUtils.convertToNumberArray(tail).concat(0x80).concat(Cryptomathic.Utils.ByteArrayUtils.emptyArray(paddingLength), byteEncodedMessageBitLength);
    }

    public hasNext(): boolean {
      return this.currentBlock < this.fullBlocks + this.tailBlocks;
    }

    public makeBlock(bytesBlock: number[]|Uint8Array): int64[] {
      let offset = 0;
      const block = new Array(this.blockLengthInInt64s);
      for (let i = 0; i < this.blockLengthInInt64s; i++) {
        const endOffset = offset + this.int64SizeInBytes;
        block[i] = Int64.fromBytes(bytesBlock.slice(offset, endOffset));
        offset = endOffset;
      }

      return block;
    }

    public getNext(): int64[] {
      let offset;
      if (this.currentBlock < this.fullBlocks) {
        offset = this.currentBlock * this.blocksizeInBytes;
        this.currentBlock++;
        return this.makeBlock(this.messageBytes.slice(offset, offset + this.blocksizeInBytes));
      } else if (this.currentBlock - this.fullBlocks < this.tailBlocks) {
        offset = (this.currentBlock - this.fullBlocks) * this.blocksizeInBytes;
        this.currentBlock++;
        return this.makeBlock(this.tail.slice(offset, offset + this.blocksizeInBytes));
      } else {
        throw new CrmError(ErrorTypes.IllegalState,"Trying to read more input to Hashfunction than is available");
      }
    }
  }

}
