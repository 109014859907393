namespace Cryptomathic.Crypto.SHA1 {
    export const SIGNATURE_ID = "SHA1withRSA";
    export const DIGEST_SIZE = 20;

    function tohex(i: number): string {
      let h = "";
      for(let s = 28;; s-=4) {
        h+=(i>>>s&0xf).toString(16);
        if(!s) {
          return h;
        }
      }
    }

    function rotl(n: number,s: number): number {
      return n<<s|n>>>32-s;
    }

    /* @deprecated */
    export function hash(msgBytes: number[]): number[] {

      let msg = Cryptomathic.Utils.StringUtils.bytes2string(msgBytes);

      let [H0, H1, H2, H3, H4] = [0x67452301, 0xEFCDAB89, 0x98BADCFE, 0x10325476, 0xC3D2E1F0];
      const M = 0x0ffffffff;
      let i;
      let t; 
      const W=new Array(80);
      const ml=msg.length;
      const wa=[];
      msg += String.fromCharCode(0x80);

      while(msg.length%4) msg+=String.fromCharCode(0);

      for(i=0;i<msg.length;i+=4) {
        wa.push(msg.charCodeAt(i)<<24|msg.charCodeAt(i+1)<<16|msg.charCodeAt(i+2)<<8|msg.charCodeAt(i+3));
      }

      while(wa.length%16!==14) {
        wa.push(0);
      }

      wa.push(ml>>>29);
      wa.push((ml<<3)&M);

      for(let bo=0; bo<wa.length; bo+=16 ) {
        for(i=0;i<16;i++) W[i]=wa[bo+i];
        for(i=16;i<=79;i++) W[i]=rotl(W[i-3]^W[i-8]^W[i-14]^W[i-16],1);
        let [A, B, C, D, E] = [H0, H1, H2, H3, H4];

        for(i=0 ;i<=19;i++) {
          t=(rotl(A,5)+(B&C|~B&D)+E+W[i]+0x5A827999)&M;
          E=D;
          D=C;
          C=rotl(B,30);
          B=A;
          A=t;
        }

        for(i=20;i<=39;i++) {
          t=(rotl(A,5)+(B^C^D)+E+W[i]+0x6ED9EBA1)&M;
          E=D;
          D=C;C=rotl(B,30);
          B=A;
          A=t;
        }

        for(i=40;i<=59;i++) {
          t=(rotl(A,5)+(B&C|B&D|C&D)+E+W[i]+0x8F1BBCDC)&M;
          E=D;
          D=C;
          C=rotl(B,30);
          B=A;A=t;
        }

        for(i=60;i<=79;i++) {
          t=(rotl(A,5)+(B^C^D)+E+W[i]+0xCA62C1D6)&M;
          E=D;
          D=C;
          C=rotl(B,30);
          B=A;
          A=t;
        }

        H0=H0+A&M;
        H1=H1+B&M;
        H2=H2+C&M;
        H3=H3+D&M;
        H4=H4+E&M;
      }
      return Cryptomathic.Utils.StringUtils.hexToBytes(tohex(H0)+tohex(H1)+tohex(H2)+tohex(H3)+tohex(H4));
    }
}
