namespace Cryptomathic.Messages {
  /**
   * The required authentication level of the policy. Returned by the {{#crossLink "Cryptomathic.SignerUserSDK.SDK/createSession:method"}}createSession{{/crossLink}} method of the SDK object as part of the {{#crossLink
   * "Cryptomathic.Messages.PolicyEntry"}}{{/crossLink}} object
   *
   * @namespace Cryptomathic.Messages
   * @class PolicyAuthenticationLevel
   */

  export enum PolicyAuthenticationLevel {
    /**
     * NA
     *
     * **Deprecated** - No longer in use
     *
     * @property NA
     * @type PolicyAuthenticationLevel
     * @static
     * @deprecated
     */
    NA = 0,

    /**
     *  ANONYMOUS
     *
     * **Deprecated** - No longer in use
     *
     * @property ANONYMOUS
     * @type PolicyAuthenticationLevel
     * @static
     * @deprecated
     */
    ANONYMOUS = 1,

    /**
     * ONE_FACTOR - The authentication level for the policy is One Factor
     *
     * @property ONE_FACTOR
     * @type PolicyAuthenticationLevel
     * @static
     */
    ONE_FACTOR = 2,

    /**
     * TWO_FACTOR - The authentication level for the policy is Two Factor
     *
     * @property TWO_FACTOR
     * @type PolicyAuthenticationLevel
     * @static
     */
    TWO_FACTOR = 3,

    /**
     * DISABLED - The policy is disabled
     *
     * (this value is not in use for key policies)
     *
     * @property DISABLED
     * @type PolicyAuthenticationLevel
     * @static
     */
    DISABLED = 127
  }
}
