///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Utils.orcan {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  /* tslint:disable:max-classes-per-file ... feel free to fix :-) */

  const LOG_RADIX = 24;            // bits per digit
  const RADIX = 1 << 24;           // the radix of this representation
  const DIG_MASK = (1 << 24) - 1;  // for masking off results

  function parseHexString(it: string): number[] {
    it = it.replace(/^0x/, "");
    it = it.replace(/^(00)+/, ""); // remove leading zero bytes

    const result = [];

    const k = LOG_RADIX / 4;
    // Optimization 1: use local variable instead of object access per iteration
    const itLen = it.length;
    for (let i = 0; i < itLen; i += k) {
      result.push(parseInt(it.substring(Math.max(itLen - i - k, 0), itLen - i), 16));
    }

    return result;
  }

  export class BigNum {

      private digs = [0];              // the digits, least significant first

      public constructor(it: number|string|BigNum) {
        if (typeof it === "object") {
          this.digs = it.digs.slice(0);
        } else if (typeof it === "number") {
          this.digs = [it];
        } else if (typeof it === "string") {
          this.digs = parseHexString(it);
        } else {
          this.digs = [0];
        }
      }

        public copy(): BigNum {
          return new BigNum(this);
        }

        public normalize(): BigNum {
          while (this.digs.length > 1 && this.digs[this.digs.length - 1] === 0) {
            this.digs.pop();
          }
          return this;
        }

        /**
         * return digit i (0 is the least significant digit)
         */
        public getDig(i: number): number {
          return (i >= this.digs.length ? 0 : this.digs[i]);
        }

        public numDigs(): number {
          return this.digs.length;
        }

        /**
         * @returns this as a hex string
         */
        public toString(): string {
          let out = ""; 
          let i;
          let s;
          const l = this.digs;
          for (i=0; i < l.length; i++) {
            s = l[i].toString(16);
            // zero-extend to 6 hex digits (24 bits) per "digit"
            while (i < l.length - 1 && s.length < 6) {
              s = "0" + s;
            }
            out = s + out;
          }
          // add an extra zero if you have an odd number of nibbles
          if (out.length % 2 !== 0) {
            out = "0" + out;
          }

          return "0x"+out;
        }

        /**
         * @returns comma separated string of digits in decimal, for debugging
         */
        public dump(): string {
          return this.digs.join(",");
        }

        /**
         * @returns this == that
         */
        public equals(that: number|string|BigNum): boolean {
          if (typeof that !== "object") { that = new BigNum(that); }
          let difference = 0;
          let i;
          const thisDigsLen = this.digs.length;
          const thatDigsLen = that.digs.length;
          for (i = 0; i < thisDigsLen || i < thatDigsLen; i++) {
            difference |= this.getDig(i) ^ that.getDig(i);
          }
          return (difference === 0);
        }

        /**
         * @returns this + that
         */
        public add(that: number|string|BigNum): BigNum {
          if (typeof that !== "object") { that = new BigNum(that); }
          const result = new BigNum(0);
          const resDigs = result.digs;
          const thisDigs = this.digs;
          const thatDigs = that.digs;
          const thisDigsLen = this.digs.length;
          const thatDigsLen = that.digs.length;
          const min = Math.min(thisDigsLen, thatDigsLen);
          const max = Math.max(thisDigsLen, thatDigsLen);

          // Optimization 2: These loops have been made more explicit -- we could call getDig
          //                 and simplify, but this has a significant performance impact in IE8.
          const maxDigs = thisDigsLen === min ? thatDigs : thisDigs;
          let c = 0;
          let n;
          let i;
          for (i = 0; i < min; ++i) {
            n = thisDigs[i] + thatDigs[i] + c;
            resDigs[i] = n & DIG_MASK;
            c = n >= RADIX ? 1 : 0;
          }
          for (; i < max; ++i) {
            n = maxDigs[i] + c;
            resDigs[i] = n & DIG_MASK;
            c = n >= RADIX ? 1 : 0;
          }

          if (c > 0) resDigs.push(c);
          return result.normalize();
        }

        public sub(that: number|string|BigNum): BigNum {
          if (typeof that !== "object") { that = new BigNum(that); }
          const result = new BigNum(0);
          const resDigs = result.digs;
          const thisDigs = this.digs;
          const thatDigs = that.digs;
          const thisDigsLen = this.digs.length;
          const thatDigsLen = that.digs.length;

          if (thisDigsLen < thatDigsLen) {
            for (let k = thisDigsLen; k < thatDigsLen; ++k) {
              thisDigs[k] = 0;
            }
          }

          let c = 0;
          let n;
          let i;
          for (i = 0; i < thatDigsLen; ++i) {
            n = thisDigs[i] - thatDigs[i] - c;
            if (n >= 0) {
              resDigs[i] = n;
              c = 0;
            } else {
              resDigs[i] = n + RADIX;
              c = 1;
            }
          }
          for (; i < thisDigsLen; ++i) {
            n = thisDigs[i] - c;
            if (n >= 0) {
              resDigs[i] = n;
              c = 0;
            } else {
              resDigs[i] = n + RADIX;
              c = 1;
            }
          }

          if (c > 0) {
            throw new CrmError(ErrorTypes.BigNumError, "negative number");
          }
          return result.normalize();
        }

        /**
         * @returns if (this - that < 0) then -1 else if (this==that) 0 else 1
         */
        public compare(that: number|string|BigNum): number {
          if (typeof that !== "object") { that = new BigNum(that); }
          let n;
          const xcopy = this.copy().normalize();
          const ycopy = that.copy().normalize();
          const x = xcopy.digs;
          const y = ycopy.digs;
          if (x.length < y.length) return -1;
          if (x.length > y.length) return 1;

          const xLen = x.length;

          for (let i = xLen-1; i >= 0; --i) {
            n = x[i] - y[i];
            if (n > 0) {
                return 1;
            } else if (n < 0) {
                return -1;
            }
          }
          return 0;
        }

        /**
         * @returns this << n
         */
        public shiftLeft(n: number): BigNum {
          // Optimization 3: a/b|0 is quicker than Math.floor(a/b) and works for 32-bit integers
          //                 but result is incorrect if a or b is outside this range
          const ndigs = (n / LOG_RADIX | 0);
          const nbits = n % LOG_RADIX;
          const res = new BigNum(0);
          const resDigs = res.digs;
          let i;
          for (i = 0; i < ndigs; ++i) resDigs.push(0);
          let c = 0;
          let j = ndigs;
          const thisDigs = this.digs;
          const thisDigsLen = thisDigs.length;
          for (i = 0; i < thisDigsLen; ++i, j++) {
              resDigs[j] = ((thisDigs[i] << nbits) | c) & DIG_MASK;
              c = thisDigs[i] >> (LOG_RADIX - nbits);
          }
          if (c > 0) res.digs.push(c);
          return res;
        }

        /**
         * @returns this >> n
         */
        public shiftRight(n: number): BigNum {
          const ndigs = (n / LOG_RADIX | 0);
          const nbits = n % LOG_RADIX;
          const res = new BigNum(0);
          res.digs = [];
          let c = 0;
          const lowBitMask = (1 << nbits) - 1;
          const thisDigs = this.digs;
          const thisDigsLen = thisDigs.length;
          for (let i = thisDigsLen-1; i >= ndigs; --i) {
              res.digs.unshift(c | (thisDigs[i] >> nbits));
              c = (thisDigs[i] & lowBitMask) << (LOG_RADIX - nbits);
          }
          return res;
        }

        /**
         * @returns this * radix^n
         */
        public radixMul(n: number): BigNum {
          const res = new BigNum(0);
          res.digs = [];
          const resDigs = res.digs;
          for (let i = 0; i < n; ++i) {
              resDigs.push(0);
          }
          res.digs = res.digs.concat(this.digs);
          return res.normalize();
        }

        /**
         * @returns this DIV radix^n
         */
        public radixDiv(n: number): BigNum {
          const res = new BigNum(0);
          if (n > this.digs.length) return res;
          res.digs = this.digs.slice(n);
          return res.normalize();
        }

        /**
         * @returns this MOD radix^n, the n least significant digits
         */
        public radixMod(n: number): BigNum {
          const res = new BigNum(0);
          if (n > this.digs.length) return this.normalize();
          res.digs = this.digs.slice(0,n);
          return res.normalize();
        }

        /**
         * @returns this * that, knowing that this.numDigs() == 1
         */
        private digitMul(that: BigNum): BigNum {
          const n = that.numDigs();
          const u = this.digs[0];
          const res = new BigNum(0);
          if (u === 0) return res;
          const thatDigs = that.digs;
          const resDigs = res.digs;
          let c = 0;
          let uv;
          for (let i = 0; i < n; ++i) {
              uv = u * thatDigs[i] + c;
              resDigs[i] = uv & DIG_MASK;
              c = (uv / RADIX | 0);
          }
          if (c > 0) res.digs.push(c);
          return res.normalize();
        }

        /**
         * See http://en.wikipedia.org/wiki/Karatsuba_algorithm
         * @returns this * that, knowing they both have more than 1 digit
         */
        private karatsubaMul(that: BigNum): BigNum {
          const n = Math.min(this.numDigs(), that.numDigs());
          const m = (n / 2 | 0);
          const x1 = this.radixDiv(m);  // the m high digits
          const x0 = this.radixMod(m);  // the m low digits
          const y1 = that.radixDiv(m);
          const y0 = that.radixMod(m);
          const z2 = x1.mul(y1);
          const z0 = x0.mul(y0);
          const sx = x1.add(x0);
          const sy = y1.add(y0);
          const sxsy = sx.mul(sy);
          const z1 = sxsy.sub(z2).sub(z0);
          return z0.add(z1.radixMul(m)).add(z2.radixMul(m+m)).normalize();
        }

        /**
         * Schoolbook multiplication
         */
        private schoolMul(that: BigNum): BigNum {
          const res = new BigNum(0);
          const thisDigs = this.digs;
          const thatDigs = that.digs;
          const n = thisDigs.length;
          const t = thatDigs.length;
          let tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          while (tmp.length < n+t+1) {
            tmp = tmp.concat(tmp);
          }

          let c;
          let uv;
          let k;
          for (let i = 0; i < t; ++i) {
            c = 0;
            k = i;
            for (let j = 0; j < n; ++j, ++k) {
              uv = tmp[k] + (thisDigs[j] * thatDigs[i]) + c;
              tmp[k] = uv & DIG_MASK;
              c = (uv / RADIX | 0);
            }
            tmp[i+n] = c;
          }
          res.digs = tmp;
          return res.normalize();
        }

        /**
         * @returns this * that
         */
        public mul(that: number|string|BigNum) {
            if (typeof that !== "object") { that = new BigNum(that); }

            if (this.numDigs() < 2) {
                return this.digitMul(that);
            } else if (that.numDigs() < 2) {
                return that.digitMul(this);
            } else if (this.numDigs() > 50 && that.numDigs() > 50) {
                return this.karatsubaMul(that);
            } else {
                return this.schoolMul(that);
            }
        }

        /**
         * Karatsuba squaring
         */
        private recSquare(): BigNum {
          const n = this.numDigs();
          const m = (n / 2 | 0);
          const a1 = this.radixDiv(m);
          const a0 = this.radixMod(m);
          const a12 = a1.square();
          const a02 = a0.square();
          const a01 = a0.mul(a1);
          const b = a01.add(a01);
          return a02.add(b.radixMul(m)).add(a12.radixMul(m+m)).normalize();
        }

        public square(): BigNum {
            if (this.numDigs() < 2) {
                return this.digitMul(this);
            } else if (this.numDigs() > 60) {
                return this.recSquare();
            } else {
                return this.schoolMul(this);
            }
        }

        public squareMod(mod: Modulus): BigNum {
            return this.square().reduce(mod);
        }

        public highBitnum(): number {
          let i = this.digs.length - 1;
          let d = this.digs[i];
          let bn = 0;
          while (d === 0 && i > 0) {
            i--;
            d = this.digs[i];
          }
          if (d > 0xfff) {
            bn = 12;
            d = d >>> 12;
          }
          if (d > 0x3f) {
            bn += 6;
            d = d >>> 6;
          }
          if (d > 0x7) {
            bn += 3;
            d = d >>> 3;
          }
          return (i * LOG_RADIX) + bn + [0,0,1,1,2,2,2,2][d];
        }

        public getBit(i: number): boolean {
          const d = (i / LOG_RADIX | 0);
          const b = i % LOG_RADIX;
          return ((this.digs[d] & (1 << b)) !== 0);
        }

        public pow(exp: number|string|BigNum): BigNum {
          if (typeof exp !== "object") { exp = new BigNum(exp); }
          const bn = exp.highBitnum();
          let p = new BigNum(1);
          let a = this.copy();
          for (let i = 0; i <= bn; ++i) {
            if (exp.getBit(i)) {
              p = p.mul(a);
            }
            a = a.square();
          }
          return p.normalize();
        }

        public powMod(exp: BigNum, mod: Modulus): BigNum {
            if (typeof exp !== "object") { exp = new BigNum(exp); }
          const bn = exp.highBitnum();
          let p = new BigNum(1);
          let a = this.copy();
          for(let i = 0; i <= bn; ++i) {
            if (exp.getBit(i)) {
              p = p.mulMod(a, mod);
            }
            a = a.squareMod(mod);
          }
          return p.normalize();
        }

        /**
         * @returns the i'th group of d bits from this, assume d divides logRadix
         */
        public getBits(i: number, d: number): number {
          const digit = Math.floor(i * d / LOG_RADIX);
          const mask = (1 << d) - 1;
          const shift = (i * d) % LOG_RADIX;
          return ((this.digs[digit] >>> shift) & mask);
        }

        /**
         * return this^exp MOD mod, using d bits at a time. d must divide logRadix d=3 seems to be the sweet spot for 1024 bit prime and 256 bit exps see:
         * http://citeseerx.ist.psu.edu/viewdoc/summary?doi=10.1.1.33.1430
         */
        public powModN(exp: number|string|BigNum, mod: Modulus, d: number): BigNum {
          if (typeof exp !== "object") { exp = new BigNum(exp); }
          const m = 1 << d;
          const Mj = [new BigNum(1), this];  // Mj[j] == this^j up to j = m-1

          // pre-compute Mj
          for (let j = 2; j < m; ++j) {
            Mj[j] = this.mul(Mj[j-1]).reduce(mod);
          }
          const k = exp.numDigs() * LOG_RADIX / d;
          let F = exp.getBits(k - 1, d);
          let C = Mj[F];

          for(let i = k-2; i >= 0; --i) {
            // C = C^(2^d)
            for (let s = 0; s < d; ++s) {
              C = C.squareMod(mod);
            }
            F = exp.getBits(i, d);
            if (F !== 0) {
                C = C.mulMod(Mj[F], mod);
            }
          }

          return C.normalize();
        }


        /**
         * asynchronous version of powModN this yields control to the browser regularly to avoid running into statement or time limits imposed by some browsers
         * result is returned through resolve, exceptions may be returned in rejectCallback or thrown directly.
         */
        public powModNAsync(exp: number|string|BigNum, mod: Modulus, d: number, resolveCallback: (result: BigNum)=>void, rejectCallback: (exception: any)=>void) {
          if (typeof exp !== "object") { exp = new BigNum(exp); }
          const m = 1 << d;
          const Mj = [new BigNum(1), this];  // Mj[j] == this^j up to j = m-1

          // pre-compute Mj
          for (let j = 2; j < m; ++j) {
            Mj[j] = this.mul(Mj[j-1]).reduce(mod);
          }
          const k = exp.numDigs() * LOG_RADIX / d;
          let F = exp.getBits(k - 1, d);
          let C = Mj[F];
          let i = k - 2;

          // Optimization 4:
          // this self-executing function will compute part of the result, then set a timeout
          // to continue the computation
          // this method hands control to the browser regularly
          // (some browsers react very badly to hogging the GUI-thread, IE8 in particular)
          (function compute() {
            let result: BigNum;
            try {
              for (; i >= 0; --i) {
                for (let s = 0; s < d; ++s) {
                  C = C.squareMod(mod);
                }
                F = exp.getBits(i, d);
                if (F !== 0) {
                  C = C.mulMod(Mj[F], mod);
                }
                if (i > 0 && i % 3 === 0) {
                  --i;
                  setTimeout(compute, 0);
                  return;
                }
              }
              result = C.normalize();
            } catch (err) {
              rejectCallback(err);
              return;
            }
            resolveCallback(result);
          })();
        }

        /**
         * @returns [this DIV that, this MOD that]
         */
        public divMod(that: number|string|BigNum): BigNum[] {
          if (typeof that !== "object") { that = new BigNum(that); }
          // must denormalize this and that so that >= radix/2
          // by shifting both left a number of times. Better do this outside the
          // recursion
          const hb = that.highBitnum() % LOG_RADIX;
          let a = this.copy();
          if (hb < LOG_RADIX - 1) {
                that = that.shiftLeft(LOG_RADIX - 1 - hb);
                a = this.shiftLeft(LOG_RADIX - 1 - hb);
            }
          const qr = a._divMod(that);
          return [ qr[0].normalize(), qr[1].shiftRight(LOG_RADIX - 1 - hb).normalize() ];
        }

        /**
         * Recursive schoolbook algorithm. See Algorithm 3.2 in http://www.treskal.com/kalle/exjobb/original-report.pdf
         * @returns [this DIV that, this MOD that], needs that to be denormalized
         */
        private _divMod(that: BigNum): BigNum[] {
          const c = this.compare(that);
          if (c < 0) {
            return [new BigNum(0), this.copy()];
          }
          if (c === 0) return [ new BigNum(1), new BigNum(0)];

          // here this > that
          const d = this.numDigs() - that.numDigs() - 1;
          if (d > 0) {
            // this.numDigs() > that.numDigs() + 1
            const aprime = this.radixDiv(d);
            const s = this.radixMod(d);
            const qrprime = aprime._divMod(that);
            const qr2 = qrprime[1].radixMul(d).add(s)._divMod(that);
            const q = qrprime[0].radixMul(d).add(qr2[0]);
            const r = qr2[1];
            return [ q,r ];
          } else if (this.numDigs() === that.numDigs() + 1) {
            // recursively compute (this-that*radix)/that:
            const bshifted = that.radixMul(1);
            if (this.compare(bshifted) >= 0) {
              const asubb = this.sub(bshifted);
              const qr = asubb._divMod(that);
              return [qr[0].add(new BigNum("1000000")), qr[1] ];
            }
            const tmp = this.digs[this.digs.length - 1] * RADIX + this.digs[this.digs.length - 2];
            let q1 = Math.floor(tmp / that.digs[that.digs.length - 1]);
            if (q1 > RADIX - 1) q1 = RADIX-1;
            let T = that.mul(q1);
            while (T.compare(this) > 0) {
              q1--;
              T = T.sub(that);
            }
            return [new BigNum(q1), this.sub(T)];
          } else {
            // here this and that must have the same number of digits, and
            // this > that. Having prepared B = that to be >= radix/2
            // we know that q=1 and r = this-that
            const q2 = new BigNum(1);
            const r1 = this.sub(that);
            return [ q2, r1 ];
          }
        }

        public div(that: BigNum): BigNum {
          return this.divMod(that)[0];
        }

        public mod(that: BigNum): BigNum {
          return this.divMod(that)[1];
        }

        /**
         * Reduce this modulo the modulus, Barrett reduction, this must have at most twice as many digits as the modulus
         */
        public reduce(mod: Modulus): BigNum {
            if (this.numDigs() < mod.modulus.numDigs()) return this;
            if (this.numDigs() > 2 * mod.modulus.numDigs()) {
              throw new CrmError(ErrorTypes.BigNumError, "BigNum reduce was called with invalid arguments for modulus.");
            }
          const k1 = mod.k + 1;
          const q1 = this.radixDiv(mod.k - 1);
          const q2 = q1.mul(mod.mu);
          const q3 = q2.radixDiv(k1);
          const r1 = this.radixMod(k1);
          const r2term = q3.mul(mod.modulus);
          const r2 = r2term.radixMod(k1);
          let r;

          if (r1.compare(r2) < 0) {
            r = r1.add(mod.bkplus1).sub(r2);
          } else {
            r = r1.sub(r2);
          }

          while (r.compare(mod.modulus) >= 0) {
            r = r.sub(mod.modulus);
          }
          return r;
        }

        public mulMod(that: BigNum, mod: Modulus): BigNum {
            return this.mul(that).reduce(mod);
        }

      }
      
      /////////////////////// Modulus /////////////////////////////////////

    export class Modulus {
        public modulus: BigNum = new BigNum(3);                  // modulus shared by all instances
        public mu: BigNum = new BigNum(1);                       // Barrett mu for modular reduction
        public k=1;
        public bkplus1: BigNum = new BigNum(1);
        public b2k: BigNum = new BigNum(1);

        public constructor(it: number|string|BigNum) {
          this.initWith(it);
        }

        public initWith(mod: number|string|BigNum) {

            if (typeof mod !== "object") {
              mod = new BigNum(mod);
            }

            this.modulus = mod.copy();
            this.k = mod.numDigs();
            this.b2k = (new BigNum(1)).radixMul(2*this.k);
            this.mu = this.b2k.divMod(mod)[0];
            this.bkplus1 = (new BigNum(1)).radixMul(this.k + 1);
        }
    }
}
