namespace Cryptomathic.ASN1 {

  export class EncapsulatedContentInfo implements Asn1Object {

    private readonly type: Asn1Object;
    private readonly content?: OctetString;

    public constructor(type: Asn1Object, content?: OctetString) {
      this.type = type;
      this.content = content;
    }

    public encode(): number[] {
      const cInfo = new Sequence().push(this.type);
      if (this.content) {
        const conExplicit = ASN1Util.prependExplicitTag(this.content, ASN1Util.TagClasses.CONTEXT_SPECIFIC, 0);
        cInfo.push(conExplicit);
      }
      return cInfo.encode();
    }
  }
}
