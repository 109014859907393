///<reference path="Tokens.ts"/>
namespace Cryptomathic.Messages.Tags {

  import tokenTag = Cryptomathic.Messages.Tags.Tokens.tokenTag;

  export const ServerCommand = {
    commandId: byteTag(20000),

    userKeyId: bytesTag(20001),
    // policyId = 20002,
    // policyName = 20003,
    // policyGuid = 20004,
    token: tokenTag(20005),

    ctkId: bytesTag( 20006),
    ctkEncryption: bytesTag(20007),
    challengeId: stringTag(20008),
    challengeData: stringTag( 20009)
  };

}
