///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.Utils.ByteArrayUtils {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export function xor(one: number[], other: number[]) {
    if (one.length !== other.length) {
      throw new CrmError(ErrorTypes.InvalidArgumentError, "cannot xor arrays of unequal length (len(one)="+one.length+", len(other)="+other.length+")");
    }
    return one.map((oneByte,i)=> oneByte^other[i]);
  }

  export function emptyArray(len: number, fill?: number): number[] {
    const fillWith = fill?fill:0;
    const result = new Array(len);

    if (!Array.prototype.fill) {
      //IE workaround
      for(let i=0;i<len;i++) {
        result[i]= fillWith;
      }
      return result;
    } else {
      return result.fill(fillWith);
    }
  }

  export function convertToNumberArray(input: number[]|Uint8Array): number[] {
    return input instanceof Array?input:Array.prototype.slice.call(input);
  }

  export function convertToByteArray(input: number[]|Uint8Array): Uint8Array {
    return input instanceof Uint8Array ? input : Array.prototype.slice.call(input);
  }

  export function concat(...arrays: Uint8Array[]): Uint8Array {
    if (typeof Uint8Array.prototype.set !== "function") {
      return ie9concat(...arrays);
    }

    const totalLength = arrays.reduce((total, arr) => total + arr.length, 0);
    const result = new Uint8Array(totalLength);
    arrays.reduce((offset, arr) => {
      result.set(arr, offset);
      return offset + arr.length;
    }, 0);
    return result;
  }

  function ie9concat(...others: Uint8Array[]): Uint8Array {
    const otherArrays: number[][] = Array.prototype.map.call(others,(element: Uint8Array) => {
      return Array.prototype.slice.call(element);
    });

    return new Uint8Array([].concat(otherArrays));
  }
}

