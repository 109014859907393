namespace Cryptomathic.ASN1 {

  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  export class SignedData implements Asn1Object {

    private version: Asn1Integer;
    private signerInfo: SignerInfo;
    private digestAlgorithms: Set;
    private encapsulatedContentInfo: EncapsulatedContentInfo;
    private certificate: Asn1Object;

    public setVersion(version: Asn1Integer) {
      this.version = version;
      return this;
    }

    public setDigestAlgorithms(digestAlgorithms: Set): SignedData {
      this.digestAlgorithms = digestAlgorithms;
      return this;
    }

    public setEncapsulatedContentInfo(encapsulatedContentInfo: EncapsulatedContentInfo): SignedData {
      this.encapsulatedContentInfo = encapsulatedContentInfo;
      return this;
    }

    public setEncodedCertificate(encodedCertificate: number[]): SignedData {
      this.certificate = new Asn1Literal(encodedCertificate);
      return this;
    }

    public setSignerInfo(signerInfo: SignerInfo) {
      this.signerInfo = signerInfo;
      return this;
    }

    public encode(): number[] {
      if (!this.version) {
        throw new Cryptomathic.Error.CrmError(ErrorTypes.IllegalState, "Error while encoding SignedData object, version is not set");
      }
      if (!this.digestAlgorithms) {
        throw new Cryptomathic.Error.CrmError(ErrorTypes.IllegalState, "Error while encoding SignedData object, digestAlgorithms is not set");
      }
      if (!this.encapsulatedContentInfo) {
        throw new Cryptomathic.Error.CrmError(ErrorTypes.IllegalState, "Error while encoding SignedData object, encapsulatedContentInfo is not set");
      }
      if (!this.certificate) {
        throw new Cryptomathic.Error.CrmError(ErrorTypes.IllegalState, "Error while encoding SignedData object, certificate is not set");
      }
      if (!this.signerInfo) {
        throw new Cryptomathic.Error.CrmError(ErrorTypes.IllegalState, "Error while encoding SignedData object, signerInfo is not set");
      }

      const certificates = new Set(this.certificate);
      const implicitCertificates = ASN1Util.makeTagImplicit(certificates, ASN1Util.TagClasses.CONTEXT_SPECIFIC, 0);
      const signerInfos = new Set(this.signerInfo);

      return new Sequence().push(this.version)
        .push(this.digestAlgorithms)
        .push(this.encapsulatedContentInfo)
        .push(implicitCertificates)
        .push(signerInfos)
        .encode();
    }
  }
}
