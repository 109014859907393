///<reference path="../messages/Tags/HsmResponse.ts"/>
///<reference path="../messages/Tags/ServerCommand.ts"/>
///<reference path="../messages/Tags/HsmCommand.ts"/>
///<reference path="../messages/Tags/Response.ts"/>
///<reference path="../messages/Tags/TagMap.ts"/>
///<reference path="../messages/OuterResponse.ts"/>
///<reference path="../utils/Errors.ts"/>
///<reference path="../error/CrmError.ts"/>
///<reference path="../messages/SignRequest.ts"/>
///<reference path="../messages/SignResponse.ts"/>
const Es5Error = Error;

namespace Cryptomathic.SignerUserSDK.Impl {
  import SignatureRequest = Cryptomathic.Crypto.SignatureRequest;
  import CrmError = Cryptomathic.Error.CrmError;
  import AuthenticationToken = Cryptomathic.Messages.AuthenticationToken;
  import KeyEntry = Cryptomathic.Messages.KeyEntry;
  import PolicyEntry = Cryptomathic.Messages.PolicyEntry;
  import parseSignerResponse = Cryptomathic.Messages.Response.parseSignerResponse;
  import emptyTagMap = Cryptomathic.Messages.Tags.emptyTagMap;
  import HsmCommand = Cryptomathic.Messages.Tags.HsmCommand;
  import HsmResponse = Cryptomathic.Messages.Tags.HsmResponse;
  import parseTags = Cryptomathic.Messages.Tags.parseTags;
  import Response = Cryptomathic.Messages.Tags.Response;
  import ServerCommand = Cryptomathic.Messages.Tags.ServerCommand;
  import TagMap = Cryptomathic.Messages.Tags.TagMap;
  import isEs5Error = Cryptomathic.Utils.Errors.isEs5Error;

  type long = number[];

  export type RejectCallback = (error: ErrorTypes, message: string, signerError?: number) => void;

  interface Command {
    commandId: Cryptomathic.Messages.CommandIDs;
    hsmContent: number[];
    serverContent: number[];
  }

  export function handleError(error: Error|CrmError, rejectCallback: RejectCallback) {
    if (error instanceof Cryptomathic.Error.CrmError) {
      if (error.getType() === ErrorTypes.SignerError) {
        rejectCallback(error.getType(), error.getMessage(), error.getSignerErrorCode());
      } else {
        rejectCallback(error.getType(), error.getMessage());
      }
    } else if (isEs5Error(error)) {
      rejectCallback(ErrorTypes.UnknownError, error.name + ": " + error.message);
    } else {
      rejectCallback(ErrorTypes.UnknownError, "Unknown error");
    }
  }

  function isSecureEnvironment(): boolean {
    return window.location.protocol.toLowerCase() === "https:";
  }

  export function getUsableTokens(policyEntry: PolicyEntry, tokenList: AuthenticationToken[]): AuthenticationToken[] {
    if (policyEntry === undefined || policyEntry === null) {
      throw new Es5Error("ErrorType: " + Cryptomathic.SignerUserSDK.ErrorTypes.InvalidArgumentError + " Policy entry not specified");
    }

    const acceptedTokenTypes = policyEntry.POLICY_ACCEPTED_TOKENS;

    // Empty string represents the case where all token types are accepted
    if (!acceptedTokenTypes) {
      return tokenList;
    }

    if (tokenList === undefined || tokenList === null) {
      return [];
    }

    const usableTokenTypeList = acceptedTokenTypes.split(":");
    return tokenList.filter((token) => usableTokenTypeList.indexOf(token.getTokenType())>-1); //Array.includes is not supported by IE, so using Array.indexof()>-1 instead
  }


  export class SignerUserSDKObject {
    private state: Cryptomathic.Utils.StateHandler.StateObject;
    private readonly host: string;
    private readonly timeout: number;

    public constructor(host: string, timeout: number) {
      this.host = host;
      this.timeout = timeout;
    }

    public initialize() {
      if (!isSecureEnvironment()) {
        throw new Es5Error("ErrorType: " + Cryptomathic.SignerUserSDK.ErrorTypes.SecureEnvError + " SDK cannot be initialized. The SDK cannot run in insecure " +
          " environments (i.e. window.location.protocol !== 'https:')");
      }
      this.initializeSdk();
    }

    public isInitialized(): boolean {
      return this.state !== undefined;
    }

    public isLoggedIn(): boolean {
      return this.state !== undefined && this.state.hasSessionKey();
    }

    public free() {
      if (this.state === undefined) {
        return;
      }
      this.state = undefined;
    }

    public createSession(resolveCallback: (tokenlist: AuthenticationToken[], policylist: PolicyEntry[]) => void, rejectCallback: RejectCallback, saml?: number[]) {
      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }

      this.resetState();

      const random = Cryptomathic.Crypto.Random.SecureRandom.getInstance();
      const nonce1 = random.generate(32);
      const helloKey = random.generate(32);

      let encryptedContentRequest: number[];

      try {
        if (!Cryptomathic.SCK || !Cryptomathic.SCK.Details ||  !Cryptomathic.SCK.Details.KeySize ||Cryptomathic.SCK.Details.KeySize === 0) {
          rejectCallback(ErrorTypes.NoSckError, "No SCK has been configured");
          return;
        }

        const sckID = Cryptomathic.Utils.StringUtils.hexToBytes(Cryptomathic.SCK.Details.KeyID);
        const pubExp = Cryptomathic.Utils.StringUtils.hexToBytes(Cryptomathic.SCK.Details.PublicExponent);
        const modulus = Cryptomathic.Utils.StringUtils.hexToBytes(Cryptomathic.SCK.Details.Modulus);
        const spki = new Cryptomathic.ASN1.SubjectPublicKeyInfo([], modulus, pubExp);

        const rejectCreateCallback = (msg: string) => rejectCallback(ErrorTypes.CryptoError, "Could not create encrypted content: " + msg);

        Cryptomathic.Crypto.CryptoUtils.createEncryptedContentForCreateSessionRequest(spki, Cryptomathic.Utils.ByteArrayUtils.convertToByteArray(nonce1), Cryptomathic.Utils.ByteArrayUtils.convertToByteArray(helloKey),
          (ecr) => {
            encryptedContentRequest = ecr;
            const createSessionRequest = Cryptomathic.Messages.Request.createSessionRequest(sckID, encryptedContentRequest, true, saml);
            const connection = new Cryptomathic.Comm.SignerConnection(this.host, this.timeout);

            connection.send(createSessionRequest, (responseBytes: number[]) => {
              try {
                const response = Cryptomathic.Messages.Response.CreateSession.parse(responseBytes);
                const {nonce2, softwareKey} = Cryptomathic.SignerUserSDK.Impl.SignerUserSDKObject.decryptHsmResponse(response.encryptedContent, helloKey);

                const softDecryptedContent = Cryptomathic.Crypto.gcmDecrypt(response.softwareEncryptedContent, softwareKey);
                const {tokens, policies} = Cryptomathic.Messages.Response.CreateSessionServer.parse(softDecryptedContent);

                //generate session key from request data and response data
                const sessionKey = Cryptomathic.Crypto.SessionKeyUtils.createSessionKey(nonce1, nonce2, helloKey, encryptedContentRequest, response.encryptedContent);
                //save session key to state object
                this.state.setSessionKey(sessionKey);
                this.state.setSessionID(response.sessionID);

                this.state.setSoftwareKey(softwareKey);
                resolveCallback(tokens, policies);
              } catch (err) {
                handleError(err, rejectCallback);
              }
            }, rejectCallback);
          },
          rejectCreateCallback);
      } catch (err) {
        handleError(err, rejectCallback);
      }
    }

    private static decryptHsmResponse(encryptedContent: number[], helloKey: number[]): { nonce2: number[]; softwareKey: number[] } {
      // decrypt and extract nonce2 and key for software encrypted data
      const decryptedContent = Cryptomathic.Crypto.gcmDecrypt(encryptedContent, helloKey);
      const tags = parseTags(decryptedContent);
      const nonce2 = tags.get(HsmResponse.nonce);
      const softwareKey = tags.get(HsmResponse.serverSessionKey);
      return {nonce2, softwareKey};
    }

    public getChallenge(authenticationToken: AuthenticationToken, resolveCallback: (response: Messages.AuthenticationChallenge) => void, rejectCallback: RejectCallback) {
      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }

      this.state.clearLastChallenge();

      if (authenticationToken.getTokenType() !== "SMS" && authenticationToken.getTokenType() !== "OATH-OCRA") {
        rejectCallback(ErrorTypes.InvalidArgumentError, "Get challenge was called with an unsupported token type: " + authenticationToken.getTokenType());
        return;
      }

      try {
        const tokenTypeId = Cryptomathic.Messages.Tags.Tokens.fromName(authenticationToken.getTokenType());
        const hsmContent = HsmCommand.tokenType.encode(tokenTypeId).concat(
          HsmCommand.tokenSerial.encode(authenticationToken.getTokenSerial())
        );

        const serverContent = ServerCommand.token.encode(authenticationToken);

        const command = {commandId:Cryptomathic.Messages.CommandIDs.Get_Challenge, hsmContent, serverContent};
        this.encryptedSendReceive(command,
          (hsmResponse) => {
          const challengeId = hsmResponse.get(HsmResponse.authenticationChallengeId);
          const challengeData = hsmResponse.getOptional(HsmResponse.authenticationChallengeData) || "";

          const challenge = new Cryptomathic.Messages.AuthenticationChallenge(challengeId, challengeData);

          this.state.setLastChallenge(authenticationToken, challengeId, challengeData);
          resolveCallback(challenge);
        }, rejectCallback);
      } catch (err) {
        handleError(err, rejectCallback);
      }
    }

    public escalate(authenticationToken: AuthenticationToken, otp: string, resolveCallback: () => void, rejectCallback: RejectCallback) {
      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }
      if(!this.state.prepareEscalateSession(authenticationToken)) {
        rejectCallback(ErrorTypes.InvalidArgumentError, "Cannot escalate with a token that is inconsistent with the last received challenge.");
        return;
      }

      const random = Cryptomathic.Crypto.Random.SecureRandom.getInstance();
      const salt = random.generate(32);
      const otpBytes = Cryptomathic.Utils.StringUtils.string2bytes(otp);

      this.getTokenCredentials(otpBytes, authenticationToken, salt,
        (ctkId: number[], tokenCredentials: number[]) => {
          try {
            const {serverContent, hsmContent} = this.escalateCommandParts(authenticationToken, ctkId, tokenCredentials);

            const escalateCommand = {commandId:Cryptomathic.Messages.CommandIDs.Escalate, hsmContent, serverContent};

            this.encryptedSendReceive(escalateCommand, (innerResponseParser) => {
              if (this.state.getSessionAuthLevel() === Cryptomathic.Messages.AuthLevel.TWO_FACTOR) {
                this.state.setSessionKey(Cryptomathic.Crypto.SessionKeyUtils.deriveNewKey(otpBytes, salt, this.state.getLastChallengeData(), this.state.getSessionKey()));
              }
              this.state.clearLastChallenge();
              resolveCallback();
            }, rejectCallback);
          } catch (err) {
            handleError(err, rejectCallback);
          }
        }, rejectCallback);
    }

    private escalateCommandParts(authenticationToken: AuthenticationToken, ctkId: number[], tokenCredentials: number[]) {
      let serverContent = ServerCommand.token.encode(authenticationToken).concat(
        ServerCommand.ctkId.encode(ctkId),
        ServerCommand.ctkEncryption.encode(tokenCredentials)
      );

      if (authenticationToken.getTokenType() === "OATH-OCRA" || authenticationToken.getTokenType() === "SMS") {
        if (!this.state.hasChallenge()) {
          throw new CrmError(ErrorTypes.IllegalState, "Using challenge token, but there is no challenge data - has GetChallenge been called?");
        }
        serverContent = serverContent.concat(ServerCommand.challengeId.encode(this.state.getLastChallengeId()));
        if (authenticationToken.getTokenType() === "OATH-OCRA") {
          serverContent = serverContent.concat(ServerCommand.challengeData.encode(this.state.getLastChallengeData()));
        }
      }

      const tokenId = Cryptomathic.Messages.Tags.Tokens.fromName(authenticationToken.getTokenType());
      const hsmContent = HsmCommand.tokenType.encode(tokenId).concat(
        HsmCommand.tokenSerial.encode(authenticationToken.getTokenSerial()),
        HsmCommand.tokenCredentials.encode(tokenCredentials)
      );
      return {serverContent, hsmContent};
    }

    public escalateAndSign(authenticationToken: AuthenticationToken, otp: string, keyEntry: KeyEntry, toSign: SignatureRequest|number[], resolveCallback: (signature: number[])=>void, rejectCallback: RejectCallback) {
      if (!keyEntry) {
        rejectCallback(ErrorTypes.InvalidArgumentError, "a key is needed for signing, but none was given");
        return;
      }

      if (!toSign) {
        rejectCallback(ErrorTypes.InvalidArgumentError, "nothing to sign");
        return;
      }

      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }
      if(!this.state.prepareEscalateSession(authenticationToken)) {
        rejectCallback(ErrorTypes.InvalidArgumentError, "Cannot escalate with a token that is inconsistent with the last received challenge.");
        return;
      }

      const random = Cryptomathic.Crypto.Random.SecureRandom.getInstance();
      const salt = random.generate(32);
      const otpBytes = Cryptomathic.Utils.StringUtils.string2bytes(otp);

      this.getTokenCredentials(otpBytes, authenticationToken, salt,
        (ctkId: number[], tokenCredentials: number[]) => {
          try {
            const escalateParts = this.escalateCommandParts(authenticationToken, ctkId, tokenCredentials);

            const signParts = Cryptomathic.Messages.Sign.Request.signCommandParts(keyEntry, toSign);

            const escalateAndSignCommand = {
              commandId:Cryptomathic.Messages.CommandIDs.Escalate_and_Sign,
              hsmContent: escalateParts.hsmContent.concat(signParts.hsmContent),
              serverContent: escalateParts.serverContent.concat(signParts.serverContent)
            };

            this.encryptedSendReceive(escalateAndSignCommand, (hsmResponse) => {
              if (this.state.getSessionAuthLevel() !== Cryptomathic.Messages.AuthLevel.NONE) {
                this.state.setSessionKey(Cryptomathic.Crypto.SessionKeyUtils.deriveNewKey(otpBytes, salt, this.state.getLastChallengeData(), this.state.getSessionKey()));
              }
              this.state.clearLastChallenge();

              try {
                const signature = Cryptomathic.Messages.Sign.Response.extractSignature(hsmResponse, toSign, keyEntry);
                resolveCallback(signature);
              } catch (err) {
                handleError(err, rejectCallback);
              }
            }, rejectCallback);
          } catch (err) {
            handleError(err, rejectCallback);
          }
        }, rejectCallback);
    }

    public sign(keyEntry: KeyEntry, toSign: SignatureRequest|number[], resolveCallback: (signature: number[])=>void, rejectCallback: RejectCallback) {
      if (!keyEntry) {
        rejectCallback(ErrorTypes.InvalidArgumentError, "a key is needed for signing, but none was given");
        return;
      }

      if (!toSign) {
        rejectCallback(ErrorTypes.InvalidArgumentError, "nothing to sign");
        return;
      }

      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }

      try {
        const {serverContent, hsmContent} = Cryptomathic.Messages.Sign.Request.signCommandParts(keyEntry, toSign);

        const doCommand = {commandId: Cryptomathic.Messages.CommandIDs.Do_Operation, hsmContent, serverContent};
        this.encryptedSendReceive( doCommand,
          (hsmResponse) => {
            try {
              const signature = Cryptomathic.Messages.Sign.Response.extractSignature(hsmResponse, toSign, keyEntry);
              resolveCallback(signature);
            } catch (err) {
              handleError(err, rejectCallback);
            }
          },
          rejectCallback);
      } catch (err) {
        handleError(err, rejectCallback);
      }
    }

    public logoff(resolveCallback: ()=>void, rejectCallback: RejectCallback) {
      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }

      const logoffCmd = {commandId: Cryptomathic.Messages.CommandIDs.Logoff, hsmContent: [] as number[], serverContent: [] as number[]};
      this.encryptedSendReceive(logoffCmd, (hsmResponse) => {
        this.resetState();
        resolveCallback();
      }, rejectCallback);
    }

    public noop(resolveCallback: ()=>void, rejectCallback: RejectCallback) {
      if (!this.isInitialized()) {
        rejectCallback(ErrorTypes.NotInitializedError, "SDK has not yet been initialized");
        return;
      }

      if (!this.isLoggedIn()) {
        rejectCallback(ErrorTypes.IllegalState, "Session has not been created");
        return;
      }

      const noopCmd = {commandId: Cryptomathic.Messages.CommandIDs.Noop, hsmContent: [] as number[], serverContent: [] as number[]};
      this.encryptedSendReceive(noopCmd, (hsmResponse) => {
        try {
          resolveCallback();
        } catch (err) {
          handleError(err, rejectCallback);
        }
      }, rejectCallback);
    }

    private initializeSdk() {
      // if we already exist, free first
      if (this.state !== undefined) {
        this.free();
      }

      this.state = new Cryptomathic.Utils.StateHandler.StateObject();
      this.state.setSessionAuthLevel(Cryptomathic.Messages.AuthLevel.NONE);
    }

    private resetState() {
      this.state = new Cryptomathic.Utils.StateHandler.StateObject();
    }

    private encryptedSendReceive(command: Command, resolveCallback: (response: TagMap) => void, rejectCallback: RejectCallback) {
      if (!this.isLoggedIn()) {
        rejectCallback(ErrorTypes.IllegalState, "Session has not been created");
        return;
      }

      const hsmCmdId = HsmCommand.commandId.encode(command.commandId);
      const seqNumber = HsmCommand.sequenceNumber.encode(this.state.getSequenceNumber());
      const hsmEncryptedContent = Cryptomathic.Crypto.gcmEncrypt(hsmCmdId.concat(seqNumber, command.hsmContent), this.state.getSessionKey());

      const serverCmdId = ServerCommand.commandId.encode(command.commandId);
      const serverEncryptedContent = Cryptomathic.Crypto.gcmEncrypt(serverCmdId.concat(command.serverContent), this.state.getSoftwareKey());

      const signerRequest = Cryptomathic.Messages.createUserRequest(this.state.getSessionID(), hsmEncryptedContent, serverEncryptedContent);
      const connection = new Cryptomathic.Comm.SignerConnection(this.host, this.timeout);

      const handleResponse = (encryptedBytes: number[]) => {
        try {
          const responseTags = parseSignerResponse(encryptedBytes, "RENC");

          const outerErrorCode = Response.errorCode.get(responseTags);

          const hsmEncryptedResponse = Response.hsmEncryption.getOptional(responseTags);
          if (outerErrorCode === Cryptomathic.Messages.SignerErrorCodes.FORCED_LOGOFF && !hsmEncryptedResponse) {
            this.resetState();
            resolveCallback(emptyTagMap());
            return;
          }

          if (!hsmEncryptedResponse || hsmEncryptedResponse.length === 0) {
            rejectCallback(ErrorTypes.InvalidResponseError, "Encrypted content was missing from response");
            return;
          }

          const hsmResponseBytes = Cryptomathic.Crypto.gcmDecrypt(hsmEncryptedResponse, this.state.getSessionKey());
          const hsmResponseTags = parseTags(hsmResponseBytes);

          this.verifySequenceNumber(hsmResponseTags);

          const hsmReturnCode = hsmResponseTags.get(HsmResponse.returnCode);

          if (outerErrorCode === Cryptomathic.Messages.SignerErrorCodes.FORCED_LOGOFF) {
            try {
              if (hsmReturnCode !== Cryptomathic.Messages.SignerErrorCodes.OK) {
                this.resetState();
                rejectCallback(ErrorTypes.SignerError, "Signer returned error code: " + Cryptomathic.Messages.SignerErrorCodes.getError(hsmReturnCode), hsmReturnCode);
                return;
              }
              this.resetState();
              resolveCallback(hsmResponseTags);
            } catch (err) {
              this.resetState();
              handleError(err, rejectCallback);
            }
          } else if (outerErrorCode === Cryptomathic.Messages.SignerErrorCodes.OK) {
            if (hsmReturnCode !== Cryptomathic.Messages.SignerErrorCodes.OK) {
               if (hsmReturnCode === Cryptomathic.Messages.SignerErrorCodes.AUTHENTICATION_FAILED_FORCED_LOGOFF) {
                 this.resetState();
               }
               rejectCallback(ErrorTypes.SignerError, "Signer returned error code: " + Cryptomathic.Messages.SignerErrorCodes.getError(hsmReturnCode), hsmReturnCode);
            } else {
              this.state.setSessionAuthLevel(hsmResponseTags.get(HsmResponse.sessionAuthLevel));
              resolveCallback(hsmResponseTags);
            }
          } else {
            rejectCallback(ErrorTypes.SignerError,
              "Signer returned error code: " + Cryptomathic.Messages.SignerErrorCodes.getError(outerErrorCode),
              outerErrorCode);
          }
        } catch (err) {
          handleError(err, rejectCallback);
        }
      };
      connection.send(signerRequest, handleResponse, rejectCallback);
    }

    private verifySequenceNumber(hsmResponse: TagMap): void {
      const seqNumber = hsmResponse.get(HsmResponse.sequenceNumber);
      if (seqNumber !== this.state.getSequenceNumber() + 1) {
        throw new CrmError(ErrorTypes.InvalidResponseError, "Invalid sequence number found in response");
      }
      this.state.incrementSequenceNumber();
    }

    private getTokenCredentials(otpBytes: number[], token: AuthenticationToken, salt: number[], resolveCallback: (ctkId: long, tokenCredentials: number[]) => void, rejectCallback: RejectCallback) {
      try {
        if (!Cryptomathic.CTK || !Cryptomathic.CTK.Details || !Cryptomathic.CTK.Details.KeySize || Cryptomathic.CTK.Details.KeySize === 0) {
          rejectCallback(ErrorTypes.NoCtkError, "No CTK has been configured");
          return;
        }

        const ctkId = Cryptomathic.Utils.StringUtils.hexToBytes(Cryptomathic.CTK.Details.KeyID);
        const pubExp = Cryptomathic.Utils.StringUtils.hexToBytes(Cryptomathic.CTK.Details.PublicExponent);
        const modulus = Cryptomathic.Utils.StringUtils.hexToBytes(Cryptomathic.CTK.Details.Modulus);

        const spki = new Cryptomathic.ASN1.SubjectPublicKeyInfo([], modulus, pubExp);
        const message = otpBytes.concat(salt);

        Cryptomathic.Crypto.CryptoUtils.encryptRsaWithOaep(spki, new Uint8Array(message),
          (encryptedBytes) => resolveCallback(ctkId, encryptedBytes),
          (msg: string) => rejectCallback(ErrorTypes.CryptoError, "RSA encryption failed: " + msg));

      } catch (err) {
        handleError(err, rejectCallback);
      }
    }

  }
}
