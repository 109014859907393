namespace Cryptomathic.Messages {
  /**
   * Defines the authentication levels available.
   */
  export enum AuthLevel {
    // @formatter:off
    NONE=          0,
    PASSWORD_ONLY= 1,
    TWO_FACTOR=    2
    // @formatter:on
  }
}
