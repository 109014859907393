///<reference path="../error/CrmError.ts"/>
namespace Cryptomathic.ASN1 {
    import CrmError = Cryptomathic.Error.CrmError;
    import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

    /**
     * The ASN1SubObject is a helper than can take a DER-encoded bytearray and read the first tag.
     *
     * When read, the tag value, the entire length (tag+length+content) of the first tag and the content of the first tag
     * is made available. The class does not assume that it can consume all the bytes input.
     *
     * The tag may be compound, in which case is can be called recursively on the content.
     */
    export class ASN1SubObject {

        public static decode(bytes: number[], index: number) {
            return new ASN1SubObject(bytes, index);
        }

        private bytes: number[];
        private tag: number;
        private read: number;

        private constructor(bytes: number[], index: number) {
            if (bytes.length < 2) {
                throw new CrmError(ErrorTypes.SerializationError,"Supplied data does not contain a valid encoding");
            }

            let i = 0;

            this.tag = bytes[index + i];
            i++;
            const LoL = decodeLengthOfLength(bytes, index + i);
            const length = decodeLength(bytes, index + i, LoL);

            if (bytes.length < 1 + LoL + length) {
                throw new CrmError(ErrorTypes.SerializationError,"Supplied data does not contain a valid encoding");
            }
            this.bytes = new Array<number>(length);
            for (let j = 0; j < length; j++) {
                this.bytes[j] = bytes[index + 1 + LoL + j];
            }
            this.read = 1 + LoL + length;
        }

        /**
         * The content of the tag
         */
        public getBytes(): number[] {
            return this.bytes;
        }

        /**
         * The tag value
         */
        public getTag(): number {
            return this.tag;
        }

        /**
         * The number of bytes used for the tag+length+content
         */
        public getRead(): number {
            return this.read;
        }

        /**
         * Check the content of the tag has a given value
         * @param value Hex-encoded string to be checked against
         * @param message Message to give if check fails
         */
        public checkValue(value: string, message: string): void {
            const bytes = Cryptomathic.Utils.StringUtils.hexToBytes(value);
            if (bytes.length !== this.bytes.length) {
                throw new CrmError(ErrorTypes.SerializationError,"ASN1SubObject is invalid: " + message);
            }

            const len = value.length;
            for (let i = 0; i < len; i++) {
                if (bytes[i] !== this.bytes[i]) {
                    throw new CrmError(ErrorTypes.SerializationError,"ASN1SubObject is invalid: " + message);
                }
            }
        }
    }

    function decodeLengthOfLength(bytes: number[], index: number) {
        if (bytes[index] === 0x80) {
            throw new CrmError(ErrorTypes.SerializationError, "Indefinite length not supported");
        } else  if ((bytes[index] & 0x80) === 0x80) {
            // long format
            return 1 + (bytes[index] & 0x7f);
        } else {
            // short format
            return 1;
        }
    }


    function decodeLength(bytes: number[], index: number, lengthOfLength: number) {
        if (bytes.length - index < lengthOfLength) {
            throw new CrmError(ErrorTypes.SerializationError,"Data length indicates it is longer than supplied data");
        }
        
        if (lengthOfLength !== 1) {
            let length = 0;
            for (let i = 1; i < lengthOfLength; i++) {
                length = (length << 8) + bytes[i + index];
            }
            return length;
        } else {
            return bytes[index] & 0x7f;
        }
    }

}
