(function(ns) {

    /*!
    THIS HAS BEEN ADDED FROM AN EXTERNAL PROJECT AND MODIFIED TO MATCH THE SIGNER CODE
    Link
        https://github.com/bitwiseshiftleft/sjcl/blob/master/core/gcm.js
     */

    /** Encrypt in GCM mode.
     * @static
     * @param key The key to use. Must be 32 bytes.
     * @param plaintext The plaintext data.
     * @param iv The initialization value.
     * @param [adata=[]] The authenticated data.
     * @param [tlen=128] The desired tag length, in bits.
     * @return The encrypted data, an array of bytes.
     */
    ns.encrypt = function (key, plaintext, iv, adata, tlen) {
        return this.stanfordStyleToSignerStyle(
            encryptImpl(
                key, // key doesn't have be converted as it will not be used by the Stanford code
                this.signerStyleToStanfordStyle(plaintext),
                this.signerStyleToStanfordStyle(iv),
                this.signerStyleToStanfordStyle(adata),
                tlen)
        );
    };

    /** Decrypt in GCM mode.
     * @static
     * @param key The key to use. Must be 32 bytes.
     * @param ciphertext The ciphertext data.
     * @param iv The initialization value.
     * @param [adata=[]] The authenticated data.
     * @param [tlen=128] The desired tag length, in bits.
     * @return The decrypted data.
     */
    ns.decrypt = function (key, ciphertext, iv, adata, tlen) {
        return this.stanfordStyleToSignerStyle(
            decryptImpl(
                key, // key doesn't have be converted as it will not be used by the Stanford code
                this.signerStyleToStanfordStyle(ciphertext),
                this.signerStyleToStanfordStyle(iv),
                this.signerStyleToStanfordStyle(adata),
                tlen)
        );
    };

    /** Encrypt in GCM mode.
     * @static
     * @param {Object} key The key to use. Must be 32 bytes.
     * @param {bitArray} plaintext The plaintext data.
     * @param {bitArray} iv The initialization value.
     * @param {bitArray} [adata=[]] The authenticated data.
     * @param {Number} [tlen=128] The desired tag length, in bits.
     * @return {bitArray} The encrypted data, an array of bytes.
     */
    var encryptImpl = function (key, plaintext, iv, adata, tlen) {
        var out, data = plaintext.slice(0), w=Cryptomathic.Crypto.GcmUtils;
        tlen = tlen || 128;
        adata = adata || [];

        // encrypt and tag
        out = _ctrMode(true, key, data, adata, iv, tlen);

        return w.concat(out.data, out.tag);
    };

    /** Decrypt in GCM mode.
     * @static
     * @param {Object} key The key to use. Must be 32 bytes.
     * @param {bitArray} ciphertext The ciphertext data.
     * @param {bitArray} iv The initialization value.
     * @param {bitArray} [adata=[]] The authenticated data.
     * @param {Number} [tlen=128] The desired tag length, in bits.
     * @return {bitArray} The decrypted data.
     */
    var decryptImpl = function (key, ciphertext, iv, adata, tlen) {
        var out, data = ciphertext.slice(0), tag, w=Cryptomathic.Crypto.GcmUtils, l=w.bitLength(data);
        tlen = tlen || 128;
        adata = adata || [];

        // Slice tag out of data
        if (tlen <= l) {
            tag = w.bitSlice(data, l-tlen);
            data = w.bitSlice(data, 0, l-tlen);
        } else {
            tag = data;
            data = [];
        }

        // decrypt and tag
        out = _ctrMode(false, key, data, adata, iv, tlen);

        if (!w.equal(out.tag, tag)) {
            throw new Cryptomathic.Error.CrmError(usdk.ErrorTypes.CryptoError, "gcm: tag doesn't match");
        }
        return out.data;
    };

    /*
    The AES adaptor will
        * take data encoded with the Stanford (GcmUtil) style,
        * change the data into Signer encoding style,
        * encrypt it with Signer's AES implementation (AES-256)
        * transform the encrypted data from Signer encoding style into Stanford (GcmUtil) style, which will be the output
     */
    var create_AES_Adaptor = function(key) {
        return {
            key: key,

            encrypt: function (stanfordStyleInput) { // jshint ignore:line
                var signerStyleInput = Cryptomathic.Crypto.Gcm.stanfordStyleToSignerStyle(stanfordStyleInput);
                var encryptedData = Cryptomathic.Crypto.AES.EncryptBlock_256(signerStyleInput, this.key);
                return Cryptomathic.Crypto.Gcm.signerStyleToStanfordStyle(encryptedData);
            }
        };
    };

    ns.signerStyleToStanfordStyle = function(data) {
        return data?this.toBits(Cryptomathic.Utils.StringUtils.bytesToHex(data)):data;
    };

    ns.stanfordStyleToSignerStyle = function(data) {
        return data?Cryptomathic.Utils.StringUtils.hexToBytes(this.fromBits(data)):data;
    };

    /* Compute the galois multiplication of X and Y
     * @private
     */
    var _galoisMultiply = function (x, y) {
        var i, j, xi, Zi, Vi, lsb_Vi;

        Zi = [0,0,0,0];
        Vi = y.slice(0);

        // Block size is 128 bits, run 128 times to get Z_128
        for (i=0; i<128; i++) {
            xi = (x[Math.floor(i/32)] & (1 << (31-i%32))) !== 0;
            if (xi) {
                // Z_i+1 = Z_i ^ V_i
                Zi = Cryptomathic.Crypto.GcmUtils._xor4(Zi, Vi);
            }

            // Store the value of LSB(V_i)
            lsb_Vi = (Vi[3] & 1) !== 0;

            // V_i+1 = V_i >> 1
            for (j=3; j>0; j--) {
                Vi[j] = (Vi[j] >>> 1) | ((Vi[j-1]&1) << 31);
            }
            Vi[0] = Vi[0] >>> 1;

            // If LSB(V_i) is 1, V_i+1 = (V_i >> 1) ^ R
            if (lsb_Vi) {
                Vi[0] = Vi[0] ^ (0xe1 << 24);
            }
        }
        return Zi;
    };

    var _ghash = function (H, Y0, data) {
        var Yi, i, l = data.length;

        Yi = Y0.slice(0);
        for (i=0; i<l; i+=4) {
            Yi[0] ^= 0xffffffff&data[i];
            Yi[1] ^= 0xffffffff&data[i+1];
            Yi[2] ^= 0xffffffff&data[i+2];
            Yi[3] ^= 0xffffffff&data[i+3];
            Yi = _galoisMultiply(Yi, H);
        }
        return Yi;
    };

    /** GCM CTR mode.
     * Encrypt or decrypt data and tag with the prf in GCM-style CTR mode.
     * @param {Boolean} encrypt True if encrypt, false if decrypt.
     * @param {Object} key The key to use. Must be 32 bytes.
     * @param {bitArray} data The data to be encrypted or decrypted.
     * @param {bitArray} iv The initialization vector.
     * @param {bitArray} adata The associated data to be tagged.
     * @param {Number} tlen The length of the tag, in bits.
     */
    var _ctrMode = function (encrypt, key, data, adata, iv, tlen) {
        var H, J0, S0, enc, i, ctr, tag, last, l, bl, abl, ivbl, w = Cryptomathic.Crypto.GcmUtils;
        
        var aes = create_AES_Adaptor(key);

        // Calculate data lengths
        l = data.length;
        bl = w.bitLength(data);
        abl = w.bitLength(adata);
        ivbl = w.bitLength(iv);

        // Calculate the parameters
        H = aes.encrypt([0,0,0,0]);
        if (ivbl === 96) {
            J0 = iv.slice(0);
            J0 = w.concat(J0, [1]);
        } else {
            J0 = _ghash(H, [0, 0, 0, 0], iv);
            J0 = _ghash(H, J0, [0, 0, Math.floor(ivbl / 0x100000000), ivbl & 0xffffffff]);
        }
        S0 = _ghash(H, [0, 0, 0, 0], adata);

        // Initialize ctr and tag
        ctr = J0.slice(0);
        tag = S0.slice(0);

        // If decrypting, calculate hash
        if (!encrypt) {
            tag = _ghash(H, S0, data);
        }

        // Encrypt all the data
        for (i=0; i<l; i+=4) {
            ctr[3]++;
            enc = aes.encrypt(ctr);
            data[i]   ^= enc[0];
            data[i+1] ^= enc[1];
            data[i+2] ^= enc[2];
            data[i+3] ^= enc[3];
        }
        data = w.clamp(data, bl);

        // If encrypting, calculate hash
        if (encrypt) {
            tag = _ghash(H, S0, data);
        }

        // Calculate last block from bit lengths, ugly because bitwise operations are 32-bit
        last = [
            Math.floor(abl/0x100000000), abl&0xffffffff,
            Math.floor(bl/0x100000000), bl&0xffffffff
        ];

        // Calculate the final tag block
        tag = _ghash(H, tag, last);
        enc = aes.encrypt(J0);
        tag[0] ^= enc[0];
        tag[1] ^= enc[1];
        tag[2] ^= enc[2];
        tag[3] ^= enc[3];

        return {tag: w.bitSlice(tag, 0, tlen), data: data};
    };

    /** Convert from a hex string to a bitArray. */
    ns.toBits = function(str) {
        var i;
        var out=[];
        var len;

        str = str.replace(/\s|0x/g, "");
        len = str.length;
        str = str + "00000000";
        for (i=0; i<str.length; i+=8) {
            out.push(parseInt(str.substr(i,8),16)^0);
        }
        return Cryptomathic.Crypto.GcmUtils.clamp(out, len*4);
    };

    ns.fromBits = function(arr) {
        var out = "";
        var i;

        for (i=0; i<arr.length; i++) {
            out += ((arr[i]|0)+0xF00000000000).toString(16).substr(4);
        }
        return out.substr(0, Cryptomathic.Crypto.GcmUtils.bitLength(arr)/4);//.replace(/(.{8})/g, "$1 ");
    };

} (Cryptomathic.namespace('Cryptomathic.Crypto.Gcm')));
