/**
 * # Cryptomathic User SDK.
 *
 * The Cryptomathic User SDK is intended for use within a browser. The SDK requires a browser that supports CORS,
 * and this should be correctly configured on the page serving the SDK script. The Signer Forwarder must also be
 * configured to accordingly allow CORS.
 *
 * The SDK wraps itself in a global variable called "Cryptomathic" to avoid naming conflicts.
 *
 * ## Example
 *     var sdk = new Cryptomathic.SignerUserSDK.SDK("https://localhost:8443/signer-forwarder", 10000);
 *     try {
 *       sdk.initialize();
 *       console.log("Successfully initialized SDK.");
 *     } catch (error){
 *       console.log("Error initializing SDK: '" + error.name + ": " + error.message + "'");
 *     }
 *
 * For more examples, see main class: {{#crossLink "Cryptomathic.SignerUserSDK.SDK"}}{{/crossLink}}
 *
 * @module Cryptomathic
 * @main Cryptomathic
 */

namespace Cryptomathic {

  /*
   * A function to define or augment the existing 'namespaces'. Restricted to namespaces starting with 'Cryptomathic.'
   */
  Cryptomathic.namespace = (namespaceString) => {
    // namespace function taken from:
    // http://elegantcode.com/2011/01/26/basic-javascript-part-8-namespaces/
    const parts = namespaceString.split(".");

    if (parts[0] !== "Cryptomathic") {
      throw Error("namespace string must start with 'Cryptomathic' and the namespace parts must be seperated with '.'");
    }

    let parent: any = Cryptomathic;
    let currentPart = "";

    for(let i = 1, length = parts.length; i < length; i++) {
      currentPart = parts[i];
      parent[currentPart] = parent[currentPart] || {};
      parent = parent[currentPart];
    }

    return parent;
  };
}
