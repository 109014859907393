///<reference path="Tags/TagMap.ts"/>
///<reference path="Tags/HsmResponse.ts"/>
namespace Cryptomathic.Messages.Sign.Response {

  import TagMap = Cryptomathic.Messages.Tags.TagMap;
  import HsmResponse = Cryptomathic.Messages.Tags.HsmResponse;

  /**
   * Extract a signature from the HSM response. If legacy style request, then it needs to be formatted, if not then the server has already done all necessary formatting.
   *
   * @param hsmResponse the server response
   * @param toSign typeevidence for deciding if this is a response to a legacy request
   * @param keyEntry needed for formatting legacy style responses
   */
  export function extractSignature(hsmResponse: TagMap, toSign: Cryptomathic.Crypto.SignatureRequest | number[], keyEntry: KeyEntry): number[] {
    const rawSignature = hsmResponse.get(HsmResponse.signature);
    if (isSignatureRequest(toSign)) {
      return toSign.encodeSignerResponse(rawSignature, keyEntry);
    } else {
      return rawSignature;
    }
  }

}
