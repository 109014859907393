///<reference path="../../error/CrmError.ts"/>
namespace Cryptomathic.Messages.Tags.TLV {
  import CrmError = Cryptomathic.Error.CrmError;
  import ErrorTypes = Cryptomathic.SignerUserSDK.ErrorTypes;

  const tagLen = 2;
  const lengthLen = 4;
  const tagAndLengthLen = tagLen+lengthLen;

  export function parse<R>(input: number[], acc: R, parseTlv: (acc: R, tlv: TlvInfo)=>R, startPos?: number, endPos?: number): R {
    let pos = startPos || 0;
    const end = endPos || input.length;

    if (end>input.length) {
      throw new CrmError(ErrorTypes.SerializationError, "too little data to begin with");
    }

    while(pos<end) {
      if (pos+tagAndLengthLen > end) {
        throw new CrmError(ErrorTypes.SerializationError, "too little data left");
      }

      const tlv=readTagLengthAndValue(input, pos);
      if (end<tlv.contentEnd) {
        throw new CrmError(ErrorTypes.SerializationError, "too little data left");
      }
      acc = parseTlv(acc, tlv);

      pos = tlv.contentEnd;
    }

    return acc;
  }

  export interface TlvInfo {
    tag: number;
    length: number;
    contentStart: number;
    contentEnd: number;
    content: number[];
    raw: number[];
  }

  function readTagLengthAndValue(data: number[], pos: number): TlvInfo {
    const tag = data[pos]*256+data[pos+1];
    const length = data[pos+5]+256*(data[pos+4]+256*(data[pos+3]+256*data[pos+2]));
    
    const contentStart = pos+tagAndLengthLen;
    const contentEnd = contentStart+length;
    const content = data.slice(contentStart,contentEnd);

    const raw = data.slice(pos, contentEnd);

    return {tag,length,contentStart, contentEnd, content, raw};
  }

}
