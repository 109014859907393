namespace Cryptomathic.Messages {

  export function PolicyEntry(policyId: Cryptomathic.Types.KeyId, policyName: string, policyAuthLevel: PolicyAuthenticationLevel, policyKeyLevel: PolicyKeyLevel, policyCanSign: boolean, policyCanDecrypt: boolean, policyEnabled: boolean, policyAcceptedTokens: string, keyList: KeyEntry[], signatureType: Cryptomathic.Messages.SignatureType, signatureTypeParameter: Cryptomathic.Types.ByteArray): PolicyEntry {
    return {
      POLICY_ID: policyId,
      POLICY_NAME: policyName,
      POLICY_AUTH_LEVEL: policyAuthLevel,
      POLICY_KEY_LEVEL: policyKeyLevel,
      POLICY_CAN_SIGN: policyCanSign,
      POLICY_CAN_DECRYPT: policyCanDecrypt,
      POLICY_ENABLED: policyEnabled,
      POLICY_ACCEPTED_TOKENS: policyAcceptedTokens,
      KEY_LIST: keyList,
      SIGNATURE_TYPE: signatureType,
      SIGNATURE_TYPE_PARAMETER: signatureTypeParameter
    };
  }
}
