namespace Cryptomathic.Crypto.Sha2w64Support {

  export function ch(x: int64, y: int64, z: int64): int64 {
    //same logic for most and least
    const most = (x[0] & y[0]) ^ (~x[0] & z[0]);
    const least = (x[1] & y[1]) ^ (~x[1] & z[1]);
    return [most, least];
  }

  export function maj(x: int64, y: int64, z: int64): int64 {
    //same logic for most and least
    const most = (x[0] & y[0]) ^ (x[0] & z[0]) ^ (y[0] & z[0]);
    const least = (x[1] & y[1]) ^ (x[1] & z[1]) ^ (y[1] & z[1]);
    return [most, least];
  }

  export function Sigma0_512(x: int64): int64 {
    return [
      Int64.rotr_high(x,28)^Int64.rotr_high(x,34)^Int64.rotr_high(x,39),
      Int64.rotr_low( x,28)^Int64.rotr_low( x,34)^Int64.rotr_low( x,39)
    ];
  }

  export function Sigma1_512(x: int64): int64 {
    return [
      Int64.rotr_high(x,14)^Int64.rotr_high(x,18)^Int64.rotr_high(x,41),
      Int64.rotr_low( x,14)^Int64.rotr_low( x,18)^Int64.rotr_low( x,41)
    ];
  }

  export function sigma0_512(x: int64): int64 {
    return [
      Int64.rotr_high(x,1)^Int64.rotr_high(x,8)^Int64.shr_high(x,7),
      Int64.rotr_low( x,1)^Int64.rotr_low( x,8)^Int64.shr_low( x,7)
    ];
  }

  export function sigma1_512(x: int64): int64 {
    return [
      Int64.rotr_high(x,19)^Int64.rotr_high(x,61)^Int64.shr_high(x,6),
      Int64.rotr_low( x,19)^Int64.rotr_low( x,61)^Int64.shr_low( x,6)
    ];
  }

  function i64(hex: string): int64 {
    const most = parseInt(hex.substr(0, 8), 16);
    const least = parseInt(hex.substr(8, 8), 16);
    return Int64.fromInts(most, least);
  }

  export const K = Object.freeze([
    i64("428a2f98d728ae22"), i64("7137449123ef65cd"), i64("b5c0fbcfec4d3b2f"), i64("e9b5dba58189dbbc"),
    i64("3956c25bf348b538"), i64("59f111f1b605d019"), i64("923f82a4af194f9b"), i64("ab1c5ed5da6d8118"),
    i64("d807aa98a3030242"), i64("12835b0145706fbe"), i64("243185be4ee4b28c"), i64("550c7dc3d5ffb4e2"),
    i64("72be5d74f27b896f"), i64("80deb1fe3b1696b1"), i64("9bdc06a725c71235"), i64("c19bf174cf692694"),
    i64("e49b69c19ef14ad2"), i64("efbe4786384f25e3"), i64("0fc19dc68b8cd5b5"), i64("240ca1cc77ac9c65"),
    i64("2de92c6f592b0275"), i64("4a7484aa6ea6e483"), i64("5cb0a9dcbd41fbd4"), i64("76f988da831153b5"),
    i64("983e5152ee66dfab"), i64("a831c66d2db43210"), i64("b00327c898fb213f"), i64("bf597fc7beef0ee4"),
    i64("c6e00bf33da88fc2"), i64("d5a79147930aa725"), i64("06ca6351e003826f"), i64("142929670a0e6e70"),
    i64("27b70a8546d22ffc"), i64("2e1b21385c26c926"), i64("4d2c6dfc5ac42aed"), i64("53380d139d95b3df"),
    i64("650a73548baf63de"), i64("766a0abb3c77b2a8"), i64("81c2c92e47edaee6"), i64("92722c851482353b"),
    i64("a2bfe8a14cf10364"), i64("a81a664bbc423001"), i64("c24b8b70d0f89791"), i64("c76c51a30654be30"),
    i64("d192e819d6ef5218"), i64("d69906245565a910"), i64("f40e35855771202a"), i64("106aa07032bbd1b8"),
    i64("19a4c116b8d2d0c8"), i64("1e376c085141ab53"), i64("2748774cdf8eeb99"), i64("34b0bcb5e19b48a8"),
    i64("391c0cb3c5c95a63"), i64("4ed8aa4ae3418acb"), i64("5b9cca4f7763e373"), i64("682e6ff3d6b2b8a3"),
    i64("748f82ee5defb2fc"), i64("78a5636f43172f60"), i64("84c87814a1f0ab72"), i64("8cc702081a6439ec"),
    i64("90befffa23631e28"), i64("a4506cebde82bde9"), i64("bef9a3f7b2c67915"), i64("c67178f2e372532b"),
    i64("ca273eceea26619c"), i64("d186b8c721c0c207"), i64("eada7dd6cde0eb1e"), i64("f57d4f7fee6ed178"),
    i64("06f067aa72176fba"), i64("0a637dc5a2c898a6"), i64("113f9804bef90dae"), i64("1b710b35131c471b"),
    i64("28db77f523047d84"), i64("32caab7b40c72493"), i64("3c9ebe0a15c9bebc"), i64("431d67c49c100d4c"),
    i64("4cc5d4becb3e42b6"), i64("597f299cfc657e2a"), i64("5fcb6fab3ad6faec"), i64("6c44198c4a475817")
  ]);

  export function hash(messageBytes: number[] | Uint8Array, initialHash: ReadonlyArray<int64>): number[] {
    //implements the 64-bit SHA-2 algoritm described in NIST FIPS 180-4 section 6.4 (but allowing for different
    //values for initial hash). Returns the full 512 bit hash as byte-array.
    const blockstream = new Cryptomathic.Crypto.MessageBlockStream(messageBytes);

    let H = initialHash.slice(0);
    while(blockstream.hasNext()) {
      let t;

      const Mi = blockstream.getNext();

      const W = new Array<int64>(80);
      for (t = 0; t < 16; t++) {
        W[t] = Mi[t];
      }
      for (t=16; t < 80; t++) {
        W[t] = Int64.sum(sigma1_512(W[t-2]), W[t-7], sigma0_512(W[t-15]), W[t-16]);
      }

      let a = H[0];
      let b = H[1];
      let c = H[2];
      let d = H[3];
      let e = H[4];
      let f = H[5];
      let g = H[6];
      let h = H[7];

      for(t=0; t < 80; t++) {
        const T1 = Int64.sum(h, Sigma1_512(e), ch(e, f, g), K[t], W[t]);
        const T2 = Int64.add(Sigma0_512(a), maj(a, b, c));
        h = g;
        g = f;
        f = e;
        e = Int64.add(d, T1);
        d = c;
        c = b;
        b = a;
        a = Int64.add(T1, T2);
      }

      const H_ = new Array<int64>(8);
      H_[0] = Int64.add(a, H[0]);
      H_[1] = Int64.add(b, H[1]);
      H_[2] = Int64.add(c, H[2]);
      H_[3] = Int64.add(d, H[3]);
      H_[4] = Int64.add(e, H[4]);
      H_[5] = Int64.add(f, H[5]);
      H_[6] = Int64.add(g, H[6]);
      H_[7] = Int64.add(h, H[7]);

      H = H_;
    }

    return Int64.convertInt64ArrayToByteArray(H);
  }

}
