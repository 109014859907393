namespace Cryptomathic.Crypto.Random {
  
    // generates a 64 byte seed
    export function generateSeed(): number[] {
      /*
      First, collect a browser fingerprint. The user agent alone has decent entropy (see e.g. https://www.eff.org/deeplinks/2010/01/tracking-by-user-agent ),
      but we use much more than this, even the position of the browser and the resolution, which can vary even for the same user.
      Much of the fingerprinting entropy only has value between different users, as the same user will have almost the same fingerprint each time.
      To attempt to remedy this, we also add browser-randomness (non-crypto on older browser), the time and some timings.
      */

      const beforeFp1 = new Date().getTime();

      let fpString1 = navigator.appName;
      fpString1 += navigator.appVersion;
      fpString1 += navigator.language;
      fpString1 += navigator.platform;
      fpString1 += navigator.product;
      fpString1 += navigator.userAgent;
      fpString1 += navigator.javaEnabled();
      fpString1 += navigator.cookieEnabled;
      fpString1 += screen.availHeight;
      fpString1 += screen.availWidth;
      fpString1 += screen.colorDepth;
      fpString1 += screen.height;
      fpString1 += screen.pixelDepth;
      fpString1 += screen.width;
      fpString1 += history.length;
      try {
        fpString1 += window.localStorage === undefined ? "noLS" : "hasLS";
        fpString1 += window.sessionStorage === undefined ? "noSS" : "hasSS";
      } catch (e) {
        // Edge faults this on resources loaded via the file:// protocol
        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8816771/
        fpString1 += "noLSnoSS";
      }
      fpString1 += window.screenX;
      fpString1 += window.screenY;
      fpString1 += window.pageXOffset;
      fpString1 += window.pageYOffset;
      fpString1 += new Date().getTimezoneOffset();
      if ((document as any).uniqueID) {
        fpString1 += (document as any).uniqueID;
      }
      fpString1 += Math.random();

      const beforeH = new Date().getTime();
      const seed1 = Cryptomathic.Crypto.SHA256.hash(Cryptomathic.Utils.StringUtils.string2bytes(fpString1));
      const afterH = new Date().getTime();

      let fpString2 = "" + (afterH - beforeH);

      fpString2 += new Date().getTime();

      const crypto = window.crypto || (window as any).msCrypto; // for IE 11
      const hasCryptRandom = crypto && (typeof crypto.getRandomValues === "function");

      if (hasCryptRandom) {
        const tEvt = new Uint8Array(32);
        crypto.getRandomValues(tEvt);
        fpString2 += Array.prototype.join.call(tEvt, "");
      }

      const after = new Date().getTime();
      fpString2 += (after - beforeFp1);

      const beforeCalculation = new Date().getTime();
      (() => {
        // do something time consuming
        let num = 2.3;
        for (let i = 0; i < 300000; ++i) {
          num *= Math.PI;
        }
        return num;
      })();
      const afterCalculation = new Date().getTime();

      fpString2 += (afterCalculation - beforeCalculation);

      const seed2 = Cryptomathic.Crypto.SHA256.hash(Cryptomathic.Utils.StringUtils.string2bytes(fpString2));

      return seed1.concat(seed2);
    }

}
