namespace Cryptomathic.ASN1 {
  import Asn1Object = Cryptomathic.ASN1.Asn1Object;

  export class IndexedSequence {

    private content: {asn1Object: Asn1Object, index: number}[];

    public constructor() {
      this.content = [];
    }

    public push(asn1Object: Asn1Object, index: number): IndexedSequence {
      this.content.push({asn1Object, index});
      return this;
    }

    /**
     * Iterates the current content and prepends each with an explicit tag
     */
    public wrapContentInExplicitTags() {
      const taggedContentMap = this.content.map((element) => ASN1Util.prependExplicitTag(element.asn1Object, ASN1Util.TagClasses.CONTEXT_SPECIFIC, element.index));

      return new Sequence(taggedContentMap);
    }
  }
}
