namespace Cryptomathic.Utils.StringUtils {

  /**
   *string to bytes (assumes all characters <= 0xff)
   */
  export function string2bytes(str: string): number[] {
    const len = str.length;
    const bytes = Array<number>(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }

  /**
   * byte array to string 
   */
  export function bytes2string(bytes: number[]|Uint8Array): string {
    let res = "";
    const chunkSize = 16 * 1024;
    const chunks = Math.floor(bytes.length / chunkSize);
    let i;
    for (i = 0; i < chunks; ++i) {
      res += String.fromCharCode.apply(null, bytes.slice(i*chunkSize, (i+1)*chunkSize));
    }
    res += String.fromCharCode.apply(null, bytes.slice(i*chunkSize));
    return res;
  }

  /**
   * hex string to byte array
   */
  export function hexToBytes(hex: string): number[] {
    const bytes: number[] = Array(Math.floor((hex.length + 1) / 2));
    const len = hex.length / 2;
    for (let i = 0; i < len; ++i) {
      bytes[i] = (parseInt(hex.charAt(2 * i), 16) << 4) | parseInt(hex.charAt(2 * i + 1), 16);
    }
    return bytes;
  }

  /**
   * byte array to hex string
   */
  export function bytesToHex(bytes: number[]|Uint8Array|Int8Array): string {
    if (bytes instanceof Int8Array) {
      bytes = new Uint8Array(bytes); //this will prevent having -128 output as "-80"
    }
    let hex = "";
    const len = bytes.length;
    for (let i = 0; i < len; ++i) {
      const hb = bytes[i].toString(16);
      if (hb.length === 1) {
        hex += "0";
      }
      hex += hb;
    }
    return hex.toUpperCase();
  }

}
