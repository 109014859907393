namespace Cryptomathic.ASN1 {

  export class IssuerAndSerialNumber implements Asn1Object {
    private readonly issuer: Asn1Object;
    private readonly serial: Asn1Object;

    constructor(issuerEncoding: number[], serialEncoding: number[]) {
      this.issuer = new Cryptomathic.ASN1.Asn1Literal(issuerEncoding);
      this.serial = new Cryptomathic.ASN1.Asn1Literal(serialEncoding);
    }

    public encode(): number[] {
      return new Sequence()
        .push(this.issuer)
        .push(this.serial)
        .encode();
    }

  }

}
